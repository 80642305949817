// projectDataContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getProjects, getTimerCount } from '../api/api';
import { useAuth } from './authContext';

const TimerStatsContext = createContext();
export const TimerStatsProvider = ({ children }) => {
  const { token } = useAuth();
  const [timerStats, setTimerStats] = useState(0);

  const updateTimerStats = async () => {
      try {
          const timerCount = await getTimerCount(token);
          setTimerStats(timerCount.incomplete_timers_count);
      } catch (error) {
          console.error('Error updating timer stats:', error);
      }
  };

  useEffect(() => {
      const fetchData = async () => {
          // Save token, name, and email to localStorage whenever they change
          await updateTimerStats();
      };
      fetchData();
  }, []);

  return (
      <TimerStatsContext.Provider value={{ timerStats, updateTimerStats }}>
          {children}
      </TimerStatsContext.Provider>
  );
};

export const useTimerStats = () => {
  const context = useContext(TimerStatsContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
