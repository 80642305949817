// reducers.js

import { UPDATE_PROJECTS_DATA } from '../actions/actionTypes';

const initialState = {
  data: { count: 0 },
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROJECTS_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default projectsReducer;
