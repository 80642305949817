import React from 'react'
import { Box } from '@mui/system'
import RegistrationComponent from '../../components/RegistrationComponent'

function  RegisterPage() {
  return (
    <div style={{display:"inline-flex",justifyContent:"center", alignContent:"center", height:"50vh", width:"100%"}}>
      <Box sx={{position:"relative", padding:"12% 5%",  width:"80%", height:"100%"}}>
      <RegistrationComponent header="Sign up" secondarybtn="admin login"/>
      </Box>
      <Box  sx={{justifyContent:"center", alignContent:"center", width:"50%", height:"100%",objectFit:"cover"}}>
                <img src='https://picsum.photos/id/20/1000/1040'  alt='pic'/>
        </Box>
        
    </div>
  )
}

export default RegisterPage