import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Container, Grid } from '@mui/material';
import Avatar from 'react-avatar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FlagIcon from '@mui/icons-material/Flag';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import { getActiveWorkItemDetail, getEndTimer, getStartTimer, getTimerCount, getWorkItemDetail, getWorkItemHistory } from '../api/api';
import { toast } from 'react-toastify';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TableDataGrid from './TableDataGrid';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../context/authContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ArrowBtn() {
  const [open, setOpen] = useState(false);
  const [workItemId, setWorkItemId] = useState('');
  const [schedule, setSchedule] = useState([]);

  const {token}= useAuth()
  const handleClickOpen = async () => {

    try {
      const workitemsString = localStorage.getItem('timerstats');
      const workitems = workitemsString ? workitemsString.split(',') : [];
      const concatenatedWorkitems = workitems.join(',');

      // Fetch data and wait for the response
      const tableData = await getActiveWorkItemDetail(token, concatenatedWorkitems);

      // Process the data and set the state
      const extractedData = tableData.map(item => ({
        id: uuidv4(),
        Work_id: item.value[0].id,
        System_Project_name: item.value[0].fields["System.TeamProject"],
        System_State: item.value[0].fields["System.State"],
        System_WorkItemType: item.value[0].fields["System.WorkItemType"],
        System_CreatedDate: item.value[0].fields["System.CreatedDate"],
        System_Title: item.value[0].fields["System.Title"],
      }));

      setSchedule(extractedData);
    
      setOpen(true);
    } catch (error) {
      console.error(error);
      toast.error('There are no active timers at the moment.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant='text'
        disableElevation
        disableRipple
        disableFocusRipple
        sx={{ float: "right", width: "100%", paddingLeft: "60%", paddingTop: "0px", '&:hover': { backgroundColor: 'white' }, marginTop: "-20px" }}
        onClick={handleClickOpen}
      >
        <ArrowForwardIcon sx={{ color: '#1B84FF', fontSize: "3rem" }} />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ '.MuiDialog-paper': { maxHeight: 'none', maxWidth: 'none', width: '70%' } }}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon sx={{ color: '#071437' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box>
       
        <TableDataGrid workItems={schedule} />
        </Box>
        
      </Dialog>
    </React.Fragment>
  );
}
