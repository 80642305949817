import React from 'react';
import { Container, Typography, Link, Box } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ py: 1, backgroundColor: 'transparent', color: 'white' }}>
      <Container maxWidth="xl">
   
      <Typography variant="body2" color="grey" sx={{fontWeight:"700"}} align="left">
          2024 © <span style={{color:"#252F4A"}}>Powered By Fulgid</span>
        </Typography>
       
      
        
      </Container>
    </Box>
  );
};

export default Footer;
