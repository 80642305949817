import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PieChart as RechartsPieChart, Pie, Cell, Tooltip } from 'recharts';
import React from 'react';
import { useAuth } from '../context/authContext';
import { usePieChartData } from '../context/pieChartContext';

function PieChartComponent({ props }) {
  const { WorkItemsActive, WorkItemsNew, WorkItemsClosed } = usePieChartData();

  const data1 = [
    {
      value:  WorkItemsActive.length,
      name: 'Active',
      color: '#17C653',
    },
    {
      value:  WorkItemsNew.length,
      name: 'New',
      color: '#1B84FF',
    },
    {
      value:  WorkItemsClosed.length,
      name: 'Resolved',
      color: '#E4E6EF',
    },
  ];
  
  const isTrue = WorkItemsActive.length !== 0 || WorkItemsNew.length !== 0 || WorkItemsClosed.length !== 0;

  const PieChartComponent1 = () => (
    <RechartsPieChart width={100} height={100}>
      <Pie
        data={data1}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={30}
        outerRadius={50}
        paddingAngle={0}
        cornerRadius={0}
        startAngle={0}
        endAngle={360}
      >
        {data1.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip />
    </RechartsPieChart>
  );

  return (
    <Paper
      sx={{
        minWidth: { xs: '100%', md: '21rem' },
        maxWidth:"22rem",
        border: '1px solid #F1F1F4',
        borderRadius: '0.625rem',
        padding: { xs: '1rem', md: '1.75rem' },
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
      }}
    >
      <Typography sx={{ color: '#071437', fontSize: '2rem', fontWeight: '600' }}>Simple Statistics</Typography>
      <Typography sx={{ color: '#99A1B7', fontSize: '1rem', fontWeight: '400' }}>Detail View</Typography>
      <Box sx={{ display: 'inline-flex' }}>
        {isTrue ? (
          <>
            <Box sx={{ marginTop: '30px' }}>
              <PieChartComponent1 />
            </Box>
            <Box sx={{ paddingTop: '0px', marginLeft: '50px', marginTop: '20px' }}>
              <Box sx={{ display: 'inline-flex', margin: '4px 0px' }}>
                <Box sx={{ backgroundColor: '#17C653', width: '20px', height: '6px', borderRadius: '6.18px', marginTop: '8px' }} />
                <Typography sx={{ paddingLeft: '15px', color: '#99A1B7', fontSize: '20px', fontWeight: '500' }}>Active</Typography>
                <Typography sx={{ paddingLeft: '50px', color: '#4B5675', fontSize: '20px', fontWeight: '700' }}>
                  {WorkItemsActive.length}
                </Typography>
              </Box>

              <Box sx={{ display: 'inline-flex', margin: '4px 0px' }}>
                <Box sx={{ backgroundColor: '#1B84FF', width: '20px', height: '6px', borderRadius: '6.18px', marginTop: '8px' }} />
                <Typography sx={{ paddingLeft: '15px', color: '#99A1B7', fontSize: '20px', fontWeight: '500' }}>New</Typography>
                <Typography sx={{ marginLeft: '65px', color: '#4B5675', fontSize: '20px', fontWeight: '700' }}>
                  {WorkItemsNew.length}
                </Typography>
              </Box>

              <Box sx={{ display: 'inline-flex', margin: '4px 0px' }}>
                <Box
                  sx={{ backgroundColor: '#E4E6EF', width: '20px', height: '6px', borderRadius: '6.18px', marginTop: '8px' }}
                />
                <Typography sx={{ paddingLeft: '15px', color: '#99A1B7', fontSize: '20px', fontWeight: '500' }}>Resolved</Typography>
                <Typography sx={{ paddingLeft: '28px', color: '#4B5675', fontSize: '20px', fontWeight: '700' }}>
                  {WorkItemsClosed.length}
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body1" sx={{ color: '#F1416C', padding: ' 10px', border:"2px dashed #F1416C69", backgroundColor:"#fd413138",margin:"40px 10px", borderRadius:"5px"
            }}>
              No Active, New, Resolved Data available
            </Typography>
          </>
        )}
      </Box>
    </Paper>
  );
}

export default PieChartComponent;
