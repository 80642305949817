import { Button, Divider, Paper, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import Avatar from 'react-avatar';

function IndividualUserPage() {
  return (
    <Container fixed sx={{ alignItems: 'center', marginTop: "20px", maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' } }}>
        <Box sx={{display:"flex"}}>
        <Box sx={{width:"25%"}}>
            <Paper sx={{padding:"50px 20px"}}>
            <Box sx={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>
            <Avatar name={'Hari'} size='80' textSizeRatio={2} round="50px"/>
            
            </Box>
            <Typography variant='subtitle1' sx={{color:"#071437", fontWeight:"700", textAlign:"center"}}>Hari</Typography>
            <Box mt={1} sx={{display:"flex", justifyContent:"center"}}>
            <Button variant='contained' disableElevation disableFocusRipple disableRipple disableTouchRipple
             sx={{color:"#1B84FF", fontWeight:"600", textTransform:"capitalize", backgroundColor:"#E9F3FF","&:hover":{
              backgroundColor:"#E9F3FF"
             }}}>Developer</Button>
            </Box>
            <Box mt={4}>
            <Typography variant='subtitle2' sx={{color:"#071437", fontWeight:"600", textAlign:"center"}}>Assigned Tickets</Typography>
            <Box m={2} sx={{display:"flex", justifyContent:"space-evenly"}}>
              <Box p={2} sx={{border:"2px dashed #DBDFE9", borderRadius:"5px"}}>
              <Typography variant='subtitle2' sx={{color:"#071437", fontWeight:"600", }}>548</Typography>
              <Typography variant='body2' sx={{color:"#99A1B7", fontWeight:"600", }}>Total</Typography>
              </Box>
              <Box p={2} sx={{border:"2px dashed #DBDFE9", borderRadius:"5px"}}>
              <Typography variant='subtitle2' sx={{color:"#071437", fontWeight:"600", }}>548</Typography>
              <Typography variant='body2' sx={{color:"#99A1B7", fontWeight:"600", }}>Solved</Typography>
              </Box>
              <Box p={2} sx={{border:"2px dashed #DBDFE9", borderRadius:"5px"}}>
              <Typography variant='subtitle2' sx={{color:"#071437", fontWeight:"600", }}>548</Typography>
              <Typography variant='body2' sx={{color:"#99A1B7", fontWeight:"600", }}>Open</Typography>
              </Box>
            </Box>
            </Box>
            <Box mt={2} mb={2} sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <Typography variant='subtitle1' sx={{color:"#071437", fontWeight:"600", textAlign:"center"}}>Details</Typography>
            <Button variant='contained' disableElevation disableFocusRipple disableRipple disableTouchRipple
             sx={{color:"#1B84FF", fontWeight:"600", textTransform:"capitalize", backgroundColor:"#E9F3FF","&:hover":{
              backgroundColor:"#E9F3FF"
             }}}
            >Edit</Button>
            </Box>
            <Divider variant='middle'/>
            <Box>
            <Box mt={2} mb={2}>
            <Typography variant='subtitle2' sx={{color:"#071437", fontWeight:"600", }}>Email</Typography>
            <Typography variant='body1' sx={{color:"#78829D", fontWeight:"500",paddingTop:"5px" }}>hari@fulgid.in</Typography>
            </Box>
            <Box mt={2} mb={2}>
            <Typography variant='subtitle2' sx={{color:"#071437", fontWeight:"600", }}>Address</Typography>
            <Typography variant='body1' sx={{color:"#78829D", fontWeight:"500",width:"40%",paddingTop:"5px"}}>101 Collin Street,Melbourne 3000 VICAustralia</Typography>
            </Box>
            <Box mt={2} mb={2}>
            <Typography variant='subtitle2' sx={{color:"#071437", fontWeight:"600", }}>Language</Typography>
            <Typography variant='body1' sx={{color:"#78829D", fontWeight:"500",paddingTop:"5px" }}>English</Typography>
            </Box>
            <Box mt={2} mb={2}>
            <Typography variant='subtitle2' sx={{color:"#071437", fontWeight:"600", }}>Login</Typography>
            <Typography variant='body1' sx={{color:"#78829D", fontWeight:"500",paddingTop:"5px" }}>01 Feb 2024, 9:30 am</Typography>
            </Box>            
            </Box>
            </Paper>
            <Paper sx={{margin:"20px 0px"}}>
              <Box >
                <Typography variant='subtitle1' sx={{color:"#071437", fontWeight:"600", textAlign:"center"}}>Connected Accounts</Typography>
              </Box>
            </Paper>
        </Box>

        </Box>

    </Container>
  )
}

export default IndividualUserPage