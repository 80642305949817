// src/auth/authConfig.js

export const msalConfig = {
    auth: {
      clientId: '5115d187-7184-4365-89bb-2b89b7a581fc',
      authority: 'https://login.microsoftonline.com/6e302edb-9db8-40ec-9378-22f9f1a1f79c',
      redirectUri: '/', // Redirect URI registered in Azure AD
      postLogoutRedirectUri:'/'
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    }, system: {
      tokenRequestType: 'spa',
    },
  };
  

  export const loginResponse={scope:['user.read']};