import React, {  useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { Backdrop, Box, CircularProgress, Container, Drawer, FormControl, Grid, MenuItem, Popover, Select } from '@mui/material';
import Avatar from 'react-avatar';
import LaunchIcon from '@mui/icons-material/Launch';
import { EditData, getEndTimer, getStartTimer, getWorkItemDetail, getWorkItemHistory, removeRelatedWork } from '../api/api';
import { toast } from 'react-toastify';
import flag from '../public/Flag.png';
import calender from "../public/Calendar.png";
import file from '../public/Check New Folder.png';
import InfoIcon from '@mui/icons-material/Info';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useAuth } from '../context/authContext';
import file1 from '../public/Calendar-blue.png'
import { useTimerStats } from '../context/timerStatsContext';
import LinkIcon from '@mui/icons-material/Link';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const inputStyles = {
  display: "block",
 
  height: "1.075rem",
  padding: "0.8rem .9rem",
  fontSize: ".9rem",
  fontWeight: "500",
  lineHeight: "1.5",

  appearance: "none",
  margin: "10px 0px",
  backgroundClip: "padding-box",
  border: "1px solid #DBDFE9",
  borderRadius: "0.475rem",
  outline: "none",
 
  transition: "border-color 0.3s ease",
  '&::placeholder':{
    color:"#4B5675",
    fontWeight:"600"
  }
};



export default function TableProjectView({ id,size,related }) {
  const [open, setOpen] = React.useState(false);
  const [openLoader, setOpenLoader] = React.useState(false);
  const [workHistory, setWorkHistory] = React.useState([]);

  const [schedule, setSchedule] = useState([]);
  const [editRowId, setEditRowId] = useState(null);

const {token}= useAuth()
  let total=0;
let timer=localStorage.getItem('timerstats').includes(id)?true:false
  let bgcolor; 
  let icon;
  switch(schedule.System_WorkItemType){
      case 'Task': {
        bgcolor = 'rgb(164, 136, 10)';
        icon = <AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px", marginRight:"8px"}}/>;
        break;
      }
      case 'Bug': {
        bgcolor = 'rgb(204, 41, 61)'; // Change this to the color you want for 'Bug'
        icon = <BugReportOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Bug'
        break;
      }
      case 'Feature': {
        bgcolor = 'rgb(180, 0, 158)'; // Change this to the color you want for 'Feature'
        icon = <EmojiEventsIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Feature'
        break;
      }
      case 'User Story': {
        bgcolor = 'rgb(0, 152, 199)'; // Change this to the color you want for 'UserStory'
        icon = <MenuBookOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'UserStory'
        break;
      }
      case 'Epic': {
        bgcolor = 'rgb(224, 108, 0)'; // Change this to the color you want for 'Epic'
        icon = <StarsOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Epic'
        break;
      }
      default:{
        bgcolor="#000000";
        icon=<AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>
        // Default case
      }
  }
  
 const StateSelector= ()=>{

  const stateColors = {
    'Active': '#17c653',
    'New': '#1B84FF',
    'Resolved': '#17c653',
    'Pause': '#6951aa',
    'Closed': '#F1416C',
    'Hold':"red"
  };

  return(
    <FormControl sx={{ m: 1, minWidth: 80 ,borderColor:"transparent", margin:"0px"}}>
    <Select
    sx={{width:"100%",'.MuiOutlinedInput-notchedOutline':{border:0},padding:"0px", height:"25px",  color: stateColors[schedule.System_State] || "Grey",appearance:"none"}}
    
    value={schedule.System_State}
    onChange={async (e) => {
      const updatedWorkItems =  { ...schedule, System_State: e.target.value } 
      const requestBody = updatedWorkItems.System_State;

      setEditRowId(id);
      if(localStorage.getItem('timerstats').includes(id)){
        return(
          toast.error('Timer is ON,stop timer to change state!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      })
        )
      }
      try {
      // Call the editData API with the requestBody
      
      await EditData(token, id, requestBody);

      // Update the local state or perform any other necessary actions
      toast.success(`State is changed successfully to ${requestBody}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      setOpen(false)
      // Reset the editRowId to disable edit mode
      setEditRowId(null);
      } catch (error) {
      console.error('Error updating data:', error);
      // Handle errors or display an error message
      }
      }}
        
      >
        
      
        <MenuItem value={'Active'}>Active</MenuItem>
        <MenuItem value={'New'}>New</MenuItem>
        <MenuItem value={'Closed'}>Closed</MenuItem>
        <MenuItem value={'Resolved'}>Resolved</MenuItem>
        <MenuItem value={'Pause'}>Pause</MenuItem>
        <MenuItem value={'Hold'}>Hold</MenuItem>
      </Select>
        
      </FormControl>
      )
 }
 const [openRelatedWork,setOpenRelatedWork]=useState(false)
 const handleOpenRelatedWork=(text)=>{
  setOpenRelatedWork(true)

}
const handleCloseRelatedWork=()=>{
  setOpenRelatedWork(false)
}

const fetchdata=async()=>{
  try {

    const historyDetails = await getWorkItemHistory(token,id);
  
    setWorkHistory(historyDetails.incomplete_timers_data);
    timer=historyDetails.end_time_status === null ? timer : historyDetails.end_time_status
    const workItemData = await getWorkItemDetail(token, id);
          setSchedule(workItemData[0]);
          setOpenLoader(false)
  } catch (error) {
    console.log(error);
    setOpenLoader(false)
  }
}

function RotatingTimerIcon({ id , selectedState, project, text}) {
  const [isRotating, setIsRotating] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [comment, setComment] = useState('');
  const [server,setServer]=useState();
  const { timerStats, updateTimerStats } = useTimerStats();

  
  let isClosedState = selectedState !== 'Active';
  useEffect(() => {
    const idExists = localStorage.getItem('timerstats').includes(id);
    setIsRotating(idExists);
  }, [timerStats]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };


      
      const startTimer = async () => {
        const token =  localStorage.getItem('token');
        try {
          const startmessage = await getStartTimer(token,id,server,project);
          setAnchorEl(null)
          await updateTimerStats()
          const toastMessage =  'Timer started Successfully!' ;
        toast.success(toastMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          setIsRotating(true);
          
         
          timer=true
        } catch (error) {
          console.log(error);
          toast.error("Please Punch In/Out before starting a task", { 
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
           
        }
       
      };
    
      const stopTimer = async () => {
        const token =  localStorage.getItem('token');
        try {
          const endmessage = await getEndTimer(token, id, comment);
          setAnchorEl(null)
          await updateTimerStats()
          const toastMessage =  'Timer stopped Successfully!' ;
        toast.success(toastMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      
          setIsRotating(!isRotating);
          timer=false
        
        } catch (error) {
          console.log(error);
          toast.error("Please Punch In/Out before ending a task", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
       
      };
    
     
      const handleClick1 = () => {
        if (isRotating) {
          stopTimer();
        } else {
          startTimer();
        }
       
      };

 

      return (
        <div>
         {isRotating? (<>

          <IconButton  onClick={handleClick} sx={{color:"white !important"}} disabled={isClosedState}>
          <span style={{fontSize:"20px", fontWeight:"500", paddingRight:"5px"}}>End Timer</span>
           <ScheduleIcon sx={{color:"white !important"}} className={isRotating?"rotate":""} />
          </IconButton>
          <Popover
        
         elevation={2}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'bottom',
    horizontal: 'center',
  }}
        >
           <div style={{padding:"10px 50px 10px 20px",margin:"auto 20px", display:"inline-flex" , overflow:"hidden",}}>
          
          
            <input type='text' placeholder='Comments' style={inputStyles} value={comment}
              onChange={handleCommentChange}/>
            <IconButton className={isRotating?"rotate":""} disableElevation disableFocusRipple disableTouchRipple disableRipple  onClick={handleClick1} disabled={isClosedState}>
           <ScheduleIcon sx={{fontSize:"32px",}}/>
          </IconButton>
          </div>
        </Popover>
        </>
         ):( <>
          <IconButton className={isRotating?"rotate":""} sx={{color:"white !important"}} disableElevation disableFocusRipple disableTouchRipple disableRipple onClick={handleClick} disabled={isClosedState}>
          <span style={{fontSize:"20px", fontWeight:"500", paddingRight:"5px"}}>Start Timer</span><ScheduleIcon />
          </IconButton>
          <Popover
         elevation={2}
         arrow
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'bottom',
    horizontal: 'center',
  }}

        >
          <div style={{padding:"10px 50px 10px 20px",margin:"auto 20px", display:"flex" }}>
          
          <FormControl sx={{ m: 1, minWidth: 200 ,borderColor:"transparent", }}>
              <Select
              sx={{width:"100%",'.MuiOutlinedInput-notchedOutline':{border:0},  color:"Grey",appearance:"none"}}
              defaultValue={'Select server'}
              value={server}
                  onChange={(event)=>setServer(event.target.value)}
                >
                 <MenuItem value={'Select server'}>Select server</MenuItem>
                  <MenuItem value={'Development'}>Development</MenuItem>
              <MenuItem value={'Production'}>Production</MenuItem>
              <MenuItem value={'Staging'}>Staging</MenuItem>
            
                </Select>
                </FormControl>
                <IconButton disableElevation disableFocusRipple disableTouchRipple disableRipple  className={isRotating?"rotate":""} onClick={handleClick1} >
            <ScheduleIcon sx={{fontSize:"32px"}}/>
          </IconButton>
          </div>
        </Popover>
         </>)}
          </div>
      );
    }
  const handleClickOpen = async () => {
    setOpenLoader(true)
  fetchdata()
     
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  if (!schedule) {
    return null;
  }
const handleRemoveRelatedWork=async(text)=>{
  const remove=await removeRelatedWork(token,text,id,schedule.System_Project_name);
  toast.success(`Reated work ${text} removed for ${id} successfully!`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
    fetchdata()
    
}
const relatedBtn=(text)=>{
  return(<>
  <div style={{display:"flex",justifyContent:"space-between", alignItems:"center"}}>
 
  <TableProjectView id={text} related={true}/>
  {text==='No related work'?(<></>):(
    <IconButton variant='text' disableElevation disableFocusRipple disableRipple disableTouchRipple
  sx={{"&:hover":{backgroundColor:"#F8285A20"},backgroundColor:"#F8285A20"}}
  onClick={()=>{handleRemoveRelatedWork(text)}}
  ><DeleteOutlineIcon sx={{color:"#F8285A", fontSize:"18px"}}/></IconButton>
 
  )}
  </div>
    
  </>)
}

  const regex = /<span class="remove-me">.*?<\/span>/;
  const description = schedule.System_Description?.replace(regex, '') || '';
  const randomColor =
    schedule.System_State === 'Active'
      ? '#1B84FF'
      : schedule.System_State === 'Removed' || schedule.System_State === 'Closed'
      ? '#F8285A'
      : schedule.System_State === 'Resolved'
      ? '#17C653'
      : '#7239EA';
 

  return (
    <React.Fragment>
     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
       
      >
        <CircularProgress sx={{color:"#17c653"}} />
      </Backdrop>
      <Button
        sx={{
            backgroundColor: 'transparent',
          color: '#252F4A',
          width: '100%', // Full width on small screens
          height: '35px',
          fontWeight: '700',
          textTransform: 'capitalize',
           // Add margin for better spacing
          '&:hover': {
            backgroundColor: 'transparent',
            color:"#1B84FF"
          },
          '&:focus': {
            backgroundColor: 'transparent',
            color:"#1B84FF"
          },
        }}
        variant="contained"
        disableElevation
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={handleClickOpen}
      >{related?id:(<LaunchIcon sx={{color:"grey",paddingRight:"20px", fontSize:size?size:"22px",'&:hover': {
            backgroundColor: 'transparent',
            
            display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'center',
    alignItems: 'flex-start',
            color:'#1B84FF'
          },
          '&:focus': {
            backgroundColor: 'transparent',
            color:'#1B84FF'
          },}}/>
     )}        
     </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ '.MuiDialog-paper': { maxHeight: 'none', maxWidth: 'none', width: '70%' },height:"auto", overflow:"auto", 
        zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <AppBar sx={{ position: 'absolute', backgroundColor: 'transparent', boxShadow: 'none' }}>
         
        </AppBar>
        <Grid container >
          <Grid item xs={8} className='vertisrcoll' sx={{padding:"50px",
            '&::-webkit-scrollbar': {
              display: 'none',
              padding: '20px',

            },
          }}>

       
            <Container >
            <Typography variant="body1" mt={2} sx={{fontWeight:"600",display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'}}>
                  <span className='taskpop' style={{color:bgcolor,display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'}}>{icon}{schedule.System_WorkItemType}</span> 
                </Typography>
              <Typography sx={{  flex: 1, color: '#000000', fontWeight: '600' }} variant="h5" component="div">
                #{schedule.Work_ID} {schedule.System_Title}
              </Typography>
              <Typography variant="body1"  sx={{fontWeight:"600",display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'}}>
                  <span style={{marginRight:"05px"  }}>
                    <img className='filecandar' src={file1} alt='logo' style={{width:"15px", height:"15px"}}/>{' '}
                  </span>
                  <span className='datapop'>{schedule.System_ExactCreatedDate}</span>
                </Typography>
              <Typography sx={{ mt:2, flex: 1, color: '#000000' }} variant="body1" component="div">
                <span style={{ fontWeight: '600', fontSize:"16px" }}>Project Name : &nbsp; </span>
                <span style={{color:"white", backgroundColor:"#FF9F55", padding:"8px"}}>{schedule.System_Project_name}</span>
              </Typography>
              <Typography sx={{ mt:2, flex: 1, color: '#000000' }} variant="body1" component="div">
                <span style={{ fontWeight: '600', fontSize:"16px" }}>Work Item ID : &nbsp;&nbsp;&nbsp;</span>
                {schedule.Work_ID}
              </Typography>
             <Typography sx={{ mt:2, flex: 1, color: '#000000', fontWeight: '600', fontSize:"16px" }} variant="body1" >External Link : {schedule.External_link?<a href={schedule.External_link}><LinkIcon sx={{color:"#1B84FF"}}/></a>:<span style={{fontWeight: '500', fontSize:"16px"}}>No External Link</span>}</Typography>
              <Typography sx={{mt:5, flex: 1, color: '#000000', fontWeight:"700" }} variant="h6" component="div">
                Description
              </Typography>
              <Divider className='dividermargin' variant="middle" />
              <Box className='desc-pop'>
              {schedule.System_Description === null?(<Typography variant='body1' sx={{color:"grey"}}>No description</Typography>):(<ul>
                  <li>
                    {' '}
                    {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
<div dangerouslySetInnerHTML={{ __html: description }} />
                  </li>
                </ul>)}
                
              </Box>
              <Typography sx={{flex: 1, color: '#000000', fontWeight:"700"  }} variant="h6" component="div">
                Reason
              </Typography>
              <Divider className='dividermargin' variant="middle" />
              <Box>
              <Typography className='desc-pop' sx={{flex: 1, }} pl={0} pt={2} variant="body1">
                
                {schedule.System_Reason===null?"No Reason":schedule.System_Reason}
               
             </Typography>
              </Box>
             
              <Typography sx={{ mt: 2, flex: 1, color: '#000000', fontWeight:"700"  }} variant="h6" component="div">
                Timer History
              </Typography>
              <Divider className='dividermargin' variant="middle" />
              <Box sx={{overflow:"auto"}}>
             
        
                  {workHistory.length > 0 ? (
                    <ul className='addevencolor' style={{listStyleType:"none", padding:"0px"}}>
                    {workHistory.map((item, index) => {
                      const classes = item.server==='Development'?'servericon-blue':item.server==='Production'?'servericon-green':item.server==='Staging'?"servericon-pink":"servericon-grey";
                      const duration = typeof item.duration === 'number' ? item.duration.toFixed(2) : parseFloat(item.duration).toFixed(2)
                      total += parseFloat(duration);
                      return(
                      <li key={index} >
                      <div style={{display:"inline-flex", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                        <div>
                        
                        <Typography variant='body1' mt={1} 
                        sx={{fontWeight:"500", color:"#000000",display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center'}}> 
                        <StorageIcon className={classes} /><div>
                        <Typography variant='h6' sx={{fontWeight:"700", color:"#000000", textTransform:"lowercase"}}>#{item.server} server</Typography>
                        {item.start_time} TO {item.end_time === null ? 'Timer not yet stopped' : item.end_time}</div></Typography>
                        
                        </div>
                        <div style={{justifyContent:"flex-end"}}>
                             <Button variant='contained'disableElevation disableFocusRipple disableRipple disableTouchRipple 
                             sx={{borderRadius:"50px",backgroundColor:"#17c653",color:"white", '&:hover':{backgroundColor:"#17c653"}, textTransform:"lowercase"}}>{duration<10? `0${duration}`:duration} mins</Button>
                        </div>
                      </div>
                     
                       
                      </li>
                  
                    )})}
                    </ul>
                    
                  ) : (
                    <Typography variant='body1' pt={2} pb={2} pl={0} sx={{color:"grey"}}>No Timer History</Typography>
                  )}
               
              </Box>
            </Container>
          
          </Grid>
          <Grid item xs={4} sx={{backgroundColor: '#FFF0DF', padding:"50px 20px"}}>
            <Container sx={{ backgroundColor: '#FFF0DF', padding: 'auto 50px' }}>
            <Typography sx={{ ml: 2, flex: 1, color: '#000000', fontWeight:"600" }} variant="body1" component="div">
                Assigned By
              </Typography>
              <Divider variant='middle'/>
              <Box sx={{ padding:"30px 10px" }}>
                <Avatar name={schedule.System_CreatedBy} size="40" round="20px" textSizeRatio={2} />
              </Box>
              <Typography sx={{ ml: 2, flex: 1, color: '#000000', fontWeight:"600" }} variant="body1" component="div">
                Assigned To
              </Typography>
              <Divider variant='middle'/>
              <Box sx={{ padding:"30px 10px" }}>
                <Avatar name={schedule.System_AssignedTo} size="40" round="20px" textSizeRatio={2} />
              </Box>
              <Typography sx={{ mt: 2, ml: 2, flex: 1, color: '#000000',fontWeight:"600"  }} variant="body1" component="div">
                Status
              </Typography>
              <Divider variant="middle" />
              <Box ml={2} mt={2}>
                <Typography variant="body1" mt={2} sx={{fontWeight:"600",display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'}}> 
                  <span style={{  marginRight:"10px" }}>
                   <img src={calender} alt='logo' style={{width:"20px",paddingTop: '5px', height:"20px"}}/>{' '}
                  </span>
                  Created Date : {schedule.System_CreatedDate}
                </Typography>
                <Typography variant="body1" mt={2} sx={{fontWeight:"600",display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'}}>
                  <span style={{ marginRight:"10px"  }} sx={{fontWeight:"600"}}>
                    <img src={flag} alt='logo' style={{width:"20px", height:"20px"}}/>{' '}
                  </span>
                  Current State : <span><StateSelector/></span>
                </Typography>
                <Typography variant="body1" mt={2} sx={{fontWeight:"600",display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'}}>
                  <span style={{marginRight:"10px"  }}>
                    <img src={file} alt='logo' style={{width:"20px", height:"20px"}}/>{' '}
                  </span>
                  Work Item Type : <span style={{color:bgcolor,display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    paddingLeft:"10px"}}>{icon} {schedule.System_WorkItemType}</span> 
                </Typography>
                <Typography variant="body1" mt={2} sx={{fontWeight:"600",display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'}}>
                  <span style={{ marginRight:"10px"  }} sx={{fontWeight:"600"}}>
                    <img src={flag} alt='logo' style={{width:"20px", height:"20px"}}/>{' '}
                  </span>
                  Related Work : <span>{relatedBtn(schedule.System_relations_id?schedule.System_relations_id:'No related work')}</span>
                </Typography>
              </Box>
              <Typography sx={{ mt: 4, ml: 2, flex: 1, color: '#000000',fontWeight:"600"  }} variant="body1" component="div">
                Timer History
              </Typography>
              <Divider variant="middle" />
              <Typography variant='body1' sx={{ mt: 3, ml: 3, flex: 1, color: '#b3013b',fontWeight:"600",    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center' }}>
              <span style={{ marginRight: '10px' }}>
                    <InfoIcon sx={{fontSize:"18px", paddingTop:"5px"}}/>{' '}
                  </span>
                   Over all {(typeof total === 'number' && !Number.isNaN(total)) ? ((total/60).toFixed(2) !== '0.00' ? (total/60).toFixed(2) : 0) : 0} Hrs
                   {/* {total} */}
                  </Typography>
              <Typography sx={{ mt: 2, ml: 2, flex: 1, color: '#000000',fontWeight:"600"  }} variant="body1" component="div">
                Timer
              </Typography>
              <Divider variant="middle" />
              <Box m={2}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: timer ? '#F1416C' : '#1B84FF',
                    '&:hover': { backgroundColor: timer ? '#F1416C' : '#1B84FF' },
                    textTransform:"capitalize",
                    fontSize:"16px",
                    color:"white !important"
                  }}
                  disabled={schedule.System_State !== 'Active'}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  
                >
               <RotatingTimerIcon id={schedule.Work_ID} selectedState={schedule.System_State} project={schedule.System_Project_name} />
                </Button>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
