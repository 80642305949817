import React, { useState } from 'react';
import './style.css'; // Import your custom CSS file
import { Button } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
const CustomCalendar = ({data}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month).getDay();

  const findAttendance = (day) => {
    const dateStr = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    return data.find(d => d.date === dateStr);
  };
  const renderDayNames = () => {
    return dayNames.map(day => (
      <div key={day} className="calendar-day-name">
        {day}
      </div>
    ));
  };
  const renderCalendarDays = () => {
    const totalDays = daysInMonth(currentMonth, currentYear);
    const firstDay = getFirstDayOfMonth(currentYear, currentMonth);
    const calendarDays = [];

    // Fill in the blanks for days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="calendar-day empty-day"></div>);
    }

    // Fill in the actual days of the month
    for (let day = 1; day <= totalDays; day++) {
      const attendance = findAttendance(day);
      let className = 'calendar-day';
      
      if (attendance) {
        className += attendance.status === 'Present' ? (attendance.category === 'Full Day' ? ' present' : ' half-day') : ' absent';
      }
      const bgcolor = {
        'Full Day': '#17C653',
        'Half Day':'#FFAD0F',
        Absent: '#FD4131',
        Leave: '#1B84FF',
      };
      calendarDays.push(
        <div key={day} className={className}>
          <div style={{display:"flex",justifyContent:attendance?"space-between": "left", height:"120px", borderBottom:"2px solid #78829D50"}}>
          <p style={{color:"#78829D69"}}>{day}</p>
          <p style={{fontSize:"12px"}}>{attendance && <div className="status" style={{backgroundColor:bgcolor[attendance.category], borderRadius:"50px", 
          color:"white", padding:"10px"
          }}>{attendance.category}</div>}</p>
          </div>
         
        </div>
      );
    }

    return calendarDays;
  };

  const handleMonthChange = (increment) => {
    let newYear = currentYear;
    let newMonth = currentMonth + increment;

    if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    } else if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    }

    setCurrentYear(newYear);
    setCurrentMonth(newMonth);
  };

  return (
    <div className="calendar-container" >
      <div className="calendar-header">
        <Button variant='text' disableElevation disableFocusRipple disableRipple sx={{fontSize:"20px", fontWeight:"600",color:"#78829D"}} onClick={() => handleMonthChange(-1)}><KeyboardDoubleArrowLeftIcon sx={{color:"grey"}}/></Button>
        <span style={{color:"#78829D",fontSize:"20px"}}>
          {new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })} {currentYear}
        </span>
        <Button variant='text' disableElevation disableFocusRipple disableRipple sx={{fontSize:"20px", fontWeight:"600",color:"#78829D"}}
        onClick={() => handleMonthChange(1)}><KeyboardDoubleArrowRightIcon sx={{color:"grey"}}/></Button>
      </div>
      <div className="calendar-days">
        <div className='days'>Sun</div>
        <div  className='days'>Mon</div>
        <div  className='days'>Tue</div>
        <div  className='days'>Wed</div>
        <div  className='days'>Thu</div>
        <div  className='days'>Fri</div>
        <div  className='days'>Sat</div>
      </div>
      <div className="calendar-grid">
        {renderCalendarDays()}
      </div>
    </div>
  );
};

export default CustomCalendar;