const initialState = {
  workItemData: null,
  };
  
  const activeWorkItemDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'STORE_WORK_ITEM_DATA':
      
        return {
          ...state,
          workItemData: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  export default activeWorkItemDataReducer;