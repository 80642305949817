
// projectDataContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getProjects, getWorkItems } from '../api/api';
import { useAuth } from './authContext';

const OverallWorkitemDataContext = createContext();

export const OverallWorkItemProvider = ({ children }) => {
    const {token}= useAuth();
    const [overallWorkItems, setOverallWorkItems] = useState([]);
  useEffect(() => {
    // Save token, name, and email to localStorage whenever they change
    const fetchData = async ()=>{
        const overallData = await getWorkItems(token);
        setOverallWorkItems(overallData);

    }
    fetchData();
  }, []);

 

  return (
    <OverallWorkitemDataContext.Provider value={{overallWorkItems }}>
      {children}
    </OverallWorkitemDataContext.Provider>
  );
};

export const useOverallWorkitem = () => {
  const context = useContext(OverallWorkitemDataContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
