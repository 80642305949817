// projectDataContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getProjects } from '../api/api';
import { useAuth } from './authContext';

const ProjectDataContext = createContext();

export const ProjectDataProvider = ({ children }) => {
    const {token}= useAuth();
const [projectData,setData]=useState([])
  useEffect(() => {
    // Save token, name, and email to localStorage whenever they change
    const fetchData = async ()=>{
        const projectData = await getProjects(token);
    setData(projectData);
    }
    fetchData();
  }, []);

 

  return (
    <ProjectDataContext.Provider value={{projectData, }}>
      {children}
    </ProjectDataContext.Provider>
  );
};

export const useProjectData = () => {
  const context = useContext(ProjectDataContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
