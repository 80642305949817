import React, { useEffect, useState } from 'react'
import StyledDataGrid1 from './StyledDataGrid'
import PieChartComponent from './PieChartComponent'
import ProjectCard from './ProjectCard'
import { Autocomplete, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import bgshade from '../public/vector-1.png';
import { getProjects } from '../api/api'
import { useAuth } from '../context/authContext'
import { useProjectData } from '../context/projectDataContext'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CountUp from 'react-countup';
import LinearProgress from '@mui/material/LinearProgress';

import Tooltip from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';

import { Avatar as Avatar1, AvatarGroup } from '@mui/material';
import Avatar from 'react-avatar';

import { Link, useNavigate } from 'react-router-dom';
import IndividualProject from './IndividualProject'
function truncate(str, num) {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
}
function ProjectContent() {
  const {projectData}=useProjectData();
  const {token}= useAuth()
  const [selectedCard, setSelectedCard] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectInfo,setProjectInfo]=useState()
  const [filterValue, setFilterValue] = useState(null);

  const projectsPerPage = 9;
  const navigate = useNavigate();
const projects=projectData;
console.log(filterValue)
const list=projectData.map(project => project.project.name);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const filteredProjects = filterValue === 'All' ||filterValue === null
  ? projects
  : projects.filter((project) => project.project.name === filterValue);

const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return ( <>
   
      <Container fixed  style={{  alignItems: 'center', marginTop:"50px",maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' }}} >
   
   <Grid container spacing={1} mb={4}>
   <Grid item xs={12} md={6} lg={6} xl={5}>
   <PieChartComponent/>
   </Grid>
  
       <Grid item xs={12} md={6} lg={6} xl={4}>
         <Paper
           sx={{
             width: { xs: "100%", md: "22rem" },
             padding: { xs: '1rem', md: '1.75rem' },
             borderRadius: "0.625rem",
             backgroundColor: "#F1416C",
             backgroundImage: `url(${bgshade})`,
             backgroundSize: "contain",
             backgroundPositionX: "right",
             backgroundRepeat: "no-repeat",
          
           }}
         >
           <Typography
             sx={{
               fontSize: "4rem",
               color: "#ffff",
               lineHeight: "1",
               fontWeight: "600"
             }}
           >
             <CountUp end={projectData.length} duration={2}/>
           </Typography>
           <Typography
             sx={{
               color: "#ffff",
               opacity: "0.75",
               fontWeight: "500",
               fontSize: "1.5rem",
               paddingTop: "0.35 rem",
               fontFamily: 'Inter, Helvetica, sans-serif'
             }}
           >
             Active Projects
           </Typography>
           <Box mt={12}>
             <Typography
               sx={{
                 color: "#ffff",
                 opacity: "0.75",
                 fontWeight: "500",
                 fontSize: "1.075rem",
                 paddingTop: "0.25 rem",
                 fontFamily: 'Inter, Helvetica, sans-serif'
               }}
             >
               
             </Typography>
           
           </Box>
         </Paper>
</Grid>
</Grid>
<Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
<Box>
<Typography variant='h4' sx={{color:'#071437', marginTop:"50px", fontWeight:"600"}}>Projects
     <Typography className='' variant='p' sx={{color:'#9b9696',display:'block', fontSize:'12px', marginTop:"5px", fontWeight:"400"}}>Explore Projects: A Comprehensive Overview of Your Endeavors</Typography>
     </Typography>
</Box>
<Box sx={{width:"30%"}}>
<Autocomplete
      value={filterValue}
      onChange={(event, newValue) => setFilterValue(newValue)}
      options={list} // Replace with your actual filter options
      renderInput={(params) => (
        <TextField {...params} label="Filter Projects" variant="outlined" />
      )}
    />
</Box>
</Box>
     

     <>
     <Grid container spacing={2.5} sx={{ marginTop: '5px' }} >
       {currentProjects.map((project, index) => {
         const colors = ['#7239EA', '#F6C000', '#17C653', '#1B84FF'];
         const randomColor = colors[Math.floor(Math.random() * colors.length)];

         return (
           <Grid item key={project.project.id} xs={12} sm={6} md={4} lg={4} onClick={()=> {setSelectedCard(project.project.name)
           navigate(`/dashboard/projects/${encodeURIComponent(project.project.name)}`);
           }}>
          
             <Card className='Cardbox'>
              
               <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Avatar name={project.project.name} size='50' textSizeRatio={2} round="2px"/>
                <Button className={project.project.visibility} disableElevation variant='contained'>
                  {project.project.visibility}
                </Button>
                </CardActions>
                 <CardContent>
                   <Typography variant='h5' className='TitleCard' component='div' sx={{height: '2rem'}}>
                     {project.project.name}
                   </Typography>
                   <Typography className='TypoCard' sx={{ mb: 2.5, height: '5rem' }} color='text.secondary'>
                     {project.project?.description ? truncate(project.project.description, 100) : ''}
                   </Typography>
                   <Box display='flex' alignItems='center'>
                     <Box sx={{ mr: 3.5 }} border='1px dashed #dbdfe9' borderRadius='10px 15px' padding='10px'>
                       <Typography variant='h6' className='TitleCardbox' component='div'>
                         {project.project.lastUpdateTime.slice(0, 10)}
                       </Typography>
                       <Typography className='TypoCardbox' sx={{ mb: 1.5 }} color='text.secondary'>
                         Last update
                       </Typography>
                     </Box>
                     <Box border='1px dashed #dbdfe9' borderRadius='10px' padding='10px'>
                       <Typography variant='h6' className='TitleCardbox' component='div'>
                         {project.project.revision}
                       </Typography>
                       <Typography className='TypoCardbox' sx={{ mb: 1.5 }} color='text.secondary'>
                         Revision
                       </Typography>
                     </Box>
                   </Box>
                   <Tooltip
                     arrow
                     placement='top'
                     sx={{
                       backgroundColor: '#fff',
                       boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1',
                       '.MuiTooltip-arrow': {
                         backgroundColor: 'white',
                       },
                       '.MuiTooltip-popper': {
                         backgroundColor: 'white',
                       },
                     }}
                   >
                     <LinearProgress variant='determinate' value={100} sx={{ mt: 2, '.MuiLinearProgress-bar': { backgroundColor: randomColor } }} />
                   </Tooltip>
                   <Box sx={{ width: 'fit-content', marginTop: '10px' }}>
                     <AvatarGroup max={4}>
                       {project.members.map((person, index) => {
                         const letters = '0123456789ABCDEF';
                         let color = '#';
                         for (let i = 0; i < 6; i++) {
                           color += letters[Math.floor(Math.random() * 8)];
                         }
                         const avatarUrl = `https://dev.azure.com/Fulgid/_apis/GraphProfile/MemberAvatars/${person.id}`;

                         return(
                           <Tooltip title={person.name}>
                           <Avatar1 alt={person.name} src={avatarUrl} sx={{ backgroundColor: color }} />
                           </Tooltip>
                           
                         ) 
                         
                       })}
                     </AvatarGroup>
                   </Box>
                 </CardContent>
            
             </Card>
       
           </Grid>
         );
       })}
     </Grid>
     <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
       <Pagination count={Math.ceil(projects.length / projectsPerPage)} page={currentPage} onChange={handlePageChange} sx={{
                        '.Mui-selected': {
                          backgroundColor: '#1B84FF !important',
                          color:"white"
                        },
                      }} />
     </Box>
     </>
</Container>
    
  </>
   
  )
}

export default ProjectContent