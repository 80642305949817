import React, { useEffect } from 'react';
import LoginComponent from '../../components/LoginComponent';
import loginBackground from "../../public/auth-bg.png";
import loginPic from "../../public/Bg-v3 6 (1).svg";
import logo from "../../public/white-logo.png"
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


function LoginPage() {
  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem("auth")){
        navigate('/dashboard');
    }
}, []);
  return (
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
      <Box sx={{ flex: 1, position: "relative", padding: "5% 5%", width: "50%" }}>
    
        <LoginComponent header="Sign In" secondarybtn="admin login" />
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: "100vh", backgroundImage: `url(${loginBackground})`, backgroundSize: "cover" }}>
        <img style={{ width: "150px", }} src={logo} alt="logo" />
        <img style={{ width: "60%", marginTop:"50px" }} src={loginPic} alt='loginpic' />
        <Box mt={4} sx={{textAlign:"center"}}>
        <Typography variant='h2 ' sx={{textAlign:"center",color:"white", fontWeight:"600", fontSize:"2.25rem"}}>Fast, Efficient and Productive</Typography>
        <Typography variant='body2' sx={{textAlign:"center", color:"white", marginTop:"10px",padding:"5px 10rem", fontSize:".8rem !important"}}>In this kind of post, the blogger introduces a person they’ve interviewed
and provides some background information about the interviewee and their
work following this is a transcript of the interview.</Typography>
        </Box>
    
      
      </Box>
    </div>
  );
}

export default LoginPage;
