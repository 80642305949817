import { Autocomplete, Box, Button, Divider, Menu, MenuItem, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useAuth } from '../context/authContext';

function FilterButton({IsDashBoard, setSelectedState,selectedState, userList, filterValue, setFilterValue}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const {isAdmin}=useAuth()
    const stateList=['active','new','resolved','pause','closed','hold','removed']
    const stateList1=['active','new']
    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
    
    const handleMenuItemClick = (option) => {
      // Handle the selected option here
      setSelectedState(option)
      handleCloseMenu();
    };
  return (
  <>
     <Button variant="contained" sx={{marginRight:"50px"}} className='buttonstyle22' onClick={handleOpenMenu} >
                <div className='buttonstyle' style={{}}>
                 <FilterAltIcon /><div style={{marginLeft:'10px'}}>Filter</div>
                </div>
              </Button>
             
                <Menu
               
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
    
      <Box sx={{margin:"10px 20px",width:"100%"}}>
      <Typography variant='h6' sx={{color:'#071437',fontWeight:"600",paddingBottom:"5px" }}>Filter Option</Typography>
    
     
  
      <Typography variant='subtitle1' sx={{color:"#071437",padding:"10px 0px",fontWeight:"500"}}>State:</Typography>
      <Autocomplete
      sx={{ width:"250px",backgroundColor:"#F9F9F9", borderRadius:"5px"}}
  value={selectedState}
  onChange={(event, newValue) => {
    setSelectedState(newValue !== null ? newValue : '');
  }}
  options={IsDashBoard?stateList1:stateList} 
  renderInput={(params) => (
 
    <TextField {...params} label="" 
    
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none", // Remove the border
        },
      }}
    />
  )}
/>
   <div style={{display:isAdmin?"":"none"}}>
      <Typography variant='subtitle1' sx={{color:"#071437",padding:"10px 0px",fontWeight:"500"}}>Assigned to:</Typography>
      <Autocomplete
      sx={{display:isAdmin?"":"none", width:"250px", backgroundColor:"#F9F9F9",borderRadius:"5px"}}
  value={filterValue}
  onChange={(event, newValue) => {
    setFilterValue(newValue !== null ? newValue : '');
  }}
  options={userList} // Replace with your actual filter options
  renderInput={(params) => (
    <TextField {...params}  label="" variant="outlined" 
    sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none", // Remove the border
        },
      }}
    />
  )}
/>
</div>
</Box>
      
        </Menu>
       </>      
  )
}

export default FilterButton