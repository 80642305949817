import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Modal } from '@mui/material';
import { connect } from 'react-redux';
import { navigateTo, loginUser } from '../redux/actions';
// Import navigateTo action
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import LoadingTick from './LoaderTick';
import { Box } from '@mui/system';
 
const RegisterComponent = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [name, setName]=useState('')
  const [error, setError] = useState({username: '', password: '',repassword:'',name:''});
  const [open, setOpen] = useState(false); 
  const [isLogin,setIsregister]=useState(false)
  const inputStyles = {
    display: "block",
    width: "100%",
    height: "1.075rem",
    padding: "0.8rem .9rem",
    fontSize: ".9rem",
    fontWeight: "500",
    lineHeight: "1.5",
    
    appearance: "none",
    margin: "30px 10px 5px 10px",
    backgroundClip: "padding-box",
    border: "1px solid #DBDFE9",
    borderRadius: "0.475rem",
    outline: "none",
   
    transition: "border-color 0.3s ease",
    '&::placeholder':{
      color:"#EEEDED",
      fontWeight:"600"
    }
  };
 
  const linkStyle = {
    display:"flex",
    width:"435px",
    fontSize:".9rem",
    textAlign: "right",
    color: "#1B84FF",
    fontWeight:"600",
    textDecoration:"none",
    
    fontFamily:"Inter,Helvetica,sans-serif",
   
 
  };
  const navigate = useNavigate();
  const handleLogin = async () => {
    setError({username: '', password: '',repassword:''}); // Reset error messages on each login attempt
    if(username === '' || password === '' || repassword === '') {
      setError({
        username: username === '' ? 'Email address is required' : '',
        password: password === '' ? 'The password is required' : '',
        repassword: repassword === ''? "The repeat password is required":'',
        name:name ===''?"Name is required":''
      });
      return;
    }
    if (password !== repassword) {
        setError({
          ...error,
          password: 'Password and repeat password does not match',
          repassword: 'Password and repeat password does not match',
        });
        return;
      }
    
    try {
        const csrfResponse = await axios.get(`${process.env.REACT_APP_INTERNAL_SERVER_API}/csrf-token`, { withCredentials: true });
        const csrfToken = csrfResponse.data; 
     
      const registerResponse = await axios.post(`${process.env.REACT_APP_INTERNAL_SERVER_API}/register`, {
        email: username,
        password: password,
        password_confirmation: repassword,
        name : name,
        api_token:""
        
      }, {
        headers: {
            'X-CSRF-TOKEN': csrfToken,
          },
        withCredentials: true, 
      });
      
      props.loginUser(registerResponse.data);
      setIsregister(true)
      setOpen(true);
  
    } catch (error) {
    
      console.error('Login failed', error);
      
    
     
    }
  };
  const handleClose = () => {
    setOpen(false);
    navigate('/dashboard'); 
  };
  return (
<Container component="main" disableGutters maxWidth="xs" >
<Typography variant="h5" sx={{ textAlign: 'center', color: '#071437', fontWeight: '700', marginBottom: '.75rem' }}>Reset Password</Typography>
<input type='text' placeholder='Name' value={name} style={inputStyles}
        onChange={(e) => setName(e.target.value)} autoComplete='new-password' />
        {error.name && <div style={{color: '#F8285A',padding:"5px 10px", fontSize:".8rem"}}>{error.name}</div>}
<input type='email' placeholder='Email' value={username} style={inputStyles}
        onChange={(e) => setUsername(e.target.value)} autoComplete='new-password' />
         {error.username && <div style={{color: '#F8285A',padding:"5px 10px", fontSize:".8rem"}}>{error.username}</div>} 
<input  type='password' placeholder='Password' value={password} style={inputStyles}
        onChange={(e) => setPassword(e.target.value)} autoComplete='new-password'  />
         {error.password && <div style={{color: '#F8285A',padding:"5px 10px", fontSize:".8rem"}}>{error.password}</div>} 
     
         <input  type='password' placeholder='Repeat password' value={repassword} style={inputStyles}
        onChange={(e) => setRePassword(e.target.value)} autoComplete='new-password'  />   
        {error.repassword && <div style={{color: '#F8285A',padding:"5px 10px", fontSize:".8rem"}}>{error.repassword}</div>} 
<Button variant="contained" color="primary" onClick={handleLogin} style={{ margin: '30px 8px', padding:" 10px 48px",fontSize:".9rem", backgroundColor: '#1B84FF', width: '30rem', height: '44px', textTransform:"capitalize",   }}>
        Reset Password
</Button>
<span>
  <p style={{ margin:"10px", textAlign:"center", color:"#99A1B7",fontWeight:"500"}}> <Link to={"/"} style={{fontSize:".9rem",
    textAlign: "right",
    color: "#1B84FF",
    fontWeight:"600",
    textDecoration:"none",
    
    fontFamily:"Inter,Helvetica,sans-serif",
   }} >Sign in</Link></p>
</span>

 <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "20rem", backgroundColor: 'white',textAlign:"center", padding:"2rem",
          borderRadius:"0.475rem"
        }}>
          <LoadingTick/>
          <p style={{fontSize:"1.1rem",fontWeight:"400",color:"#252F4A"}} id="modal-modal-description">You have registered successfully!</p>
          <Button variant="contained" disableElevation disableFocusRipple disableRipple disableTouchRipple sx={{backgroundColor:"#1B84FF", color:"white",textTransform:"capitalize", fontSize:"1rem", padding:".6rem 1.2rem", borderRadius:".5rem"}} onClick={handleClose}>Ok, got it!</Button>
        </div>
      </Modal>
</Container>
  );
};
 
const mapDispatchToProps = {
  navigateTo,
  
};
 
export default connect(null, mapDispatchToProps)(RegisterComponent);