// src/redux/reducers/navigationReducer.js
const initialState = {
    currentRoute: '/',
  };
  
  const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'NAVIGATE_TO':
        return { ...state, currentRoute: action.payload };
      default:
        return state;
    }
  };
  
  export default navigationReducer;
  