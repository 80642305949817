import { Box, Button, Drawer, Icon, IconButton, ListItemIcon, MenuItem, Select, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { createWorkItem, getProjectWorkItems } from '../api/api';
import { useAuth } from '../context/authContext';
import { useProjectData } from '../context/projectDataContext';
import { toast } from 'react-toastify';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LoadingTick from './LoaderTick';
import SaveIcon from '@mui/icons-material/Save';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
const inputStyles = {
    display: "block",
    width: "96%",
    height: "1.075rem",
    padding: "0.8rem .9rem",
    fontSize: ".9rem",
    fontWeight: "500",
    lineHeight: "1.5",
  
    appearance: "none",
    margin: "10px 0px 5px 0px",
    backgroundClip: "padding-box",
    border: "1px solid #DBDFE9",
    borderRadius: "0.475rem",
    outline: "none",
   
    '&:hover': {
      border: "1px solid #DBDFE9",
    },
    '&:focus': {
      border: "1px solid #DBDFE9",
    },
    '&.Mui-focused': {
      border: "1px solid #DBDFE9",
    },
    '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',}
  };
 

function AddWorkItemButton() {
    const [addSlide,setAddSlide]=useState(false)
    const [title,setTitle]=useState('');
    const [project,setProject]=useState('');
    const [externalLink,setExternalLink]=useState('');
    const [workItem,setWorkItem]=useState('');
    const [description,setDescription]=useState('')
    const [workItemList,setWorkItemList]=useState([])
    const [relatedWork,setRelatedWork]=useState('')
    const [selectedOption, setSelectedOption] = useState('');
    const [loading, setLoading] = useState(false);
    const [developmentActivity,setDevelopmentActivity]=useState('')
    const [loginSuccess, setLoginSuccess] = useState(false);
    const {token}=useAuth()
    const {projectData}=useProjectData()
    const list=projectData.map(project => project.project.name);
    const id = projectData.reduce((acc, project) => {
      acc[project.project.name] = project.project.id;
      return acc;
    }, {});
    const activityList=['Development','Maintenance','Deployment','Design']
    const [projectId,setProjectId]=useState('')
    const options = [
      { value: 'Task', label: 'Task', iconName: <AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px",color:"rgb(164, 136, 10)", marginRight:"8px"}}/> },
      { value: 'Bug', label: 'Bug', iconName: <BugReportOutlinedIcon sx={{fontSize:"18px",color:"rgb(204, 41, 61)",marginRight:"8px"}}/> },
      { value: 'Issue', label: 'Issue', iconName: <ErrorOutlineOutlinedIcon sx={{fontSize:"18px",color:"#b4009e",marginRight:"8px"}}/>  },
      { value: 'Feature', label: 'Feature', iconName: <EmojiEventsIcon sx={{fontSize:"18px",color:"rgb(180, 0, 158)",marginRight:"8px"}}/> },
    ];
    const fetchData = async (e) => {
      try {
        const workItemsData = await getProjectWorkItems(token,e);
    
        // Assuming workItemsData is an array of work items
        const formattedWorkItems = workItemsData['workitems'].map((workitem) => ({
          id: workitem.Work_ID,
          title: workitem.System_Title
        }));
    
        setWorkItemList(formattedWorkItems);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching work items:', error);
      }
    };


    const handleOpenAddSlide=()=>{
        setAddSlide(true)
      }
const handleCloseAddSlide =()=>{
    setAddSlide(false)
}

const handleCreateTask=async(e)=>{
  setLoading(true)
    e.preventDefault();
const addTask=await createWorkItem(token,title,workItem,project,description,externalLink,relatedWork,projectId,developmentActivity);

toast.success('New work item created successfully!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  setLoading(false)
setTitle('');
    setWorkItem('');
    setProject('');
    setDescription('');
    setExternalLink('');
    setRelatedWork('');
    setProjectId('');
    setSelectedOption('');
    setWorkItemList([]);
    setDevelopmentActivity('')
setAddSlide(false)
}

let bgcolor; 
let icon;
let bgcoloricon;
const setcolor=(params)=>{
  switch(params){
    case 'Task': {
      bgcoloricon = '#FAF3D3'
      bgcolor = 'rgb(164, 136, 10)'
      icon = <AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px", marginRight:"8px"}}/>
      break;
    }
    case 'Bug': {
      bgcoloricon = '#FFDFDD'
      bgcolor = 'rgb(204, 41, 61)' // Change this to the color you want for 'Bug'
      icon = <BugReportOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/> // Change this to the icon you want for 'Bug'
      break;
    }
    case 'Feature': {
      bgcoloricon = '#F5F0FF'
      bgcolor = 'rgb(180, 0, 158)' // Change this to the color you want for 'Feature'
      icon = <EmojiEventsIcon sx={{fontSize:"18px",marginRight:"8px"}}/>// Change this to the icon you want for 'Feature'
      break;
    }
   
   
    case 'Issue': {
      bgcoloricon = '#f8e2f6'
      bgcolor = '#b4009e'// Change this to the color you want for 'Epic'
      icon = <ErrorOutlineOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/> // Change this to the icon you want for 'Epic'
      break;
    }
    default:{
      bgcoloricon = '#000000';
      bgcolor="#000000";
      icon=<AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>
      // Default case
    }
}

}

const color={
  Issue:"#b4009e",
  Task:"rgb(164, 136, 10)",
  Bug:"rgb(204, 41, 61)",
  Feature:"rgb(180, 0, 158)"
}
const icons={
  Issue:<ErrorOutlineOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>,
  Task:<EmojiEventsIcon sx={{fontSize:"18px",marginRight:"8px"}}/>,
  Bug:<BugReportOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>,
  Feature:<AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px", marginRight:"8px"}}/>
}

  return (
    <div>

        <Button sx={{ my: 2, color: 'white', textTransform:"capitalize",backgroundColor:"#1B84FF" ,"&:hover":{backgroundColor:"#1b84ff"}}} 
          disableElevation disableFocusRipple disableRipple
          onClick={()=>{handleOpenAddSlide()}}>
              <div style={{display:"flex", justifyContent:"center", alignItems:"center",fontSize:"16px"}}><AddIcon sx={{fontSize:"20px"}}/> New Work Item</div>  
              </Button>
              <Drawer
              anchor="right"
  open={addSlide}
  onClose={handleCloseAddSlide}
  sx={{
    width: '50%',
  
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: '50%',
      boxSizing: 'border-box',
    },
  }}>
  <Box p={2}>
  <Box sx={{display:"flex",justifyContent:"flex-end"}}>
  <IconButton onClick={handleCloseAddSlide}>
      <CloseIcon sx={{color:"#000000", fontSize:"28px"}} />
    </IconButton>
  </Box>
  <Box pl={5} pr={5}>
    <Typography variant="h6" sx={{ color: '#071437', fontWeight: '600' }}>Create Work Item</Typography>
    <Typography variant="body2" sx={{ color: '#99A1B7', fontWeight: '500' }}>
    Empower Your Productivity: Let Us Craft Your Tasks with Precision and Care!
        </Typography>
    <Box mt={5}>
    <form onSubmit={handleCreateTask}>
        <Box mt={2}>
        <label style={{color:"#99A1B7", fontWeight:"600"}} for="title">Title:<span style={{ color: "red" }}><sup>*</sup></span></label>
            <input style={inputStyles} value={title} type="text" id="title" name="title" required
                onChange={(e)=>setTitle(e.target.value)}
            />
        </Box>
       
        <Box mt={2}>
        <label style={{color:'#99A1B7', fontWeight:"600"}} for="type">Work Item Type:<span style={{ color: "red" }}><sup>*</sup></span></label>
        <Select
  sx={{ ...inputStyles, height: '45px', width: '100%', border:"0px solid #DBDFE969",color: selectedOption===''?"#99A1B7":color[selectedOption],padding:"0px",
  display:"flex", alignItems:"center",'.MuiOutlinedInput-input':{display:"flex",alignItems:"center",border:"0px solid #DBDFE969"
  }, '.MuiSelect-select': {
              border: "0px solid #DBDFE9", // Remove border on focus
            },'& .MuiOutlinedInput-notchedOutline': {
  border: "1px solid #DBDFE9",
},

  
  }}
  value={workItem}
  id="project"
  name="project"
  required
 
  onChange={(e) => {
    setWorkItem(e.target.value);
    setSelectedOption(e.target.value);
  }}
>
<MenuItem value='' selected>Select Type</MenuItem>
{options.map((option, index) => (
    <MenuItem key={index} value={option.value} sx={{display:"flex", color:color[option.label],alignItems:"center"}}>
      <ListItemIcon sx={{minWidth:"0px", height:"100%",}}>
        {option.iconName}
      </ListItemIcon>
      <Typography variant="inherit">{option.label}</Typography>
    </MenuItem>
  ))}
</Select>

        </Box>
       
        <Box mt={2}>
        <label style={{color:"#99A1B7", fontWeight:"600"}} for="project">Project:<span style={{ color: "red" }}><sup>*</sup></span></label>
        <Select
   sx={{ ...inputStyles, height: '45px', width: '100%', border:"0px solid #DBDFE969",padding:"0px",
  display:"flex", alignItems:"center",'.MuiOutlinedInput-input':{display:"flex",alignItems:"center",border:"0px solid #DBDFE969"
  }, '.MuiSelect-select': {
              border: "0px solid #DBDFE9", // Remove border on focus
            },'& .MuiOutlinedInput-notchedOutline': {
  border: "1px solid #DBDFE9",
},

  
  }}
    value={project}
    id="project"
    name="project"
    required
    onChange={(e) => {setProject(e.target.value)
    setProjectId(id[e.target.value])
    fetchData(e.target.value);
   
    }}
>
    {list.map((option, index) => (
    <MenuItem key={index} value={option} sx={{display:"flex", alignItems:"center"}}>
    
      {option}
    </MenuItem>
  ))}
  
</Select>
        </Box>
        <Box mt={2}>
        <label style={{color:"#99A1B7", fontWeight:"600"}} for="project">Development Activity:<span style={{ color: "red" }}><sup>*</sup></span></label>
        <Select
   sx={{ ...inputStyles, height: '45px', width: '100%', border:"0px solid #DBDFE969",padding:"0px",
  display:"flex", alignItems:"center",'.MuiOutlinedInput-input':{display:"flex",alignItems:"center",border:"0px solid #DBDFE969"
  }, '.MuiSelect-select': {
              border: "0px solid #DBDFE9", // Remove border on focus
            },'& .MuiOutlinedInput-notchedOutline': {
  border: "1px solid #DBDFE9",
},

  
  }}
    value={developmentActivity}
    id="project"
    name="project"
    required
    onChange={(e) => {setDevelopmentActivity(e.target.value)   
    }}
>
    {activityList.map((option, index) => (
    <MenuItem key={index} value={option} sx={{display:"flex", alignItems:"center"}}>
    
      {option}
    </MenuItem>
  ))}
  
</Select>
        </Box>
        <Box mt={2}>
        <label style={{color:"#99A1B7", fontWeight:"600"}} for="description">Description:<span style={{ color: "red" }}><sup>*</sup></span></label>
        <textarea style={{ ...inputStyles, height: '100px' }} value={description} id="description" name="description" rows="4" 
        onChange={(e)=>setDescription(e.target.value)}></textarea>
        </Box>
        <Box mt={2}>
        <label style={{color:"#99A1B7", fontWeight:"600"}} for="external-link">External Link: <span style={{fontWeight:"400"}}>(optional)</span></label>
        <input style={inputStyles} type="url" id="external-link" value={externalLink} name="external-link"
            onChange={(e)=> setExternalLink(e.target.value)}
        />
        </Box>
        <Box>
        <label style={{color:"#99A1B7", fontWeight:"600"}} for="project">Related Work: <span style={{fontWeight:"400"}}>(optional)</span></label>
        <Select
   sx={{ ...inputStyles, height: '45px', width: '100%', border:"0px solid #DBDFE969",padding:"0px",
  display:"flex", alignItems:"center",'.MuiOutlinedInput-input':{display:"flex",alignItems:"center",border:"0px solid #DBDFE969"
  }, '.MuiSelect-select': {
              border: "0px solid #DBDFE9", // Remove border on focus
            },'& .MuiOutlinedInput-notchedOutline': {
  border: "1px solid #DBDFE9",
},

  
  }}
    value={relatedWork}
    id="project"
    name="project"

    onChange={(e) => setRelatedWork(e.target.value)}
>
    {workItemList.map((option, index) => (
    <MenuItem key={index} value={option.id} sx={{display:"flex", alignItems:"center"}}>
    
      {option.id} - {option.title}
    </MenuItem>
  ))}
  
</Select>
        </Box>
        <Button variant='contained' sx={{ my: 2, color: 'white', textTransform:"capitalize",backgroundColor:"#1B84FF" ,"&:hover":{backgroundColor:"#1b84ff"}}} 
        disableElevation disableFocusRipple disableRipple
        type="submit" >{loading ? 'Creating...' : <>Create <SaveOutlinedIcon sx={{fontSize:"18px",marginLeft:"5px"}}/></>}
      {loading && <LoadingTick />}</Button>

        
    </form>
    </Box>
  </Box>
  </Box>

              </Drawer>
    </div>
  )
}

export default AddWorkItemButton