
// reducer.js

import {storeCSRFToken } from '../actions/index';

const initialState = {

  csrfToken: null,
};

const csrfTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeCSRFToken:
      console.log(state)
      return {
        ...state,
        csrfToken: action.payload,
      };

    default:
      return state;
  }
};

export default csrfTokenReducer;
