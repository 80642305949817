import React, { Fragment } from 'react'
import  { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Papa from 'papaparse';
import logo from "../public/logo.png"
import PlaceIcon from '@mui/icons-material/Place';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Menu,
  Tooltip,
  Pagination,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useAuth } from '../context/authContext';
import { getAttendenceReport, getProjectMonthlyData } from '../api/api';
import { useTable, useExpanded, usePagination } from 'react-table';
import TableProjectView from './TableProjectView';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Navigate, useNavigate } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import './style.css';
import detailpageimg from "../public/detailpageimg.png"
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import Badge from '@mui/material/Badge';
import { getUsersAttendance } from '../api/adminApi';
import AttendanceCalendar from './AttendanceCalendar';
function AttendanceContent() {
    const currentDate = new Date();
    const year=currentDate.getFullYear();
    const month= currentDate.getMonth();
    const [selectedUser, setSelectedUser] = useState(null);
    const startingDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const [start, setStart] = useState(startingDateOfMonth.toISOString().split('T')[0]);
    const [end, setEnd] = useState(currentDate.toISOString().split('T')[0]);
    const [selectedExportType, setSelectedExportType] = useState();
    const { token } = useAuth();
    const [data, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [totalHours,setTotalHours]=useState(0);
    const [present,setPresent]=useState(0);
    const [absent,setAbsent]=useState(0);


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    
    };
  
    
    // const fetchAdminData = async () => {
    //   try {
    //     const reportData = await getUsersAttendance(token, year, month);
        
     
        
    //     // Filter the data based on the selected user
    //     const selectedUserData = reportData.data.day_type_report.find(user => user.user_name === selectedUser);
    
    //     // Set the filtered data   
    //     const presentCount = reportData.data.present_count;
    //     const absentCount = reportData.data.absent_count;
    //     setPresent(presentCount);
    //     setAbsent(absentCount);
    //     setRows(selectedUserData ? selectedUserData.details.reverse() : []);
    //     setTotalHours(selectedUserData ? selectedUserData.total_days : 0);
    //     setIsLoading(false);
    //   } catch (error) {
    //     setIsLoading(false);
    //     console.error('Error fetching data:', error);
    //   }
    // };
    
    const fetchData = async () => {
      try {
        const reportData = await getAttendenceReport(token, start, end);
        const presentCount = reportData.data.present_count;
        const absentCount = reportData.data.absent_count;
        setPresent(presentCount)
        setAbsent(absentCount)
        setRows(reportData.data.attendance.reverse());
        setTotalHours(reportData.data.totalhours)
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };
  
    const handleStartDateChange = (event) => {
      const newStartDate = event.target.value;
      setStart(newStartDate);
    };
  
    const handleEndDateChange = (event) => {
      const newEndDate = event.target.value;
      setEnd(newEndDate);
    };
  
  
    const handleExportCSV = async() => {
      const allData = await getAttendenceReport(token, start, end);
      const csvData = allData.data.attendance || [];
        const csvHeaders = [
          { label: 'Date', key: 'date' },
          { label: 'Login Time', key: 'loginTime' },
          { label: 'Logout Time', key: 'logoutTime' },
          { label: 'Work Hours (in mins)', key: 'total_mins' },
          { label: 'Status', key: 'status' },
          { label: 'IP Address', key: 'ipAddress' },
          { label: 'Location', key: 'location' },
        ];
      
        // Create CSV data
        const csvRows = csvData.map(row => {
            const { loginTime, logoutTime, ipAddress, location } = getLoginLogoutTimes(row.punch_records);
            return {
              date: row.date,
              loginTime: loginTime,
              logoutTime: logoutTime,
              total_hours: row.total_mins,
              status: row.status,
              ipAddress: ipAddress,
              location: location,
            };
          });
      
        // Create CSV data URI
        const csvReport = {
          data: csvRows,
          filename: 'report.csv',
        };
      
        // Convert CSV data to a data URI
        const csvDataURI = encodeURI(
          `data:text/csv;charset=utf-8,${Papa.unparse(csvReport.data, { header: true })}`
        );
      
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = csvDataURI;
        link.target = '_blank';
        link.download = csvReport.filename || 'download.csv';
      
        // Trigger the download
        link.click();
      };
      
      const handleExportPDF = async() => {
        const allData = await getAttendenceReport(token, start, end);
    const pdfData = allData.data.attendance || [];
        const doc = new jsPDF();
        const logoBase64 = logo;
        const pdfWidth = doc.internal.pageSize.getWidth();
        const logoWidth = 30; // Adjust the logo width if needed
        const logoX = (pdfWidth - logoWidth) / 2;
        doc.addImage(logoBase64, 'JPEG', logoX, 10, logoWidth, 18);
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        const text1 = doc.internal.scaleFactor + 12
        const text2 = 32; // Position 10 units above the bottom of the page
      
        doc.setFontSize(10);
        doc.text(`Attendance Report from ${start} to ${end}`, text1, text2);
        doc.text(`Total Days: ${present + absent}   Present Days: ${present} days   Absent Days: ${absent} days`, text1, text2 +5);
    
        // Set column headers
        const headers = ['Date', 'Login Time', 'Logout Time', 'Work Hours (in mins)', 'Status', 'IP Address', 'Location'];
      
        // Extract data rows
        const dataRows = pdfData.map(row => {
            const { loginTime, logoutTime, ipAddress, location } = getLoginLogoutTimes(row.punch_records);
            return [
              row.date,
              loginTime,
              logoutTime,
              row.total_mins,
              row.status,
              ipAddress,
              location,
            ];
          });
      
        // AutoTable settings
        const tableConfig = {
          startY: 40,
          head: [headers],
          body: dataRows,
        };
      
        // Add table to the PDF
        doc.autoTable(tableConfig);
      
        // Save the PDF
        const footerStr = "*This is a system generated document or report";
    const textSize = 8; // Size of the text
   
  
    // Calculate the text's x and y coordinates to center it at the bottom of the page
    const textX = doc.internal.scaleFactor + 10
    const textY = pageHeight - 10; // Position 10 units above the bottom of the page
  
    doc.setFontSize(textSize);
    doc.text(footerStr, textX, textY);
    // Save the PDF
    doc.save(`report_${start}-${end}.pdf`);
  
      
      };
      
      const getLoginLogoutTimes = (punchRecords) => {
        const sortedPunchRecords = punchRecords.sort((a, b) => new Date(a.login_time) - new Date(b.login_time));
        const loginTime = sortedPunchRecords.length > 0 ? sortedPunchRecords[0].login_time : 'No login record';
        const logoutTime =sortedPunchRecords.length > 0 ? sortedPunchRecords[0].logout_time : 'No logout record';
        const ipAddress = sortedPunchRecords.length > 0 ? sortedPunchRecords[sortedPunchRecords.length - 1].ip_address : 'No IP address';
        const location = sortedPunchRecords.length > 0 ? sortedPunchRecords[sortedPunchRecords.length - 1].location : 'No location';
      
        return { loginTime, logoutTime, ipAddress, location };
      };
      const [exportInProgress, setExportInProgress] = useState(false);

      const handleExport = () => {
        if (selectedExportType === 'csv') {
          handleExportCSV();
        } else if (selectedExportType === 'pdf') {
          handleExportPDF();
        }
        // Add more export types as needed
      };
    
      useEffect(() => {
        if (exportInProgress) {
          handleExport();
          setExportInProgress(false);
        }
      }, [exportInProgress]);
    
      const handleMenuClick = (exportType) => {
        setSelectedExportType(exportType);
        handleClose();
        setExportInProgress(true);
      };
  
    useEffect(() => {
      fetchData();
    }, [start, end]);
  
    const handleNavigation = () => {
      navigate('/dashboard/projects');
    };
  
  
    const MyTable = ({ data }) => {
  
        const getLoginLogoutTimes = (punchRecords) => {
            const sortedPunchRecords = punchRecords.sort((a, b) => new Date(a.login_time) - new Date(b.login_time));
            const loginTime = sortedPunchRecords.length > 0 ? sortedPunchRecords[0].login_time : 'No login record';
            const logoutTime =sortedPunchRecords.length > 0 ? sortedPunchRecords[0].logout_time : 'No logout record';
            const ipAddress = sortedPunchRecords.length > 0 ? sortedPunchRecords[sortedPunchRecords.length - 1].ip_address : 'No IP address';
            const location = sortedPunchRecords.length > 0 ? sortedPunchRecords[sortedPunchRecords.length - 1].location : 'No location';
          
            return { loginTime, logoutTime, ipAddress, location };
          };
          
          const columns = React.useMemo(
            () => [
              {
                Header: 'Date',
                accessor: 'date',
              },
              {
                Header: 'Checkin',
                accessor: 'punch_records1',
                Cell: ({ row }) => {
                  const { loginTime } = getLoginLogoutTimes(row.original.punch_records);
                  return <div>{loginTime}</div>;
                },
              },
              {
                Header: 'Checkout',
                accessor: 'punch_records2',
                Cell: ({ row }) => {
                  const { logoutTime } = getLoginLogoutTimes(row.original.punch_records);
                  return <div>{logoutTime}</div>;
                },
              },
              
              {
                Header: 'Work Hours ',
                accessor: 'total_mins',
               
              },
              {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ row }) => {
                  const bgcolor = {
                    'Full Day': '#17C653',
                    'Half Day':'#FFAD0F',
                    Absent: '#FD4131',
                    Leave: '#1B84FF',
                  };
              const text = row.original.status=== 'Present'?row.original.category:row.original.status;
                  const showBadge = row.original.punch_records.length > 1;
              const color=row.original.status=== 'Present'?row.original.category:row.original.status;
                  return (
                    <div style={{ position: 'relative' }}>
                      {showBadge && (
                        <Badge
                            variant="dot"
                              color="error"
                              size="small"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              badgeContent=""
                            >
                        <div
                          style={{
                            margin:"5px",
                            backgroundColor: bgcolor[color] || '#FFFFFF',
                            width: '100px',
                            color: 'white',
                            padding:"1px",
                            textAlign: 'center',
                            borderRadius: '10px',
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              <div>
                                {row.original.punch_records.map((record, index) => (
                                  <div key={index}>
                                    Login: {record.login_time} - Logout: {record.logout_time}
                                  </div>
                                ))}
                              </div>
                            }
                          >
                           
                              <p style={{margin:"2px"}}>{text}</p>
                          
                          </Tooltip>
                        </div>
                        </Badge>
                      )}
                      {!showBadge && (
                        <div
                          style={{
                            margin:"5px",
                            backgroundColor: bgcolor[color] || '#FFFFFF',
                            width: '100px',
                            padding:"1px",
                            color: 'white',
                            textAlign: 'center',
                            borderRadius: '10px',
                          }}
                        >
                          <p style={{margin:"2px"}}>{text}</p>
                        </div>
                      )}
                    </div>
                  );
                },
              },
              {
                Header: 'IP Address',
                accessor: 'ipAddress',
                Cell: ({ row }) => {
                    const { ipAddress } = getLoginLogoutTimes(row.original.punch_records);
                    return <div>
                    {ipAddress}
                    </div>;
                  },
              },
            
{
  Header: 'Location',
  accessor: 'location',
  Cell: ({ row }) => {
    const { location } = getLoginLogoutTimes(row.original.punch_records);

    const handleLocationClick = () => {
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`;
      window.open(googleMapsUrl, '_blank');
    };

    return (
      <div>
        <Tooltip arrow placement="top" title={location}>
          <PlaceIcon sx={{ color: "red", cursor: "pointer", fontSize:"32px" }} onClick={handleLocationClick} />
        </Tooltip>
      </div>
    );
  },
},
            ],
            [data]
          );
          // ...
        
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
        setPageSize, 
        gotoPage, // Add gotoPage to the list of destructured values
      } = useTable(
        {
          columns,
          data: React.useMemo(() => data || [], [data]),
          initialState: { pageIndex: 0, pageSize: 10 }, // Set initial page index and page size
        },
        useExpanded,
        usePagination // Add usePagination hook
      );
      
  
    // if (rows.length === 0) {
    //   return <div style={{textAlign:"center", color:"grey"}}><h4>No Attendance Record</h4></div>;
    // }
  
  
    return (
        <>
      <table {...getTableProps()} style={{ width: '100%', textAlign: 'left', marginTop: '10px', borderCollapse: 'collapse' }}>
        <thead style={{ backgroundColor: '#F9F9F9', fontSize: '16px' }}>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id} style={{ padding: '20px 10px', borderBottom: '1px solid #DBDFE9' }} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.id} style={{ borderBottom: '1px solid #eeeef0' }} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td key={cell.id} style={{ padding: '20px 10px' }} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', borderTop: "1px solid #f0f0f0" }}>
    <div style={{ display: 'flex', justifyContent: 'left', marginTop: '20px' }}>
        <div>
          <span>
            Rows per page:{' '}
            <select
            style={{border:"none", backgroundColor:"#1B84FF", color:"#fff", padding:"5px", borderRadius:"5px"}}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5,10, 20, 30, 40, 50].map((pageSizeOption) => (
                <option key={pageSizeOption} value={pageSizeOption}>
                  {pageSizeOption}
                </option>
              ))}
            </select>
          </span>
        </div>
      </div>
    <div style={{display:"flex",justifyContent:"flex-end", margin:"10px"}}>
    <Pagination
        shape="rounded"
        count={Math.ceil(data.length / pageSize)}
        page={pageIndex + 1}
        onChange={(event, value) => {
          gotoPage(value - 1);
        }}
        sx={{
          '.Mui-selected': {
            backgroundColor: '#1B84FF !important',
            color:"white"
          },
        }}
      />
</div>
</div>
</>
    );
  };
  
 
  
    if (isLoading) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="loader1" style={{ backgroundColor: '#17C653', width: '40px' }}></div>
        </Box>
      );
    }
  

  return (
    <Container fixed sx={{ alignItems: 'center', marginTop: '20px', maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' } }}>
    <Box sx={{ display: 'flex !important', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box>
        <Typography variant="h6" sx={{ color: '#071437', fontWeight: '600' }}>
          Attendance Report
        </Typography>
        <Typography variant="body2" sx={{ color: '#99A1B7', fontWeight: '600' }}>
          Home - Attendance Report
        </Typography>
      </Box>

      <Button
        variant="text"
        sx={{ textTransform: 'capitalize', color: '#252F4A', backgroundColor: '#F1F1F4', fontWeight: '600' }}
        onClick={handleNavigation}
      >
        My Projects
      </Button>
    </Box>
    <Paper sx={{ padding: '10px 20px', marginTop: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 5%' }}>
        <Box sx={{width:"60%"}}>
        <Box>
        <Typography variant="h6" sx={{ color: '#252F4A', fontWeight: '600' }}>
          Monthly Attendance Report
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: '100', color: '#000000' }}>
            Retrieve a project overview incorporating time tracking.
          </Typography>
        </Box>
          <Box mt={2} sx={{display:"flex", justifyContent:"space-evenly", alignItems:"center", paddingTop:"30px", marginLeft:'-40px', paddingBottom:"30p"}}>
            <Box><WorkHistoryOutlinedIcon sx={{fontSize:"64px"}}/></Box>
            <Box>
                <Typography variant='h3' sx={{color:"#1B84FF", fontSize:'2rem', fontWeight:"700"}}>{totalHours}</Typography>
                <Typography variant='h4' sx={{color:"#000000",fontSize:'1.25rem', fontWeight:"400"}}>Total Hours</Typography>
            </Box>
            <Box sx={{backgroundColor:"#f4f4f4", padding:"50px 2px", marginLeft:"20px", marginRight:"20px"}}></Box>
            <Box>
                <Typography variant='h4' sx={{color:"#17C653", fontWeight:"600"}} >{present} days<span style={{color:"#78829D",fontSize:"16px", fontWeight:"500", fontFamily: "monospace"}}>  Present</span></Typography>
                <Typography variant='h4' sx={{color:"#FD4131", fontWeight:"600"}}>{absent} days<span style={{color:"#78829D", fontSize:"16px", fontWeight:"500", fontFamily: "monospace"}}>  Absent</span></Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ paddingRight: '4%' }}>
          <img src={detailpageimg} alt="pictue" />
        </Box>
      </Box>
      <Box sx={{  backgroundColor: '#F9F9F9', padding: '20px 5%' }}>
        <Box sx={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
         
          <Button
            variant="contained"
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              color: '#1B84FF',
              marginRight: '10px',
              backgroundColor: '#E9F3FF',
              fontWeight: '600',
              '&:hover': { backgroundColor: '#E9F3FF' },
            }}
          >
            MY report
          </Button>
        </Box>
        <Box sx={{ display: 'inline-flex;', justifyContent: 'space-between', alignItems: 'center',marginTop:"-5px", float: "right" }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* <Autocomplete
  sx={{ width: "300px", backgroundColor: "#F9F9F9", borderRadius: "5px" }}
  value={selectedUser}
  onChange={(event, newValue) => {
    setSelectedUser(newValue !== null ? newValue : "");
  }}
  options={projectUserOptions} // Use projectTypeOptions here
  renderInput={(params) => (
    <TextField
      {...params}
      label=""
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
    />
  )}
/> */}
          <Typography variant="body1" sx={{ color: '#78829D', fontWeight: '600', paddingTop:"10px" }}>
            Filter 
          </Typography>

          <input type="date" placeholder="From Date" value={start} onChange={handleStartDateChange} />
          <input type="date" placeholder="End Date" value={end} onChange={handleEndDateChange} />
        </Box>
      
        <Box>
          <Button className="buttonstyle22" variant="contained" onClick={handleClick}>
            <div className="buttonstyle">
              <FileDownloadIcon />
              <div style={{ marginLeft: '10px' }}>Export</div>
            </div>
          </Button>
          <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={()=>{
       
        handleClose()}}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
     <MenuItem onClick={() => handleMenuClick('pdf')}>PDF</MenuItem>
      <MenuItem onClick={() => handleMenuClick('csv')}>CSV</MenuItem>
     
    </Menu>
        </Box>
      </Box>
      </Box>
      <Box>
        {/* <MyTable data={data} /> */}
       
      </Box>
    <Box mt={5}>
      <AttendanceCalendar data={data}/>
    </Box>
    </Paper>
  </Container>
  )
}

export default AttendanceContent