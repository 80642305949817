import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { postUserActivity } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';

const LoginWithWhatsApp = () => {
  const [qrCode, setQrCode] = useState('');
  const [loginKey, setLoginKey] = useState('');
  const [loginStatus, setLoginStatus] = useState('pending');
  const [loading, setLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const navigate = useNavigate();
    const {login } = useAuth();

    useEffect(() => {
        // Fetch QR code and key from the backend
        setLoading(true); // Set loading to true when fetching starts
        axios.get('https://internal.testing-server.co.in/api/generate-qr-code')
          .then(response => {
            setQrCode(response.data.qrCode);
            setLoginKey(response.data.key);
            setLoading(false); // Set loading to false when fetching is done
          })
          .catch(error => {
            console.error('Error fetching QR code and key:', error);
            setLoading(false); // Set loading to false in case of error
          });
      }, []);


useEffect(() => {
    let interval;
  
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get(`https://internal.testing-server.co.in/api/check-login-status/${loginKey}`);
        console.log(response.data.token);
        
            console.log('loggedin');
          await toast.success('Login successful!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          
          login(response.data.token, response.data.users.name, response.data.users.email, response.data.role);
         
          setLoginSuccess(true);
  
          setTimeout(() => {
            setLoading(false);
            setLoginSuccess(false);
            navigate('/dashboard');
          }, 1000);
  
          await postUserActivity(response.data.token, 'User Signed In');
          clearInterval(interval);
        
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };
  
    if (loginKey) {
      interval = setInterval(checkLoginStatus, 30000); // Interval set to 30 seconds (30000 milliseconds)
    }
  
    return () => clearInterval(interval);
  }, [loginKey, navigate, login]);
  
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{
        textAlign: 'center',
    }}>
      <h4>Login with WhatsApp</h4>
      <img src={qrCode} alt="QR Code" style={{ height: '100px'}} />
      {loginStatus === 'success' && <p>Login successful!</p>}
    </div>
  );
};

export default LoginWithWhatsApp;
