// src/redux/reducers/index.js
import { combineReducers } from 'redux';
import navigationReducer from './navigationReducer';
import userReducer from './UserReducer';
import projectsReducer from './PojectReducer';
import activeWorkItemDataReducer from './ActiveWorkItemReducer';
import csrfTokenReducer from './CsrfTokenReducer';
const rootReducer = combineReducers({
  navigation: navigationReducer,
  user: userReducer,
  projects: projectsReducer,
  activeWorkitem: activeWorkItemDataReducer,
  csrfToken: csrfTokenReducer,

  
});

export default rootReducer;
