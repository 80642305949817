import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { navigateTo } from '../../redux/actions';
import { useAuth } from '../../context/authContext';
import { ProjectDataProvider } from '../../context/projectDataContext';
import { PieCharttDataProvider } from '../../context/pieChartContext';
import { OverallWorkItemProvider } from '../../context/overallWorkitemContext';
import { TimerStatsProvider } from '../../context/timerStatsContext';
import ProjectContent from '../../components/ProjectContent';

function Home() {
   
    const navigate = useNavigate();
    const {token}= useAuth()
 
    useEffect(() => {
        if(!token){
          
            navigate('/');
        }
    }, []);

    return (
        <ProjectDataProvider>
        <TimerStatsProvider>
        <PieCharttDataProvider>
        <OverallWorkItemProvider>
       
        <div>
            <Sidebar/>
            <Routes>
        {/* Your existing routes */}
        <Route path="/project/:projectId" element={<ProjectContent />} />
      </Routes>
        </div>
        </OverallWorkItemProvider>
        </PieCharttDataProvider>
        </TimerStatsProvider>
        </ProjectDataProvider>
    )
}

export default Home;

