import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Container,  Button, Badge, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, InputLabel } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Avatar from 'react-avatar';
import { ChangePassword, getLogout, getTimerCount, getUserActivity } from '../api/api';
import { toast } from 'react-toastify';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useAuth } from '../context/authContext';
import { useTimerStats } from '../context/timerStatsContext';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VerticalStepperWithPagination from './ActivityStepper';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AddWorkItemButton from './AddWorkItemButton';

const pages = ['', '', ];
const Settings=['User Activity','Change Password','Logout']
const inputStyles = {
  display: "block",
  width: "90%",
  height: "1.075rem",
  padding: "0.8rem .9rem",
  fontSize: ".9rem",
  fontWeight: "500",
  lineHeight: "1.5",

  appearance: "none",
  margin: "20px 0px 5px 0px",
  backgroundClip: "padding-box",
  border: "1px solid #DBDFE9",
  borderRadius: "0.475rem",
  outline: "none",
 
  transition: "border-color 0.3s ease",
  '&::placeholder':{
    color:"#4B5675",
    fontWeight:"600"
  }
};



const Navbar = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] = useState(false);
  const [timer,setTimer]=useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState({ password: '',repeatpassword:'',name:''});
  const {token, email,name}= useAuth()
  const {timerStats}=useTimerStats();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [addSlide,setAddSlide]=useState(false)
  const handleDrawerOpen = () => {
    
    handleCloseUserMenu()

    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetPassword= async ()=>{
    setError({username: '', password: '',repeatpassword:''}); // Reset error messages on each login attempt
    if( password === '' || repeatPassword === '') {
      setError({
       
        password: password === '' ? 'The password is required' : '',
        repeatPassword: repeatPassword === ''? "The repeat password is required":'',
        
      });
      return;
    }
    if (password !== repeatPassword) {
        setError({
          ...error,
          password: 'Password and re-type password does not match',
          repeatpassword: 'Password and re-type password does not match',
        });
        return;
      }
   
      try{
        const changePassword =await ChangePassword(token,email,password,repeatPassword);
        
        toast.success("Password changed Successfully!",{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setOpen(false);

      }catch(error){
          console.log(error)
          toast.error("Error occured during api call!",{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
      }


  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

const handleOpenAddSlide=()=>{
  setAddSlide(true)
}
  
  const handleCloseUserMenu = async () => {
  
    setAnchorElUser(null);
  };

 const handleLogout =async ()=>{
  try {
   
    await getLogout(token, navigate);

    navigate('/');
  } catch (error) {
    console.error('Logout failed', error);
  }
 
 }

const handleFunctions={
  'User Activity':handleDrawerOpen,
  'Change Password':handleClickOpen,
 'Logout':handleLogout,
}

  const renderMenuItems = (items, onClose) => (
    items.map((item) => (
      <MenuItem key={item} onClick={handleFunctions[item]} sx={{ '&:hover': { backgroundColor: '##f6f5f5', borderRadius:'5px' } }}>
        <Typography textAlign="center" sx={{color:item==='Logout'?"red":"#333", fontSize:"13px", fontWeight:'400',       '&:hover': { color: '#1B84FF' }, // Change 'your_hover_color' to the desired color
}} >{item}</Typography>
      </MenuItem>
    ))
  );
  const inputStyles = {
    // ... (existing styles)
    visibilityIcon: {
      cursor: 'pointer',
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#6B6B6B',
    },
  };
  return (
    <AppBar sx={{ background: "transparent", boxShadow: "none" }} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="inherit">
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <AddWorkItemButton/>
            {pages.map((page,index) => (
              <Button key={index} sx={{ my: 2, color: 'white', display:page!==""?'block':"none", textTransform:"capitalize",backgroundColor:"#1B84FF" }} onClick={()=>{handleOpenAddSlide()}}>
              <div style={{display:"flex", justifyContent:"center", alignItems:"center",fontSize:"16px"}}><AddIcon sx={{fontSize:"20px"}}/> {page}</div>  
              </Button>
            ))}
          </Box>
          
          {timerStats!==0?  

          (
            <Box sx={{ position: 'relative', textAlign: 'center',paddingRight:"20px" }}><AccessTimeIcon sx={{ color: 'grey', fontSize: '30px', paddingTop:"5px", paddingBottom:"3px"}} />
      <Badge
       color='success'
        sx={{
          '.MuiBadge-colorSuccess':{
            backgroundColor:"#17c653"
          },
          position: 'absolute',
          top: 0,
          left: '30%',
          animation: 'ripple 1.2s infinite ease-in-out',
          transform: 'translateX(-50%)', 
          '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(.8)',
      opacity: 0,
    }
        }}}
        variant="dot"
        invisible={false}
      /></Box>
    ):(<Box sx={{ position: 'relative', textAlign: 'center',paddingRight:"20px" }}><AccessTimeIcon sx={{ color: 'grey', fontSize: '30px', paddingTop:"5px", paddingBottom:"3px"}} /></Box>)}
    
          
          <Box sx={{ flexGrow: 0 }}>
              
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, }}>
            <Avatar name={name} size='40'  round="20px" textSizeRatio={2}/>
            </IconButton>

            <Menu
              sx={{ mt: '45px', width:"100vh", paddingTop:"0px"}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
            <Box   sx={{width:"100%"}}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px'  }}>
              <Avatar name={name} size='50'   round="5px" textSizeRatio={2}/>
              <div style={{marginLeft:"10px"}}>
              <Typography variant='body1'>{name}</Typography>
              <Typography variant='body2' sx={{color:'grey'}}>{email}</Typography>
              </div>
            </Box>
          <Divider />
           
              {renderMenuItems(Settings, handleLogout)}
            </Box>
            
            </Menu>
            <Dialog className='popup-pwd'  open={open} onClose={handleClose}  >
        
               
                
        <DialogContent    sx={{overflow:"hidden"}}>
              <Box sx={{ width:'100%', }} >
              <Box sx={{display:"flex", flexDirection: 'row',padding:"0px",
   
    justifyContent: 'space-between',
    alignItems: 'flex-start'}}>
    <Box pr={8}>
    <Typography variant='h6' className='pwdchange'> Change Password</Typography>
    <Typography variant='body1' className='pwdcontent'  sx={{color:"#000000"}}> Use the form below to change your password.</Typography>
    </Box>
<IconButton  onClick={handleClose} sx={{paddingTop:"0px"}}>
                <CancelOutlinedIcon sx={{color:"grey"}}/>
              </IconButton>
              </Box>
              <InputLabel>
  New Password{' '}
  <span style={{ color: 'red', fontSize: '22px' }}>*</span>
  <Tooltip
    title="Password must contain atleast one uppercase, one lowercase, one symbol and one number."
    arrow
    placement="top"
    classes={{ tooltip: 'custom-tooltip' }}
  >
    <IconButton size="small" color="#e1e3ea78">
      <InfoIcon />
    </IconButton>
  </Tooltip>
</InputLabel>
<TextField
  type={newPasswordVisibility ? 'text' : 'password'}
  placeholder="New Password"
  value={password}
  style={inputStyles}
  onChange={(e) => setPassword(e.target.value)}
  autoComplete="new-password"
  fullWidth
  InputProps={{
    endAdornment: (
      <IconButton
        onClick={() => setNewPasswordVisibility(!newPasswordVisibility)}
        style={inputStyles.visibilityIcon}
      >
        {newPasswordVisibility ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    ),
  }}
/>
{error.password && <div style={{color: '#F8285A',padding:"5px 10px", fontSize:".8rem"}}>{error.password}</div>} 
<InputLabel>
  Re-Type Password{' '}
  <span style={{ color: 'red', fontSize: '22px' }}>*</span>
  <Tooltip
    title="Password must contain atleast one uppercase, one lowercase, one symbol and one number."
    arrow
    placement="top"
    classes={{ tooltip: 'custom-tooltip' }}
  >
    <IconButton size="small" color="#e1e3ea78">
      <InfoIcon />
    </IconButton>
  </Tooltip>
</InputLabel>
<TextField
  type={repeatPasswordVisibility ? 'text' : 'password'}
  placeholder="Re-Type Password"
  value={repeatPassword}
  style={inputStyles}
  onChange={(e) => setRepeatPassword(e.target.value)}
  autoComplete="new-password"
  fullWidth
  InputProps={{
    endAdornment: (
      <IconButton
        onClick={() => setRepeatPasswordVisibility(!repeatPasswordVisibility)}
        style={inputStyles.visibilityIcon}
      >
        {repeatPasswordVisibility ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    ),
  }}
/>
{error.repeatpassword && <div style={{color: '#F8285A',padding:"5px 10px", fontSize:".8rem"}}>{error.repeatpassword}</div>} 

              </Box>
        
        </DialogContent>
        <DialogActions className="button-wrapper">
          <Box >
          <Button variant='contained' disableElevation disableFocusRipple disableRipple disableTouchRipple 
          sx={{backgroundColor:"#1B84FF",textTransform:"capitalize", '&:hover':{backgroundColor:"#1B84FF"},}} onClick={handleSetPassword}>
          Set Password <TaskAltIcon sx={{fontSize:"18px", color:"white"}}/>
          </Button>
          </Box>
         
        </DialogActions>
         
                
      </Dialog>
     
          </Box>
        </Toolbar>
      
      </Container>
      <Drawer
  anchor="right"
  open={drawerOpen}
  onClose={handleDrawerClose}
  sx={{
    width: '30%',
  
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: '30%',
      boxSizing: 'border-box',
    },
  }}
>
  <div style={{padding:"20px 40px"}}>
  <Box sx={{display:"flex",justifyContent:"flex-end"}}>
  <IconButton onClick={handleDrawerClose}>
      <CloseIcon sx={{color:"#000000", fontSize:"28px"}} />
    </IconButton>
  </Box>
   <Box>
    <Typography variant='h6' sx={{color:"#071437",fontWeight:"600"}}>Activities</Typography>
    <Typography variant="body1" sx={{color:"#99A1B7"}}>Last Three Days Records</Typography>
    <VerticalStepperWithPagination/>
   </Box>

  </div>
</Drawer>
    </AppBar>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Navbar);
