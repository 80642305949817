import React, { useEffect, useState } from 'react';
import { Container, styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import {  FormControl, MenuItem, Pagination, Paper, Select, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableProjectView from './TableProjectView';
import { EditData, getTimerCount } from '../api/api';
import { useAuth } from '../context/authContext';
import RotatingTimerIcon from './RotatingTimer';
import { usePieChartData } from '../context/pieChartContext';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import "./style.css"
import Tooltip from '@mui/material/Tooltip';

const commonStyles = {
  borderRadius: '0.625rem',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Inter, Helvetica, sans-serif',
};

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-root': {
    border: 'none',
    ...commonStyles,
  },
  '.MuiDataGrid-virtualScroller':{
     
    overflowX: 'auto', 
    '&::-webkit-scrollbar': {
      display: 'none',
      padding: '20px',
    },
  },
  padding: '10px 20px',
});

function TableDataGrid({ workItems }) {

  const {token,isAdmin}= useAuth()
  const [gridData, setGridData] = useState([]);
  const {updatePieChart}=usePieChartData();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const paginatedWorkItems = workItems.slice((currentPage - 1) * pageSize, currentPage * pageSize);
let bgcolor; 
let icon;
let bgcoloricon;
const setcolor=(params)=>{
  switch(params){
    case 'Task': {
      bgcoloricon = '#FAF3D3';
      bgcolor = 'rgb(164, 136, 10)';
      icon = <AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px", marginRight:"8px"}}/>;
      break;
    }
    case 'Bug': {
      bgcoloricon = '#FFDFDD';
      bgcolor = 'rgb(204, 41, 61)'; // Change this to the color you want for 'Bug'
      icon = <BugReportOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Bug'
      break;
    }
    case 'Feature': {
      bgcoloricon = '#F5F0FF';
      bgcolor = 'rgb(180, 0, 158)'; // Change this to the color you want for 'Feature'
      icon = <EmojiEventsIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Feature'
      break;
    }
    case 'User Story': {
      bgcoloricon = '#E5F9FF';
      bgcolor = 'rgb(0, 152, 199)'; // Change this to the color you want for 'UserStory'
      icon = <MenuBookOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'UserStory'
      break;
    }
    case 'Epic': {
      bgcoloricon = '#fadfd3';
      bgcolor = 'rgb(224, 108, 0)'; // Change this to the color you want for 'Epic'
      icon = <StarsOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Epic'
      break;
    }
    case 'Issue': {
      bgcoloricon = '#f8e2f6';
      bgcolor = '#b4009e'; // Change this to the color you want for 'Epic'
      icon = <ErrorOutlineOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Epic'
      break;
    }
    default:{
      bgcoloricon = '#000000';
      bgcolor="#000000";
      icon=<AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>
      // Default case
    }
}

}
  useEffect(() => {
   
    const fetchData = async () => {
      try {
        const timerCount = await getTimerCount(token);
       
        setGridData(workItems)
        // Check if incomplete_timers_data is defined before accessing its properties
        if (timerCount?.incomplete_timers_data) {
          const workItemValues = timerCount.incomplete_timers_data.map((timer) => timer.workitem);
       
          localStorage.setItem('timerStats', workItemValues);
        } else {
          console.error('Incomplete timers data is undefined or has no length.');
        }
      } catch (error) {
        console.error('Error fetching timer count:', error);
      }
    };

    fetchData();

 
  }, []);
  function truncate(str, num) {
    if (str.length <= num) {
      return str;
    }
    return `${str.slice(0, num)}...`;
  } 
  const columns =isAdmin?
  [
    { field: 'Work_ID', headerName: 'Title', flex: 2, renderCell: (params) => {
      setcolor(params.row.System_WorkItemType)
    
      return(
        <Tooltip title={params.row.System_Title} placement="top" arrow>
        <div className='iconbox-title'>
          <div className='iconinner' style={{color:bgcolor,backgroundColor:bgcoloricon}}>{icon}</div><div>
         
          <Typography variant='h4' className='projectname-items margintop'><b>{truncate(params.row.System_Title,isAdmin?20:30)}</b></Typography>
        <Typography variant='h4' className='projectname-items'>Work ID <b>{params.row.Work_id}</b></Typography>
        
        </div></div>
        </Tooltip>
      )
    }
      },
      { field: 'System_Project_name', headerName: 'Project Name', flex: 1, renderCell:(params)=>{
        setcolor(params.value)
        return(
          <div className='projectheading'>{params.value}</div>
        )
      }},
      { field: 'System_WorkItemType', headerName: 'Work Type', flex: 1, renderCell:(params)=>{
        setcolor(params.value)
        return(
          <span className='id-task' style={{color:bgcolor}}>{icon} {params.value}</span>
        )
      }} ,
      {
        field: 'System_State',
        headerName: 'State',
        flex: 1,
        renderCell: (params) => {
          // Define colors for each state
          
          const stateColors = {
            'Active': '#17c653',
            'New': '#1B84FF',
            'Resolved': '#FFAD0F',
            'Closed': '#F1416C',
            'Pause': '#6951aa',
            'Removed': '#000',
            'Hold':"red"
          };
          const bgColor={
            'Active':'#dfffea',
            'New':'#e9f3ff',
            'Resolved':'',
            'Closed':''
          }
          // Render a dropdown
          return (
            <div className='id-task2'>
            <div className='taskcolor' style={{background: stateColors[params.value] }}> </div>
            <FormControl sx={{ m: 1, minWidth: 120 ,borderColor:"transparent"}}>
             
            <Select
            sx={{width:"100%",'.MuiOutlinedInput-notchedOutline':{border:0},  color:[params.value] || "Grey",appearance:"none"}}
            
            value={params.value}
            onChange={async (e) => {


const updatedWorkItems = workItems.map((item) =>
  item.Work_ID === params.row.Work_id ? { ...item, System_State: e.target.value } : item
);

// Find the edited item in the workItems array
const editedItem = updatedWorkItems.find((item) => item.Work_ID === params.row.Work_id);

const requestBody = editedItem.System_State;

if(localStorage.getItem('timerstats').includes(params.row.Work_id)){
        return(
          toast.error('Timer is ON,stop timer to change state!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      })
        )
      }
try {
  // Call the editData API with the requestBody
  
  await EditData(token, params.row.Work_id, requestBody);

  toast.success(`State is changed successfully to ${requestBody}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
await updatePieChart()
 
 

} catch (error) {
  console.error('Error updating data:', error);
  // Handle errors or display an error message
}
}}
          
          >
            
           
            <MenuItem value={'Active'}>Active</MenuItem>
            <MenuItem value={'New'}>New</MenuItem>
            <MenuItem value={'Closed'}>Closed</MenuItem>
            <MenuItem value={'Resolved'}>Resolved</MenuItem>
            <MenuItem value={'Pause'}>Pause</MenuItem>
            <MenuItem value={'Hold'}>Hold</MenuItem>
            <MenuItem value={'Removed'}>Removed</MenuItem>
          </Select>
             
          </FormControl>
             </div>
          );
        },
      },
      { field: 'System_AssignedTo', headerName: 'Assigned To', flex: 1.5, renderCell:(params)=>{
       
        return(
          <span className='dateassign'> {params.row.System_AssignedTo}</span>
        )
      }},
    
      { field: 'System_CreatedDate', headerName: 'Assigned Date', flex: 1.5, renderCell:(params)=>{
        setcolor(params.value)
        return(
          <span className='dateassign'> {params.value}</span>
        )
      }},
    
     
      {
        field: 'button',
        headerName: 'Action',
        
        renderCell: (params) => (<div className='clockrote'>
              <div  style={{
  background: params.row.System_State === "Active" ? 'transparent' : '#DBDFE969',borderRadius:"8px"
}}> <RotatingTimerIcon id={params.row.Work_id} selectedState={params.row.System_State} project={params.row.System_Project_name} state={params.row.System_State}/></div>
              <div className='pageloading'> <TableProjectView id={params.row.Work_id} /></div>
        </div>
         ),
      },
     
     
    ]
    :
  [
    { field: 'Work_ID', headerName: 'Title', flex: 2, renderCell: (params) => {
      setcolor(params.row.System_WorkItemType)
      return(
        <Tooltip title={params.row.System_Title} placement="top" arrow>
        <div className='iconbox-title'>
          <div className='iconinner' style={{color:bgcolor,backgroundColor:bgcoloricon}}>{icon}</div><div>
         
          <Typography variant='h4' className='projectname-items margintop'><b>{truncate(params.row.System_Title,30)}</b></Typography>
        <Typography variant='h4' className='projectname-items'>Work ID <b>{params.row.Work_id}</b></Typography>
        
        </div></div>
        </Tooltip>
      )
    }
      },
      { field: 'System_Project_name', headerName: 'Project Name', flex: 1, renderCell:(params)=>{
        setcolor(params.value)
        return(
          <div className='projectheading'>{params.value}</div>
        )
      }},
      { field: 'System_WorkItemType', headerName: 'Work Type', flex: 1, renderCell:(params)=>{
        setcolor(params.value)
        return(
          <span className='id-task' style={{color:bgcolor}}>{icon} {params.value}</span>
        )
      }} ,
      {
        field: 'System_State',
        headerName: 'State',
        flex: 1,
        renderCell: (params) => {
          // Define colors for each state
          
          const stateColors = {
            'Active': '#17c653',
            'New': '#1B84FF',
            'Resolved': '#FFAD0F',
            'Closed': '#F1416C',
            'Pause': '#6951aa',
            'Removed': '#000',
            'Hold':"red"
          };
          const bgColor={
            'Active':'#dfffea',
            'New':'#e9f3ff',
            'Resolved':'',
            'Closed':''
          }
          // Render a dropdown
          return (
            <div className='id-task2'>
            <div className='taskcolor' style={{background: stateColors[params.value] }}> </div>
            <FormControl sx={{ m: 1, minWidth: 120 ,borderColor:"transparent"}}>
             
            <Select
            sx={{width:"100%",'.MuiOutlinedInput-notchedOutline':{border:0},  color:[params.value] || "Grey",appearance:"none"}}
            
            value={params.value}
            onChange={async (e) => {


const updatedWorkItems = workItems.map((item) =>
  item.Work_id === params.row.Work_id ? { ...item, System_State: e.target.value } : item
);

// Find the edited item in the workItems array
const editedItem = updatedWorkItems.find((item) => item.Work_id === params.row.Work_id);

const requestBody = editedItem.System_State;

if(localStorage.getItem('timerstats').includes(params.row.Work_id)){
        return(
          toast.error('Timer is ON,stop timer to change state!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      })
        )
      }
try {
  // Call the editData API with the requestBody
  
  await EditData(token, params.row.Work_id, requestBody);

  toast.success(`State is changed successfully to ${requestBody}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
await updatePieChart()
 

} catch (error) {
  console.error('Error updating data:', error);
  // Handle errors or display an error message
}
}}
          
          >
            
           
            <MenuItem value={'Active'}>Active</MenuItem>
            <MenuItem value={'New'}>New</MenuItem>
            <MenuItem value={'Closed'}>Closed</MenuItem>
            <MenuItem value={'Resolved'}>Resolved</MenuItem>
            <MenuItem value={'Pause'}>Pause</MenuItem>
            <MenuItem value={'Hold'}>Hold</MenuItem>
            <MenuItem value={'Removed'}>Removed</MenuItem>
          </Select>
             
          </FormControl>
             </div>
          );
        },
      },
      { field: 'System_CreatedDate', headerName: 'Assigned Date', flex: 1.5, renderCell:(params)=>{
        setcolor(params.value)
        return(
          <span className='dateassign'> {params.value}</span>
        )
      }},
    
     
      {
        field: 'button',
        headerName: 'Action',
        
        renderCell: (params) => (<div className='clockrote'>
              <div  style={{
  background: params.row.System_State === "Active" ? 'transparent' : '#DBDFE969',borderRadius:"8px"
}}> <RotatingTimerIcon id={params.row.Work_id} selectedState={params.row.System_State} project={params.row.System_Project_name} state={params.row.System_State}/></div>
              <div className='pageloading'> <TableProjectView id={params.row.Work_id} /></div>
        </div>
         ),
      },
     
     
    ]
  const rowHeight=80;
      const rowSpace='1px';
  return (
    <Container fixed sx={{ alignItems: 'center',  maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' } }}>
      <Paper sx={{ borderRadius: '0.625rem', boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)', marginTop: '10px', backgroundColor: 'white' }}>
      
      <Typography sx={{ ml: 2, flex: 1, color: '#071437', fontWeight: '600' }} variant="h5" component="div">Active Timer</Typography>
      <div style={{  width: '100%'}}>
              <StyledDataGrid
                className='workitems'
                sx={{ border: "none", width:"100%", height:'100%', padding:'10px 0px', overflowY:'auto !important',
               
                }}
                columns={columns}
                rows={paginatedWorkItems}  // Use paginatedWorkItems instead of workItems
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20, 30]}
                paginationMode="server" // Set paginationMode to "server" for server-side pagination
                onPageChange={(newPage) => handleChangePage(null, newPage)}
                hideFooter
                getRowHeight={() => rowHeight}
                getRowSpacing={()=> rowSpace}
                sorting={[{ field: 'Work_ID', order: 'desc' }]} 
              />         
              </div>
              <div style={{display:"flex", justifyContent:"space-between",marginTop:"20px", marginBottom:"10px",     borderTop: "1px solid #f3f1f1",paddingTop: "30px" }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent:"center" }}>
                           <Typography sx={{ margin: '0 10px' }}>Rows per page:</Typography>
                             <select style={{ border: 'none',outline: 'none',backgroundColor:"#1B84FF", color:"#fff", padding:"5px", borderRadius:"5px"}}
                                          value={pageSize} onChange={(e) => { setPageSize(Number(e.target.value)); setCurrentPage(1); }}>
                                          {[5, 10, 20, 30, 40, 50].map((pageSizeOption) => (
                                            <option key={pageSizeOption} value={pageSizeOption}>
                                              {pageSizeOption}
                                            </option>
                                          ))}
                                        </select>
                                      
                                      </div>
                          <div>
                          <Pagination
                              shape="rounded"
                              count={Math.ceil(workItems.length / pageSize)}
                              page={currentPage}
                              onChange={handleChangePage}
                              sx={{
                        '.Mui-selected': {
                          backgroundColor: '#1B84FF !important',
                          color:"white"
                        },
                      }}
                            />
                          </div>
              </div>
      </Paper>
    </Container>
  );
}

export default TableDataGrid;