
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTimerStats } from '../context/timerStatsContext';
const BASE_URL = process.env.REACT_APP_INTERNAL_SERVER_API;



export const getUsers = async (token) => {
    try {
      const response = await axios.get(`${BASE_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },withCredentials: true,
      });
      return response.data
    } catch (error) {
      console.error('Error fetching all work items:', error);
      throw error;
    }
  };

  
export const getUsersAttendance = async (token,year,month) => {
  try {
    const response = await axios.get(`${BASE_URL}/attendance-report/${year}/${month}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    return response.data
  } catch (error) {
    console.error('Error fetching all work items:', error);
    throw error;
  }
};