import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Papa from 'papaparse';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import { useAuth } from '../context/authContext';
import { getRevokeSession, getUserSessions } from '../api/api';
import {  useNavigate } from 'react-router-dom';
import './style.css';
import detailpageimg from "../public/image 1.png"
import microsoftLogo from '../public/microsoft (1) 1.png';
import logo from '../public/favicon.png'
import { toast } from 'react-toastify';

function WebSessionsContent() {
    const navigate = useNavigate();
    const [userSessionData,setUserSessionData]=useState([])
    const {token}=useAuth();

    const fetchData=async()=>{
        const sessiondata= await getUserSessions(token)
        setUserSessionData(sessiondata.user_sessions.reverse())
    }
useEffect(()=>{
fetchData();
},[token])

    const handleRevoke=(id)=>{
      const revokesession=  getRevokeSession(token,id);
      toast.success('Session revoked successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        const updatedData = userSessionData.map((item) => {
          if (item.id === id) {
            return { ...item, animating: true };
          }
          return item;
        });
        setUserSessionData(updatedData);
    
        setTimeout(() => {
          setUserSessionData((prevData) => prevData.filter((item) => item.id !== id));
        }, 500);
    }
    const handleNavigation = () => {
        navigate('/dashboard/projects');
      };

      function truncate(str, num) {
        if (str.length <= num) {
          return str;
        }
        return `${str.slice(0, num)}...`;
      } 
    return (
        <Container fixed sx={{ alignItems: 'center', marginTop: '20px', maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' } }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box >
              <Typography variant="h6" sx={{ color: '#071437', fontWeight: '600' }}>
              Web sessions
              </Typography>
              <Typography variant="body2" sx={{ color: '#99A1B7', fontWeight: '600' }}>
                Home - Web sessions
              </Typography>
            </Box>
    
            <Button
              variant="text"
              sx={{ textTransform: 'capitalize', color: '#252F4A', backgroundColor: '#F1F1F4', fontWeight: '600' }}
              onClick={handleNavigation}
            >
              My Projects
            </Button>
          </Box>
          <Paper sx={{ padding: '10px 20px', marginTop: '20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5%' }}>
            <Box>
            <Box>
                <Typography variant="h6" sx={{ color: '#252F4A', fontWeight: '600' }}>
                Web sessions
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: '100', color: '#000000' }}>
                This is a list of devices that have logged into your account. Revoke any sessions that you do not recognize.                </Typography>
              </Box>
              <Box mt={4} >
                <Typography variant='h4' sx={{fontWeight:"600", color:"#000000"}}><span style={{color:"#FD4131", fontWeight:"900", fontSize:"48px"}}>{userSessionData.length}</span> Logged in Sessions</Typography>
            
              </Box>
            </Box>
             
              <Box  sx={{ paddingRight: '10%' }}>
                <img src={detailpageimg} alt="pictur" />
              </Box>
            </Box>
          <Box sx={{ padding: '10px 5%'}}>
          <Button
              variant="contained"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              sx={{
                paddingLeft:"25px",
                paddingRight:"25px",
                color: '#1B84FF',
                marginRight: '10px',
                backgroundColor: '#E9F3FF',
                fontWeight: '600',
                '&:hover': { backgroundColor: '#E9F3FF' },
              }}
            >
              SESSIONS
            </Button>
          </Box>
           <Box mt={8}>
           {userSessionData.length === 1?(
            <Typography variant='h5' sx={{color:"#F1416C",textAlign:"center", border:"2px dashed #F1416C69", backgroundColor:"#fd413138", margin:"5px 30%",borderRadius:"5px"}}>No active session other than current session.</Typography>
            ):(<>

           
              { userSessionData.map((item)=>{
      const containsDay = item.last_used_human_readable.toLowerCase().includes('week') || item.last_used_human_readable.toLowerCase().includes('month');
      const itemClass = item.animating ? "slide-left" : "";
            return(
              <Box key={item.id} className={itemClass} mt={2} mb={2} sx={{backgroundColor:"#FFEEF3", display:"flex",padding:"30px 5%",justifyContent:"space-between", alignItems:"center", }}>
            <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"40%"}}>
            <Box>
            <DesktopWindowsOutlinedIcon sx={{fontSize:"100px"}}/>
            </Box>
            <Box>
            <Typography mb={1}  variant='body1'>{item.ip !==null?item.ip:'No IP Address record'}</Typography>
            <Button  variant='contained' sx={{
    color:"white", 
    textAlign: 'center',
    textTransform:"uppercase",
    backgroundColor:"#282626", 
    margin: '0 auto',
    '&:hover':{
        backgroundColor:"#282626"
    },
    '&:focus':{
        backgroundColor:"#282626"
    }
}}

  disableElevation 
  disableFocusRipple 
  disableRipple 
  disableTouchRipple
>
    <img src={item.name==='microsoft-token'?microsoftLogo:logo} style={{marginLeft:"2px", marginRight:"15px",width:"25px"}} alt="microsoft"/> {item.name}
</Button>
<Typography variant='h6' mt={1} sx={{fontWeight:"600",fontSize:"18px"}}>Last used: <span style={{color:containsDay?"#FD4131":"#17c653", fontWeight:"700"}}>{item.last_used_human_readable}</span></Typography>
<Typography variant='h6' mt={1} sx={{fontWeight:"600",fontSize:"18px"}}>DEVICE <span style={{ fontWeight:"400"}}> &nbsp;&nbsp; {item.device === null?'No device record':truncate(item.device,22)}</span></Typography>
            </Box>
               <Box>

               </Box>
            </Box>
              <Box>
                <Button variant='contained' sx={{backgroundColor:"#FD4131",'&:hover': { backgroundColor: '#FD4131' },display:"flex", justifyContent:"center", alignItems:"center"}} 
                disableElevation disableFocusRipple disableTouchRipple disableRipple onClick={() => handleRevoke(item.id)}
>
                    revoke <DeleteOutlineOutlinedIcon sx={{color:"white", marginLeft:"10px"}}/>
                </Button>
              </Box>

            </Box>
            )
           })

           }
           </>
            )}
          
           
           </Box>
          </Paper>
        </Container>
      );
}

export default WebSessionsContent