import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import Papa from 'papaparse';
import logo from "../public/logo.png"
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Menu,
  Backdrop,
  CircularProgress,
  Autocomplete,
  TextField,
  Chip,
} from '@mui/material';
import { useAuth } from '../context/authContext';
import { getProjectMonthlyData } from '../api/api';
import { useTable, useExpanded } from 'react-table';
import TableProjectView from './TableProjectView';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Navigate, useNavigate } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import './style.css';
import detailpageimg from "../public/detailpageimg.png"
import { IconFileTypePdf } from '@tabler/icons-react';
import { IconFileTypeCsv } from '@tabler/icons-react';


function ReportContent() {
  const currentDate = new Date();
  const {isAdmin}=useAuth()
  const startingDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const [start, setStart] = useState(startingDateOfMonth.toISOString().split('T')[0]);
  const [end, setEnd] = useState(currentDate.toISOString().split('T')[0]);
  const [selectedExportType, setSelectedExportType] = useState('csv');
  const { token } = useAuth();
  const [data, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [fetch,setFetch]=useState(null)
  const [type,setType]=useState('')
  const [projectname,setProjectName]=useState([])

  const getUniqueProjects = () => {
    if (!data.project_wise_reports) {
      return [];
    }
  
    const allProjectTypes = new Set();
  
    // Iterate through project_wise_reports to collect unique project types
    Object.keys(data.project_wise_reports).forEach(reports => {
     
          allProjectTypes.add(reports);
     
    });
  
    return Array.from(allProjectTypes);
  };
  const projectList= getUniqueProjects();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  
  };
  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorEl1(null);
  };
  



  const fetchData = async () => {
    setFetch(true)
    handleCloseMenu()
    try {
      const reportData = await getProjectMonthlyData(token, start, end);
      setRows(reportData);
      getUniqueProjects();
      setProjectName(projectList);
      setIsLoading(false);
      setFetch(false)
    } catch (error) {
      setFetch(false)
      setIsLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStart(newStartDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEnd(newEndDate);
  };

  const csvLink = useRef();
  const handleExportCSV = () => {
    const workbook = XLSX.utils.book_new();
  
    // biome-ignore lint/complexity/noForEach: <explanation>
projectname.forEach((project) => {
      const filteredReports = data.overall_project_durations.filter(
        (report) => report.project === project
      );
  
      const csvData = projectname.length === 0 ? data.overall_project_durations : filteredReports || [];
  
      // Extract subcomponent headers
      const subHeaders = ['Project Name', 'Work Item', 'Task Type', 'Server', 'Duration'];
  
      // Create CSV data including main and subcomponent data
      const csvRows = [];
  
      // Add the main CSV header row
      csvRows.push(['Project', 'Work Item Count', 'Duration']);
  
      // biome-ignore lint/complexity/noForEach: <explanation>
csvData.forEach((row) => {
        if (row.project) {
          // Determine the appropriate work item count
          const projectWiseReports = Array.isArray(data.project_wise_reports[row.project])
            ? data.project_wise_reports[row.project]
            : [];
  
          const filteredReport = projectWiseReports.filter(
            (report) => report.project_type === type
          );
          const workItemCount = type === '' ? projectWiseReports.length : filteredReport.length;
  
          // Main table data row
          const mainDataRow = [row.project, workItemCount, row.duration];
  
          // Push main data row
          csvRows.push(mainDataRow);
  
          // Subcomponent data rows
          const subDataRows = (type === '' ? projectWiseReports : filteredReport || []).map((subItem) => [
            row.project,
            `${subItem.workitem} ${row.project}`,
            subItem.project_type,
            subItem.server,
            subItem.duration,
          ]);
  
          // Flatten subcomponent header row and data rows into main array
          csvRows.push(subHeaders);
          csvRows.push(...subDataRows.map((subRow) => subRow), []);
        }
      });
  
      // Create a worksheet for each project
      const ws = XLSX.utils.aoa_to_sheet(csvRows);
  
      // Assign worksheet to the project name
      XLSX.utils.book_append_sheet(workbook, ws, project);
    });
  
    // Save the workbook with a generic name as it now contains multiple projects
    XLSX.writeFile(workbook, `Projects_Report_${start}-${end}.xlsx`);
  };
  
  
  
  const handleExportPDF = () => {
    const logoBase64 = logo;
    const doc = new jsPDF();
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const text1 = 12; // Adjusted for consistency
    const text2 = 35; // Position for the initial text
    const footerStr = "*This is a system generated document or report";
    const textSize = 8; // Size of the text
  
    projectname.forEach((name, index) => {
      if (index > 0) {
        doc.addPage(); // Add a new page for each project after the first
      }
  
      const filteredReports = data.overall_project_durations.filter(
        (report) => report.project === name
      );
  
      const pdfWidth = doc.internal.pageSize.getWidth();
      const logoWidth = 30; // Adjust the logo width if needed
      const logoX = (pdfWidth - logoWidth) / 2;
      doc.addImage(logoBase64, 'JPEG', logoX, 10, logoWidth, 20);
  
      doc.setFontSize(10);
      doc.text(`Report for ${name} from ${start} to ${end}`, text1, text2);
      doc.setFontSize(14);
      doc.text(`Project : ${name} `, text1 + 100, text2);
      const mainHeaders = [['Project', 'Work Item Count', 'Duration']];
      const subHeaders = [['Project Name', 'Work Item', 'Task Type', 'Server', 'Duration']];
  
      const mainTableConfig = {
        startY: 40,
        head: mainHeaders,
      };
  
      const projectWiseReports = Array.isArray(data.project_wise_reports[name])
        ? data.project_wise_reports[name]
        : [];
  
      const filteredReport = projectWiseReports.filter(
        (report) => report.project_type === type
      );
  
      const subDataRows = (type === '' ? projectWiseReports : filteredReport || [])
        .map(subItem => [
          name,
          subItem ? `#${subItem.workitem} ${subItem.title}` : '',
          subItem ? subItem.project_type : '',
          subItem ? subItem.server : '',
          subItem ? subItem.duration : '',
        ]);
  
      // Calculate the length of subDataRows for the "Work Item Count" column
      const workItemCount = subDataRows.length;
  
      mainTableConfig.body = [[name, workItemCount, filteredReports.map(item => item.duration).join(', ')]];
      doc.autoTable(mainTableConfig);
  
      if (subDataRows.length > 0) {
        const subTableConfig = {
          startY: doc.autoTable.previous.finalY + 10,
          head: subHeaders,
          body: subDataRows,
        };
  
        doc.autoTable(subTableConfig);
      }
  
      // Footer at the bottom of each page
      const textX = 10; // Adjusted for consistency
      const textY = pageHeight - 10; // Position 10 units above the bottom of the page
      doc.setFontSize(textSize);
      doc.text(footerStr, textX, textY);
    });
  
    // Save the PDF with a generic name as it now contains multiple projects
    doc.save(`Projects_Report_${start}-${end}.pdf`);
  };
  
  

  const getUniqueProjectTypes = () => {
    if (!data.project_wise_reports) {
      return [];
    }
  
    const allProjectTypes = new Set();
  
    // Iterate through project_wise_reports to collect unique project types
    Object.values(data.project_wise_reports).forEach(reports => {
      reports.forEach(item => {
        if (item.project_type) {
          allProjectTypes.add(item.project_type);
        }
      });
    });
  
    return Array.from(allProjectTypes);
  };
  

  const [exportInProgress, setExportInProgress] = useState(false);
  const getFilteredProjectTypes = () => {
    // Get unique project types based on your data structure
    const allProjectTypes = data.overall_project_durations
      ? data.overall_project_durations.map((item) => item.project_type)
      : [];
  
    // Filter the project types based on the selected type value
    const filteredProjectTypes = Array.from(new Set(allProjectTypes));
  
    return filteredProjectTypes;
  };
  
  const handleExport = () => {
    if (selectedExportType === 'csv') {
      handleExportCSV();
    } else if (selectedExportType === 'pdf') {
      handleExportPDF();
    }
    // Add more export types as needed
  };

  useEffect(() => {
    if (exportInProgress) {
      handleExport();
      setExportInProgress(false);
    }
  }, [exportInProgress]);

  const handleMenuClick = (exportType) => {
    setSelectedExportType(exportType);
    handleClose();
    setExportInProgress(true);
  };

  useEffect(() => {
    fetchData();
  }, [start, end]);

  const handleNavigation = () => {
    navigate('/dashboard/projects');
  };


  const MyTable = ({ data }) => {
    const filteredReports = data.overall_project_durations.filter(
      (report) => projectname.includes(report.project)
    );
  
   
  const columns = React.useMemo(
    () => [
     
      {
        Header: 'Project',
        accessor: 'project',
      },
  
      {
        Header: 'Server',
        accessor: 'server',
      },
     
      {
        Header: 'Duration',
        accessor: 'duration',
      
      },  
        {
        Header: 'Work Item',
        accessor: 'workitem',
        Cell: ({row})=>{
          const project = row.original.project;
          const count = project && data.project_wise_reports[project] ? data.project_wise_reports[project].length : 0;
          return( <div>
              {count}
            </div>)
        }
      },
     
    ],
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
    toggleRowExpanded,
  } = useTable(
    {
      columns,
     
      data: React.useMemo(() => (projectname.length === 0 ? data.overall_project_durations : filteredReports || []), [data]),

    },
    useExpanded
  );

  if (rows.length === 0) {
    return <div style={{textAlign:"center", color:"grey"}}><h4>No Work Done</h4></div>;
  }


  return (
    <table {...getTableProps()} style={{ width: '100%', textAlign:"left" }}>
    <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()} >
       <th style= {{padding:'10px', backgroundColor:'#e9e9e9'}}>#</th>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()} style= {{padding:'10px', backgroundColor:'#e9e9e9'}}>{column.render('Header')}</th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row);
        return (
          <React.Fragment key={row.id}>
            <tr {...row.getRowProps()}>
          
            <td style={{width:"10px"}}>
                {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
<button style={{border:"none", color:"#1B84FF", backgroundColor:'#E9F3FF', borderRadius:'50%', height:'40px',width:'40px'}} onClick={() => toggleRowExpanded(row.id)}>
                  {row.isExpanded ?<RemoveIcon/> :<AddIcon/> }
                </button>
              </td>

              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
              {/* Button for expanding/collapsing subcomponent */}
             
            </tr>
            {row.isExpanded ? (
              <tr>
                <td colSpan='5' >
                  {/* Subcomponent Table */}
                  <SubcomponentTable
                    reports={data.project_wise_reports[row.original.project]}
                  />
                </td>
              </tr>
            ) : null}
          </React.Fragment>
        );
      })}
    </tbody>
  </table>
  );
};
const projectTypeOptions = getUniqueProjectTypes();


const SubcomponentTable = ({ reports }) => {
  const filteredReports = reports.filter(
    (report) => report.project_type === type
  );


  const columns = React.useMemo(
    () => [
      {
        Header: 'Work Item',
        accessor: 'combined', // New accessor for combined data
        Cell: ({ row }) => (
          <div >
           #{row?.original?.workitem || 'No Work Item'} {row?.original?.title || 'No Title'}

          </div>
        ),
      },
      { 
        Header: 'Server',
        accessor: 'server',
      },
      { 
        Header: 'Task Type',
        accessor: 'project_type',
        // Cell: ({ row }) => (
        //   <div >
        //      {row.original.project_type}
        //   </div>
        // ),
      },
      {
        Header: 'Duration',
        accessor: 'duration',
       
      },
     
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div>
         {row?.original?.workitem ? (
  <TableProjectView id={row.original.workitem} />
) : (
  <div>No work item</div>
)}
        </div>
      ),
    },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: type!==''?filteredReports: reports || [] });

  return (
    <table {...getTableProps()} style={{ width: '100%', marginLeft: '20px' }}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style= {{padding:'10px', backgroundColor:'#f6f6f6'}}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} >
              {row.cells.map(cell => (
                <td {...cell.getCellProps()} style={{background: '#f7f8fa'}}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="loader1" style={{ backgroundColor: '#17C653', width: '40px' }} />
      </Box>
    );
  }
  const colors = ['#1B84FF', '#17c653', '#F8285A','#F6C000'];

  // Function to get a random color from the predefined set
  const getRandomColor = () => {
    const index = Math.floor(Math.random() * colors.length);
    return colors[index];
  };

  return (
    <Container fixed sx={{ alignItems: 'center', marginTop: '20px', maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' } }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6" sx={{ color: '#071437', fontWeight: '600' }}>
            User Report
          </Typography>
          <Typography variant="body2" sx={{ color: '#99A1B7', fontWeight: '600' }}>
            Home - Report
          </Typography>
        </Box>

        <Button
          variant="text"
          sx={{ textTransform: 'capitalize', color: '#252F4A', backgroundColor: '#F1F1F4', fontWeight: '600' }}
          onClick={handleNavigation}
        >
          My Projects
        </Button>
      </Box>
      <Paper sx={{ padding: '10px 20px', marginTop: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 5%' }}>
          <Box>
            <Typography variant="h6" sx={{ color: '#252F4A', fontWeight: '600' }}>
              Detail Report
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: '100', color: '#000000' }}>
              Retrieve a project overview incorporating time tracking.
            </Typography>
          </Box>
          <Box sx={{ paddingRight: '10%' }}>
            {/* biome-ignore lint/a11y/noRedundantAlt: <explanation> */}
<img src={detailpageimg} alt="picture" />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#F9F9F9', padding: '20px 2%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ color: '#78829D', fontWeight: '600', paddingRight: '10px' }}>
              #REPORT
            </Typography>
           
            <Button
              variant="contained"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              sx={{
                color: '#1B84FF',
                marginRight: '10px',
                backgroundColor: '#E9F3FF',
                fontWeight: '600',
                '&:hover': { backgroundColor: '#E9F3FF' },
              }}
            >
             {isAdmin?"ALL REPORT":"MY REPORT"}
            </Button>
          </Box>
          <Box sx={{ display: 'inline-flex;', justifyContent: 'space-between', alignItems: 'center',marginTop:"-5px", float: "right" }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <Button variant="contained" sx={{marginRight:"30px"}} className='buttonstyle22' onClick={handleOpenMenu} >
                <div className='buttonstyle' style={{}}>
                 <FilterAltIcon /><div style={{marginLeft:'10px'}}>Filter</div>
                </div>
              </Button>
             
                <Menu
               sx={{'.css-6hp17o-MuiList-root-MuiMenu-list':{paddintTop:"5px"}}}
        anchorEl={anchorEl1}
        open={Boolean(anchorEl1)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{margin:"5px 20px",width:"100%", paddingBottom:"10px"}}>
        <Typography variant='h6' sx={{color:"#071437",padding:"10px 0px",fontWeight:"500"}}>Filter Options</Typography>
        <Typography variant='subtitle1' sx={{color:"#071437",padding:"10px 0px",fontWeight:"600"}}>Date:</Typography>
        <input style={{marginRight:"15px"}} type="date" placeholder="From Date" value={start} onChange={handleStartDateChange} />
            <input style={{marginLeft:"10px"}} type="date" placeholder="End Date" value={end} onChange={handleEndDateChange} />
            <Typography variant='subtitle1' sx={{color:"#071437",padding:"10px 0px",fontWeight:"600"}}>State:</Typography>
            
<Autocomplete
  sx={{ width: "300px", backgroundColor: "#F9F9F9", borderRadius: "5px" }}
  value={type}
  onChange={(event, newValue) => {
    setType(newValue !== null ? newValue : "");
  }}
  options={projectTypeOptions} // Use projectTypeOptions here
  renderInput={(params) => (
    <TextField
      {...params}
      label=""
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
    />
  )}
/>
<Typography variant='subtitle1' sx={{color:"#071437",padding:"10px 0px",fontWeight:"600"}}>Project:</Typography>
<Autocomplete
  multiple
  sx={{ width: "300px", backgroundColor: "#F9F9F9", borderRadius: "5px" }}
  defaultValue={projectList}
  value={projectname} // Ensure this is an array since `multiple` selection is enabled
  onChange={(event, newValue) => {
    setProjectName(newValue);
  }}
  options={projectList} // Your array of project options
  renderInput={(params) => (
    <TextField
      {...params}
      label=""
      placeholder="Select projects"
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
    />
  )}
  renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={option}
            {...getTagProps({ index })}
            sx={{ backgroundColor:getRandomColor(), color: 'white', '& .MuiChip-deleteIcon': { color: 'white' } }}
          />
        ))
      }
/>


        </Box>
      </Menu>

           
          </Box>
        
          <Box>
            <Button className="buttonstyle22" variant="contained" onClick={handleClick}>
              <div className="buttonstyle">
                <FileDownloadIcon />
                <div style={{ marginLeft: '10px' }}>Export</div>
              </div>
            </Button>
            <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{'.css-6hp17o-MuiList-root-MuiMenu-list':{paddingLeft:"0px", paddingRight:"0px !important"}}}
        onClose={()=>{
         
          handleClose()}}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
         <MenuItem  onClick={() => handleMenuClick('pdf')} sx={{paddingLeft:"10px", paddingRight:"20px"}}><IconFileTypePdf style={{color:"red", marginRight:"20px"}}/> PDF</MenuItem>
      <MenuItem onClick={() => handleMenuClick('csv')} sx={{paddingLeft:"10px", paddingRight:"20px"}}><IconFileTypeCsv style={{color:"green", marginRight:"20px"}}/> CSV</MenuItem>
       
      </Menu>
          </Box>
          </Box>
        </Box>
        <Box>
        {fetch?(<>
          <Backdrop
            sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={fetch}
          ><Box sx={{backgroundColor:"white", display:"inline-flex", justifyContent:"center", alignItems:"center", borderRadius:"5px", padding:"5px 30px"}}>
<p style={{fontSize:"18px",marginRight:"15px",fontWeight:"500", color:"#1B84FF"}}>Fetching</p><CircularProgress sx={{color:"#1B84FF"}} size={25} />
          </Box>
            
          </Backdrop>
        </>):(<MyTable data={data} />)}
          
         
        </Box>
      </Paper>
    </Container>
  );
}

export default ReportContent