import React from 'react';
import "./style.css"

const LoadingTick = () => {
  
 
  return (
    <div className="loader1" style={{marginRight:"10px"}}>
     
      
      
    </div>
  );
};

export default LoadingTick;
