import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItemIcon, ListItemText, IconButton, ListItemButton,  Button } from '@mui/material';
import { Home } from '@mui/icons-material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DashboardContent from './DashboardContent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, } from '@mui/system';
import logo from "../public/Group 58.png"
import ProjectContent from './ProjectContent';
import 'react-toastify/dist/ReactToastify.css';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import WorkItemsContent from './WorkItemsContent';
import logo1 from "../public/logo-sim.svg"
import { getLogout, postUserActivity } from '../api/api';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../context/authContext';
import ReportContent from './ReportContent';
import AttendanceContent from './AttendanceContent';
import WebSessionsContent from './WebSessionsContent';
import BatchPredictionOutlinedIcon from '@mui/icons-material/BatchPredictionOutlined';
import UserDetail from './UserDetail';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';


const Sidebar = ({open, setOpen}) => {

  const [selectedItem, setSelectedItem] = useState();
  const location = useLocation();
  const navigate = useNavigate();
 const {token,isAdmin}= useAuth()
console.log(isAdmin)
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
useEffect(() => {
    if(!token){
      navigate('/');
  }
    // Get the current path from the location and find the matching menu item
    const currentPath = location.pathname;
    const matchingItem = menuItems.find((item) => item.path === currentPath);

    // Set the selectedItem based on the matching menu item or set a default value
    setSelectedItem(matchingItem ? matchingItem.text : 'Dashboard');
  }, [location.pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleLogout =async ()=>{
    try {
     
      await getLogout(token, navigate);
      navigate('/');
    } catch (error) {
      console.error('Logout failed', error);
    }
   
   }
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleItemClick = (item) => {
    postUserActivity(token,`User Accessed ${item} Page`)
    setSelectedItem(item);
  };


  const menuItems = [
    { text: 'Dashboard', icon: <Home />, content:<DashboardContent/>, path:"/dashboard" },
    { text: 'Projects', icon: <AssignmentIcon />, content:<ProjectContent/>, path:"/dashboard/projects" },
     { text: 'Work Items', icon: <TaskAltOutlinedIcon />, content: <WorkItemsContent/>,path:"/dashboard/workitems"  },
     { text: 'Report', icon: <AssessmentOutlinedIcon />, content: <ReportContent/>,path:"/dashboard/report"  },
     { text: 'Attendance', icon: <PostAddIcon />, content: <AttendanceContent/>,path:"/dashboard/attendance"  },
     { text: 'Web Sessions', icon: <BatchPredictionOutlinedIcon />, content: <WebSessionsContent/>,path:"/dashboard/web-sessions"  },
     { text: 'User Details', icon: <AccountTreeOutlinedIcon />, content: <UserDetail/>,path:"/dashboard/user-details"  },
    // { text: 'Info', icon: <Info />, content: 'Info content' },
  ];
    
  return (
    <div>
      
      <Drawer  PaperProps={{
          sx: {
            width:open? 265:"75px",
            overflow: "visible",
            paddingLeft: open?"0px":"10px",
            height: "100%",
            paddingTop:"10px",
            backgroundColor: "#0d0e12",
            color: "#f5f5f5",
            '& .MuiListItemIcon-root': {
              color: '#f5f5f5',
            },
            '& .MuiListItem-button:hover': {
              backgroundColor: '#1a1b1f',
            },
            '& .MuiListItem-root.Mui-selected': {
              backgroundColor: '#1a1b1f',
            },
          },
        }} variant="permanent" open={open}>
{open?<img src={logo} style={{width:"10rem", margin:"12px 20px", display:"block"}}  alt='logo'/>
: <img src={logo1} style={{width:"3rem", height:"3rem", margin:"12px 5px", display:"block"}}  alt='logo'/>}
       
        <Box sx={{ 
  position: 'absolute', 
  top: 10, 
  right: open ? -20 : -25, // adjust these values as needed
}}>
  <IconButton 
    sx={{ 
      borderRadius:"10px",
      zIndex:10, 
      backgroundColor:"white",
      '&:hover': {
        backgroundColor: 'white', 
      },
      '&:focused':{
        backgroundColor:"white"
      }, 
      border:"1px solid  rgba(0, 0, 0, 0.03)",
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.03)',
    }} 
    onClick={open ? handleDrawerClose : handleDrawerOpen}
  >
  <ArrowBackIcon className={open ?   'rotateMinus180':'rotate180'} sx={{color:'#1B84FF',borderLeft:"3px solid rgb(27, 132, 255,.3)", paddingLeft:"1px"}}/>

  </IconButton>
</Box>
      
        <List sx={{paddingTop:"30px",flexGrow: 1 }}>
          {menuItems.map((item, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
<ListItemButton divider={true} disableGutters  sx={{margin:"20px 10px",display:item.text ==="Web Sessions"?"none":!isAdmin && item.text==="User Details"?"none":""}} key={index} 
            component={Link}
            to={item.path}
            selected={location.pathname === item.path} onClick={() => handleItemClick(item.text)}>
              <ListItemIcon sx={{paddingLeft:"10px"}}>
                {item.icon}
              </ListItemIcon>
              {open && <ListItemText primary={item.text} />}
            </ListItemButton>
          ))}
        </List>
        <Box ml={2} mb={2}>
        <Button variant='text' disableElevation disableFocusRipple disableRipple disableTouchRipple
          onClick={()=> {handleItemClick('Web Sessions')
          navigate('/dashboard/web-sessions')
          }}
          sx={{color:"white", textTransform:"capitalize", fontSize:"18px",marginBottom:"5px" }}
          >
          <BatchPredictionOutlinedIcon sx={{marginRight:"2rem"}}/>
            Sessions
          </Button>
          <Button variant='text' disableElevation disableFocusRipple disableRipple disableTouchRipple
          onClick={handleLogout}
          sx={{color:"white", textTransform:"capitalize", fontSize:"18px", }}
          >
          <LogoutIcon sx={{marginRight:"2rem"}}/>
            Logout
          </Button>
        </Box>
      </Drawer>

      
    </div>
  );
};

export default Sidebar;
