import { Box, Button, Card, CardActions, CardContent, Container, Divider, Grid, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../context/authContext';
import { getUsers } from '../api/adminApi';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
function UserDetail() {
  const {token}=useAuth();
const [users,setusers]=useState([])

const navigate=useNavigate()
const fetchData=async()=>{
  try{
    const userData=await getUsers(token);
    setusers(userData.data)
  }catch(error){
console.log(error)
  }
}

  useEffect(()=>{
    fetchData();
  },[])


  return (
    <Container fixed sx={{ alignItems: 'center', marginTop: '20px', maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' } }}>
    <Box sx={{ display: 'flex !important', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box>
        <Typography variant="h6" sx={{ color: '#071437', fontWeight: '600' }}>
          View User Details
        </Typography>
        <Typography variant="body2" sx={{ color: '#99A1B7', fontWeight: '600' }}>
          Home - User Management - User details
        </Typography>
      </Box>
        <Box sx={{display:"flex",justifyContent:"space-between", alignItems:"center", width:"100px"}}>
        <Button
        variant="text"
        sx={{ textTransform: 'capitalize', color: '#252F4A', backgroundColor: '#F1F1F4', fontWeight: '600' }}
  
      >
       Filter
      </Button>
      <Button
        variant="text"
        sx={{ textTransform: 'capitalize', color: 'white', backgroundColor: '#1B84FF', fontWeight: '600' }}
  
      >
      Create
      </Button>
        </Box>

      </Box>
      <Box>
      <Grid container spacing={2.5} sx={{ marginTop: '5px' }} >
          {users.map((item,index)=>{
            return(
              <Grid item key={item.id} xs={12} sm={6} md={4} lg={4} sx={{justifyContent:"center"}}>
              <Card className='Cardbox' sx={{maxWidth:"500px"}} onClick={()=>{
                navigate(`/dashboard/user-details/${encodeURIComponent(item.name)}`);
              }}>
                <CardContent>
                  <Box mb={2} sx={{display:"flex",justifyContent:"center", alignItems:"center",}}>
                 
                  <Avatar name={item.name} size='50' textSizeRatio={2} round="50px"/>
              

                  </Box>
                  <Typography variant='h5' sx={{color:"#071437", textAlign:"center"}}>{item.name}</Typography>
                  <Divider variant='middle'/>
                  <Box p={2}>
                    <Typography variant='body1' sx={{color:"#99A1B7"}}>Email: <span>{item.email}</span> </Typography>
                    <Typography variant='body1' sx={{color:"#99A1B7"}}>Role: <span>{item.roles.length!==0?item.roles.map(role => role.name):"No role assigned"}</span> </Typography>
                    <Typography variant='body1' sx={{color:"#99A1B7"}}>Employee ID: <span>{item.id}</span> </Typography>
                  </Box>
                  <Box sx={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                    <Button variant='text' sx={{textTransform:"capitalize", color:"#1B84FF","&:hover":{backgroundColor:"transparent"}}}
                    disableElevation disableFocusRipple disableRipple disableTouchRipple>View details</Button>
                  </Box>
                </CardContent>
                
              </Card>
             
              </Grid>
            )
          })

          }

      </Grid>

      </Box>

      </Container>
  )
}

export default UserDetail