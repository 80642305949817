// src/redux/actions/index.js
import { UPDATE_PROJECTS_DATA } from './actionTypes';
import { STORE_CSRF_TOKEN } from './actionTypes';
export const navigateTo = (route) => ({
    type: 'NAVIGATE_TO',
    payload: route,
  });

  export const loginUser = (userData) => ({
    type: 'LOGIN_USER',
    payload: userData,
  });

  export const storeCSRFToken = (csrfToken) => ({
    type: STORE_CSRF_TOKEN,
    payload: csrfToken,
  });

export const updateProjectsData = (data) => ({
  type: UPDATE_PROJECTS_DATA,
  payload: data,
});

export const storeWorkItemData = (data) => ({
  type: 'STORE_WORK_ITEM_DATA',
  payload: data,
});