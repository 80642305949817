// api.js

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTimerStats } from '../context/timerStatsContext';
const BASE_URL = process.env.REACT_APP_INTERNAL_SERVER_API;


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_INTERNAL_SERVER_API,
  withCredentials: true,
});


export const getCsrfToken = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/csrf-token`, 
     
      {withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const getLogin= async(username,password)=>{
  try{
    const csrfToken= await getCsrfToken()
    axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    const response=await axios.post(`${BASE_URL}/login`, {
        email: username,
        password: password,
      }, {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true, 
      });
return response;
  }catch(error){
    console.log(error)
  }
}

export const addRelatedWork= async(token,parent,child,project)=>{
  try{
    const response=await axios.get(`${BASE_URL}/workitem/add/parent/${child}/${parent}/${project}`,  {
        headers: {
          Authorization: `Bearer ${token}`,
        },withCredentials: true,
      });
      await postUserActivity(token,'User Created New Work Item')
return response;
  }catch(error){
    console.log(error)
  }
}

export const removeRelatedWork= async(token,parent,child,project)=>{
  try{
    const response=await axios.get(`${BASE_URL}/workitem/remove/parent/${child}/${parent}/${project}`,  {
        headers: {
          Authorization: `Bearer ${token}`,
        },withCredentials: true,
      });
      await postUserActivity(token,'User Created New Work Item')
return response;
  }catch(error){
    console.log(error)
  }
}

export const createWorkItem= async(token,title,workItem,project,description,externalLink,relatedWork,projectid,activity)=>{
  try{
    const response=await axios.post(`${BASE_URL}/workitem/add`, {
        title: title,
        type:workItem,
        description:description,
        project:project,
        extranel_url:externalLink,
        workitem_type:activity
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },withCredentials: true,
      });
    
      if(relatedWork!==''){
        await addRelatedWork(token,relatedWork,response.data.data.id,projectid)

      }
     
      await postUserActivity(token,'User Created New Work Item')
return response;
  }catch(error){
    console.log(error)
  }
}

export const postUserActivity = async (token,message) => {
  try {
    const response = await axios.post(`${BASE_URL}/activity-store`,
    {
      message:message,
    },
     {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const getProjects = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/projects`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};


export const getMicrosoft = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/auth-login/${token}`, 
     
      {withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};


export const getLogout = async (token, navigate) => {
 
  try {
    await postUserActivity(token, "User Signed Out")
    const response = await axios.post(`${BASE_URL}/logout`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
   
    localStorage.removeItem('token'); 
    localStorage.removeItem('auth');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('timerStats');
    navigate('/');
    return response.data;
  } catch (error) {
    console.error('Error fetching logout', error);
    throw error;
  }
};

export const getMicrosoftLogin= async () => {
 
  try {
  
    
    const loginResponse = await axios.post(
      `${BASE_URL}/login/microsoft/`,{},
      {  withCredentials: true }
    );
    const { token, users } = loginResponse.data;
      await postUserActivity(token,"User Signed In via Micorsoft ")
    if (token && users) {
      localStorage.setItem('token', token);
      localStorage.setItem('name', users.name);
      localStorage.setItem('email', users.email);
      localStorage.setItem('auth', true);
    } else {
      console.error('Invalid response structure from Microsoft login API');
    }
    return loginResponse
    }catch(error){
      console.log(error)
    }

  
  };

  export const ChangePassword= async (token,email,password,repeatPassword) => {
 
    try {
    
      
      const loginResponse = await axios.post(
        `${BASE_URL}/password-change`,{

          password:password,
          password_confirmation:repeatPassword
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );  
     await postUserActivity(token,'User Changed Password')
      return loginResponse
      }catch(error){
        console.log(error)
      }
  
    
    };


export const getWorkItems = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    const selectedFields = response.data.WorkItemsDetail || [];
    return selectedFields.map((item) => ({
      ...item,
      id: item.Work_ID,
    }));
  } catch (error) {
    console.error('Error fetching all work items:', error);
    throw error;
  }
};
export const getProjectWorkItems = async (token,project) => {
  try {
    const response = await axios.get(`${BASE_URL}/projects/${project}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    await postUserActivity(token,`User Accessed ${project} project page`)
    return response.data;
  } catch (error) {
    console.error('Error fetching all work items:', error);
    throw error;
  }
};
export const getProjectWorkItemsState = async (token,project,state) => {
  try {
    const response = await axios.get(`${BASE_URL}/projects/${project}/${state}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    await postUserActivity(token,`User Accessed ${project} project page`)
    return response.data;
  } catch (error) {
    console.error('Error fetching all work items:', error);
    throw error;
  }
};
export const getWorkItemsActive = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem/active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    const selectedFields = response.data.WorkItemsDetail || [];
   
    return selectedFields.map((item) => ({
      ...item,
      id: item.Work_ID,
    }));
  } catch (error) {
    console.error('Error fetching active work items:', error);
    throw error;
  }
};


export const getWorkItemsPause = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem/pause`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    const selectedFields = response.data.WorkItemsDetail || [];
   
    return selectedFields.map((item) => ({
      ...item,
      id: item.Work_ID,
    }));
  } catch (error) {
    console.error('Error fetching pause work items:', error);
    throw error;
  }
};

export const getWorkItemsResolved = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem/resolved`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    const selectedFields = response.data.WorkItemsDetail || [];
 
    return selectedFields.map((item) => ({
      ...item,
      id: item.Work_ID,
    }));
  } catch (error) {
    console.error('Error fetching active work items:', error);
    throw error;
  }
};
export const getWorkItemsClosed = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem/closed`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    const selectedFields = response.data.WorkItemsDetail || [];
  
    return selectedFields.map((item) => ({
      ...item,
      id: item.Work_ID,
    }));
  } catch (error) {
    console.error('Error fetching resolved work items:', error);
    throw error;
  }
};

export const getWorkItemsRemoved = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem/removed`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    const selectedFields = response.data.WorkItemsDetail || [];
   
    return selectedFields.map((item) => ({
      ...item,
      id: item.Work_ID,
    }));
  } catch (error) {
    console.error('Error fetching active work items:', error);
    throw error;
  }
};


export const getWorkItemsNew = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem/new`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    const selectedFields = response.data.WorkItemsDetail || [];
  
    return selectedFields.map((item) => ({
      ...item,
      id: item.Work_ID,
    }));
  } catch (error) {
    console.error('Error fetching new work items:', error);
    throw error;
  }
};
export const getWorkItemsHold = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem/hold`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    const selectedFields = response.data.WorkItemsDetail || [];
  
    return selectedFields.map((item) => ({
      ...item,
      id: item.Work_ID,
    }));
  } catch (error) {
    console.error('Error fetching new work items:', error);
    throw error;
  }
};

export const getWorkItemDetails = async (token, workId) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem/${workId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    await postUserActivity(token,`User Accessed ${workId} Work Item Page`)
    return response.data;
  } catch (error) {
    console.error('Error fetching work item details:', error);
    throw error;
  }
};

export const getWorkItemDetail = async (token, workId) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitems/${workId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    
    await postUserActivity(token,`User Accessed ${workId} Work Item Page`)
    return response.data;
  } catch (error) {
    console.error('Error fetching work item details:', error);
    throw error;
  }
};

export const getActiveWorkItemDetail = async (token, workId) => {
  try {
    
    const response = await axios.get(`${BASE_URL}/multi-workitems/${workId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    
  
    return response.data;
  } catch (error) {
    console.error('Error fetching work item details:', error);
    throw error;
  }
};

export const getWorkItemByDate = async (token, date) => {
  try {
    const response = await axios.get(`${BASE_URL}/workitem/date/${date}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
  
   
    return response.data;
  } catch (error) {
    console.error('Error fetching work item details:', error);
    throw error;
  }
};

export const getWorkItemHistory = async (token, id) => {
  try {
    const response = await axios.get(`${BASE_URL}/tasks/id/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
  
    return response.data;
  } catch (error) {
    console.error('Error fetching work item details:', error);
    throw error;
  }
};

export const getTimerCount = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/tasks/status/active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });

    const timerstats = response.data.incomplete_timers_data.map((timer,index)=> timer.Work_ID)

    localStorage.setItem('timerstats',timerstats)

    localStorage.setItem('timerdata',response.data.incomplete_timers_data)
    return response.data;
  } catch (error) {
    console.error('Error fetching work item details:', error);
    throw error;
  }
};

export const getStartTimer = async (token, workItemId,server,project) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/tasks-start`,
      {
        id: workItemId,
        server: server,
        project_name: project,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    await postUserActivity(token,`User Started Timer For <b>${workItemId}</b>`)
    getTimerCount(token);
    return response.data;
  } catch (error) {
    console.error('Error fetching start timer:', error);
    throw error;
  }
};


export const getEndTimer = async (token, workItemId,comment) => {
  try {
    const response = await axios.post(`${BASE_URL}/tasks/end`,{
      id:workItemId,
      user_comments:comment
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
    await postUserActivity(token,`User Ended Timer For <b>${workItemId}</b>`)
    getTimerCount(token);
    return response.data;
  } catch (error) {
    console.error('Error fetching start timer:', error);
    throw error;
  }
};

export const getPunchStatus = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/punchstatecheck`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });
  
    return response.data;
  } catch (error) {
    console.error('Error fetching work item details:', error);
    throw error;
  }
};


export const getUserActivity = async (token) => {
  try {
 
    const response = await axios.get(`${BASE_URL}/user-activity`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },withCredentials: true,
    });

    await postUserActivity(token,"User Accessed User Activity Page")
    return response.data;
  } catch (error) {
    console.error('Error fetching user activity data:', error);
    throw error;
  }
};

export const getPunchin = async (token, location) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/punchin`,
      {
        location: location
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true,
      }
    );
    await postUserActivity(token,'User Punched In')
    return response.data;
  } catch (error) {
    console.error('Error fetching start timer:', error);
    throw error;
  }
};


export const getPunchout = async (token,location) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/punchout`,
      {
        location: location
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true,
      }
    );
    await postUserActivity(token,'User Punched Out')
    return response.data;
  } catch (error) {
    if (error.response.status === 403) {
      toast.error('Please close work timer before punch out.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return null;
    }else{
      console.error('Error fetching start timer:', error);
      throw error;
    }
    
   
  }
};

export const EditData = async (token, workItemId,change) => {
  try {
 

    const response = await axios.get(`${BASE_URL}/workitem-update/${workItemId}/${change}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    await postUserActivity(token,`User Changed The State Of <b> ${workItemId}</b> To <b>${change}</b>`)
    return response.data;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};

export const getProjectMonthlyData = async (token, StartDate,EndDate) => {
  try {
 

    const response = await axios.get(`${BASE_URL}/project-reports/user/${StartDate}/${EndDate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    
    return response.data;
  } catch (error) {
    console.error('Error Monthly data:', error);
    throw error;
  }
};



export const getAttendenceReport = async (token, StartDate,EndDate) => {
  try {
 

    const response = await axios.get(`${BASE_URL}/get-attendance`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
  
    return response.data;
  } catch (error) {
    console.error('Error Monthly data:', error);
    throw error;
  }
};


export const getProjectTimer = async (token, project) => {
  try {
 

    const response = await axios.get(`${BASE_URL}/project/${project}/timerstate`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    
    return response.data;
  } catch (error) {
    console.error('Error Monthly data:', error);
    throw error;
  }
};


export const getUserSessions = async (token) => {
  try {
 

    const response = await axios.get(`${BASE_URL}/check-session`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
 
    return response.data;
  } catch (error) {
    console.error('Error Monthly data:', error);
    throw error;
  }
};

export const getRevokeSession = async (token,id) => {
  try {
 

    const response = await axios.get(`${BASE_URL}/revoke-session/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    await postUserActivity(token, `User Revoked Session With ID <b>${id}</b> `)
    return response.data;
  } catch (error) {
    console.error('Error Monthly data:', error);
    throw error;
  }
};
