import React, { useEffect, useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Paper,
  Typography,
  Button,
  Pagination,
} from '@mui/material';
import './style.css';
import CloseIcon from '@mui/icons-material/Close';
import { getUserActivity } from '../api/api';
import { useAuth } from '../context/authContext';
// const data = [
//     {
//         "id": 31041,
//         "user_id": 4,
//         "action": "User has signed in.",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:16",
//         "updated_at": "2024-01-18 09:56:16"
//     },
//     {
//         "id": 31042,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:18",
//         "updated_at": "2024-01-18 09:56:18"
//     },
//     {
//         "id": 31043,
//         "user_id": 4,
//         "action": "Users accessing State based work items => active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:18",
//         "updated_at": "2024-01-18 09:56:18"
//     },
//     {
//         "id": 31044,
//         "user_id": 4,
//         "action": "Users accessing State based work items => active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:18",
//         "updated_at": "2024-01-18 09:56:18"
//     },
//     {
//         "id": 31045,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:18",
//         "updated_at": "2024-01-18 09:56:18"
//     },
//     {
//         "id": 31046,
//         "user_id": 4,
//         "action": "Retrieve date based workitem =>2024-01-18",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:19",
//         "updated_at": "2024-01-18 09:56:19"
//     },
//     {
//         "id": 31047,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>new",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:19",
//         "updated_at": "2024-01-18 09:56:19"
//     },
//     {
//         "id": 31048,
//         "user_id": 4,
//         "action": "Retrieve date based workitem =>2024-01-18",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:19",
//         "updated_at": "2024-01-18 09:56:19"
//     },
//     {
//         "id": 31049,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:19",
//         "updated_at": "2024-01-18 09:56:19"
//     },
//     {
//         "id": 31050,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:19",
//         "updated_at": "2024-01-18 09:56:19"
//     },
//     {
//         "id": 31051,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>new",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:19",
//         "updated_at": "2024-01-18 09:56:19"
//     },
//     {
//         "id": 31052,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>resolved",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:19",
//         "updated_at": "2024-01-18 09:56:19"
//     },
//     {
//         "id": 31053,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>resolved",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:19",
//         "updated_at": "2024-01-18 09:56:19"
//     },
//     {
//         "id": 31054,
//         "user_id": 4,
//         "action": "User Punchin ",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 09:56:30",
//         "updated_at": "2024-01-18 09:56:30"
//     },
//     {
//         "id": 31055,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 10:02:04",
//         "updated_at": "2024-01-18 10:02:04"
//     },
//     {
//         "id": 31056,
//         "user_id": 4,
//         "action": "Users accessing State based work items => active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 10:02:04",
//         "updated_at": "2024-01-18 10:02:04"
//     },
//     {
//         "id": 31057,
//         "user_id": 4,
//         "action": "Users accessing State based work items => active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 10:02:04",
//         "updated_at": "2024-01-18 10:02:04"
//     },
//     {
//         "id": 31058,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>active",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 10:02:05",
//         "updated_at": "2024-01-18 10:02:05"
//     },
//     {
//         "id": 31059,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>new",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 10:02:05",
//         "updated_at": "2024-01-18 10:02:05"
//     },
//     {
//         "id": 31060,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>new",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 10:02:05",
//         "updated_at": "2024-01-18 10:02:05"
//     },
//     {
//         "id": 31061,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>resolved",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 10:02:05",
//         "updated_at": "2024-01-18 10:02:05"
//     },
//     {
//         "id": 31062,
//         "user_id": 4,
//         "action": "Retrieve state based workitem =>resolved",
//         "ip_address": "152.58.204.216",
//         "created_at": "2024-01-18 10:02:05",
//         "updated_at": "2024-01-18 10:02:05"
//     },
//  ];
; // Replace with your actual data array
const CustomStepIcon = () => {
    const color = ['#F8285A', '#17C653', '#1B84FF', '#F6C000'];
    const randomIndex = Math.floor(Math.random() * color.length);
  
    return (
      <div
        style={{
          border: "4px solid",
          borderColor: color[randomIndex],
          padding: "8px",
          borderRadius: "20px",
        }}
      >
        {/* This component is used to hide the default numbers in StepIcon */}
      </div>
    );
  };
  
const RecordsPerPage = 11;

const VerticalStepperWithPagination = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data,setActivityData]=useState([]);
  const totalPages = Math.ceil(data.length / RecordsPerPage);
  const {token, email}= useAuth()
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setActiveStep((value - 1) * RecordsPerPage);
  };
  const fetchData=async ()=>{
    const getUserActivitydata= await getUserActivity(token)
    setActivityData(getUserActivitydata.data.user_activity_last_three_days.reverse())
   
  }
  useEffect(()=>{
   
    fetchData()
  },[])

  
  const renderStepperContent = (index) => {
    const startIndex = index;
    const endIndex = Math.min(index + RecordsPerPage, data.length);

    return data.slice(startIndex, endIndex).map((item, i) => (
        <Step key={startIndex + i}>
        <Box sx={{display:"flex" , alignItems:"center"}}> 
        <Typography variant="body1" sx={{width:"50px",color:"#252F4A", fontWeight:"600"}}>{item.created_at.slice(11, 16)}</Typography>
        <StepLabel icon={<CustomStepIcon />}>
          <Box sx={{marginLeft:"10px", width:"100%"}}>
            <Typography variant="body1" >
              <div dangerouslySetInnerHTML={{ __html: item.action }} />
            </Typography>
          </Box>
        </StepLabel>
        </Box>
        
      </Step>
      
    ));
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box flex={1} mr={2} mt={2}>
        <Stepper  orientation="vertical" sx={{'.MuiStepLabel-iconContainer':{padding:"0px"},'.MuiStepConnector-vertical':{marginLeft:"60px",width:"2px"}}}>
          {renderStepperContent(activeStep)}
        </Stepper>
        <Box mt={2} display="flex" justifyContent="center" alignItems="center">
          <Pagination
          shape="rounded"
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
    '.Mui-selected': {
      backgroundColor: '#1B84FF !important',
      color:"white"
    },
  }}
          />
        </Box>
      </Box>
    
    </Box>
  );
};

export default VerticalStepperWithPagination;
