import React, { useState, useEffect } from 'react';
import { Paper, Typography, Button, Box, useTheme, useMediaQuery, Modal } from '@mui/material';
import Slider from 'react-slick';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { getWorkItemByDate } from '../api/api';
import { useAuth } from '../context/authContext';
import TableProjectView from './TableProjectView';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import "./style.css"



let bgcolor; 
let icon;
let bgcoloricon;
const setcolor=(params)=>{
  switch(params){
    case 'Task': {
      bgcoloricon = '#FAF3D3';
      bgcolor = 'rgb(164, 136, 10)';
      icon = <AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px", marginRight:"8px"}}/>;
      break;
    }
    case 'Bug': {
      bgcoloricon = '#FFDFDD';
      bgcolor = 'rgb(204, 41, 61)'; // Change this to the color you want for 'Bug'
      icon = <BugReportOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Bug'
      break;
    }
    case 'Feature': {
      bgcoloricon = '#F5F0FF';
      bgcolor = 'rgb(180, 0, 158)'; // Change this to the color you want for 'Feature'
      icon = <EmojiEventsIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Feature'
      break;
    }
    case 'User Story': {
      bgcoloricon = '#E5F9FF';
      bgcolor = 'rgb(0, 152, 199)'; // Change this to the color you want for 'UserStory'
      icon = <MenuBookOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'UserStory'
      break;
    }
    case 'Epic': {
      bgcoloricon = '#FAF3D3';
      bgcolor = 'rgb(224, 108, 0)'; // Change this to the color you want for 'Epic'
      icon = <StarsOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Epic'
      break;
    }
    case 'Issue': {
      bgcoloricon = '#f8e2f6';
      bgcolor = '#b4009e'; // Change this to the color you want for 'Epic'
      icon = <ErrorOutlineOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Epic'
      break;
    }
    default:{
      bgcoloricon = '#000000';
      bgcolor="#000000";
      icon=<AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>
      // Default case
    }
}

}
const Schedule = ({ System_Project_name, System_Title, System_State, Work_ID,schedule }) => {
  const{isAdmin}=useAuth()
  const stateColors = {
    'Active': '#17c653',
    'New': '#1B84FF',
    'Resolved': '#FFAD0F',
    'Closed': '#F1416C',
    'Pause': '#6951aa',
    'Removed': '#000',
    'Hold':"red"
  };
  const randomColor = System_State ==="Active"?"#1B84FF": System_State ==="Closed"?"#F8285A": System_State==="Resolved"?'#17C653':"#7239EA";
  setcolor(schedule.System_WorkItemType)

  return (
    <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        mb: 2,
        borderLeft: `5px solid ${bgcolor}`,
        padding: ' 10px 20px',
        minHeight: '100px', 
      }}
    > <Box>
 <Typography variant="h6" sx={{textTransform:"capitalize"}}>{System_Title}</Typography>
      <Typography variant="subtitle1">{System_Project_name}</Typography>
     
      <Typography variant="body2"> <div style={{background: stateColors[System_State],color:"white",borderRadius:"5px", textAlign:"center",padding:"2px", width:"60px", marginBottom:"5px" }}> {System_State}</div> </Typography>
      <Typography variant="body2" sx={{color:bgcolor,display:"flex",alignItems:"center"}}>{icon} {schedule.System_WorkItemType}</Typography>
      <Typography variant="body2" sx={{display:isAdmin?"block":"none"}}>Assigned to: {schedule.System_AssignedTo}</Typography>
    </Box>
     <Box>

   <TableProjectView id={Work_ID} size={'32px'}/>
     </Box>
     
    </Box>
   
    </>
  );
};
function Carousel({ selectedDate, onSelectDate,smallScreen }) {
  const [current, setCurrent] = useState(0);

  const nextSlide = () => {
    setCurrent(current + 1);
  };

  const prevSlide = () => {
    setCurrent(current - 1);
  };

  const generateDates = (start) => {
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dates = Array.from({ length:smallScreen? 7: 14 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() + i + start);
      const formattedDate = date.toISOString().split('T')[0];
      return {
        key: formattedDate,
        label: `${dayNames[date.getDay()]} ${date.getDate()}`,
      };
    });
    return dates;
  };

  const dates = generateDates(current);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:"100%", padding:"5px 10px"}} >
      <button style={{border:"none", backgroundColor:"transparent",marginLeft:"5px",marginRight:"5px"}} onClick={prevSlide}><KeyboardDoubleArrowLeftIcon sx={{color:"grey"}}/></button>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:"100%"}} >
      {dates.map(({ key, label }) => (
        <Button
          key={key}
          disableElevation
          disableFocusRipple
          disableRipple
          variant={key === selectedDate ? 'contained' : 'text'}
          sx={{
            backgroundColor: key === selectedDate ? '#F8285A;' : 'transparent',
            color: key === selectedDate ? 'white' : '#071437',
            width: '60px',
            borderRadius: '50rem',
            alignItems: 'center',
            border: 'none',
            height: '100px',
            minWidth: '20px',
            padding: '10px',
            textTransform: 'capitalize',
            fontSize: '1.2rem',
            margin:"2px 5px",
            '&:hover': {
              backgroundColor: '#F8285A',
              color: 'white',
            },
            '&:focus': {
              backgroundColor: '#F8285A',
              color: 'white',
            },
          }}
          onClick={() => onSelectDate(key)}
        >
          {label}
        </Button>
      ))}

      </div>
      
      <button style={{border:"none", backgroundColor:"transparent",marginLeft:"5px",marginRight:"5px"}} onClick={nextSlide}><KeyboardDoubleArrowRightIcon sx={{color:"grey"}}/></button>
    </div>
  );
}
const Calendar = ({  selectedDate, onSelectDate, onPrevWeek, onNextWeek }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        justifyContent: 'space-between',
        overflowX: isSmallScreen ? 'auto' : 'hidden',
        mb: 2,
        mt: 2,
      }}
    >
      <Carousel selectedDate={selectedDate} onSelectDate={onSelectDate} smallScreen={isSmallScreen} />
    </Box>
  );
};

const CalenderTable = () => {
  const currentDate = new Date();
  const [weekStartDate, setWeekStartDate] = useState(new Date(currentDate));

  const {token,isAdmin}= useAuth()
  const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0'); 
const day = String(today.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;
  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const [schedules, setSchedules] = useState({});
  const [loading, setLoading] = useState(false);

  const onPrevWeek = () => {
    const newStartDate = new Date(weekStartDate);
    newStartDate.setDate(weekStartDate.getDate() - 7);
    setWeekStartDate(newStartDate);
  };

  const onNextWeek = () => {
    const newStartDate = new Date(weekStartDate);
    newStartDate.setDate(weekStartDate.getDate() + 7);
    setWeekStartDate(newStartDate);
  };

  const fetchSchedules = async (date) => {
    setLoading(true);
    try {
      
      const response = getWorkItemByDate(token, date);
      const data = await response;
      setSchedules({ ...schedules, [date]: data.WorkItemsDetail });
    } catch (error) {
      console.error('Error fetching schedules:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!schedules[selectedDate]) {
      fetchSchedules(selectedDate);
    }
  }, [selectedDate, schedules]);

  return (
  
      <Paper sx={{ borderRadius: '0.625rem', boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)', width: '100%', paddingTop:"20px",marginTop:"20px", paddingBottom:"20px", border:"1px solid #F1F1F4" }}>
        <Typography variant="h4" sx={{padding:"5px 50px", fontWeight:"600"}}>What’s up <span style={{color:"#F8285A", borderBottom:"5px solid rgb(248,40,90,.3)", paddingBottom:"5px", }}>Today</span>
        <Typography className='' variant='p' sx={{color:'#9b9696',display:'block', fontSize:'12px', marginTop:"16px", fontWeight:"400"}}>Today's Focus: Navigate Daily Tasks with Precision</Typography>
</Typography>
        
        <Calendar
     
          selectedDate={selectedDate}
          onSelectDate={setSelectedDate}
          onPrevWeek={onPrevWeek}
          onNextWeek={onNextWeek}
        />
        <Box
          sx={{
            overflow: 'auto',
            height: '340px',
            alignItems: 'center',
            justifyContent: 'center',
            '&::-webkit-scrollbar': {
              display: 'none',
              padding: '20px',
            },
          }}
        >
          {loading ? (
            <Typography variant="h5" sx={{ textAlign: 'center', color: 'grey', marginTop: '50px' }}>Loading...</Typography>
          ) : schedules[selectedDate] && schedules[selectedDate].length ? (
            schedules[selectedDate].map((schedule) => <Schedule key={schedule.Work_ID} {...schedule} schedule={schedule}/>)
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center', color: '#F1416C',border:"2px dashed #F1416C69", backgroundColor:"#fd413138", margin: '100px 30%',borderRadius:"5px", padding:"10px 5px" }}>No Task for this day.</Typography>
          )}
        </Box>
      </Paper>
 
  );
};

export default CalenderTable;
