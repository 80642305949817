import React, { useEffect, useState } from 'react';
import {  Container, styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { Box, Button, FormControl,Menu, MenuItem, Pagination, Paper, Select, Typography } from '@mui/material';
import { EditData,  getTimerCount, getWorkItems, getWorkItemsActive,getWorkItemsPause, getWorkItemsClosed, getWorkItemsHold, getWorkItemsNew, getWorkItemsResolved, getWorkItemsRemoved } from '../api/api';
import "./style.css"
import { toast } from 'react-toastify';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import 'react-toastify/dist/ReactToastify.css';
import TableProjectView from './TableProjectView';
import { useAuth } from '../context/authContext';
import RotatingTimerIcon from './RotatingTimer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { usePieChartData } from '../context/pieChartContext';
import FilterButton from './FilterButton';
import { Navigate, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AddWorkItemButton from './AddWorkItemButton';

const commonStyles = {
  borderRadius: '0.625rem',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Inter, Helvetica, sans-serif',
};
  
  const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-root': {
      border: 'none',
     
      ...commonStyles,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      paddingLeft: "20px"

    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'transparent',
    },
    '.MuiDataGrid-columnGroupHeader': {
      border: 'none',
    },
    '.MuiDataGrid-columnHeader': {
      color: 'grey',
      fontWeight: '600',
      fontFamily: 'Inter, Helvetica, sans-serif',
    },
    '.MuiDataGrid-row': {
      border: 'none',
      overflow:"none",
      fontFamily: 'Inter, Helvetica, sans-serif',
    },
    '.MuiDataGrid-cell': {
      borderBottom: 'none',
      width:'auto',
    },
    '.MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
    },
    '.MuiDataGrid-iconSeparator': {
      color: 'transparent',
    },
    '.MuiDataGrid-virtualScroller':{
     
      overflowX: 'auto', 
      '&::-webkit-scrollbar': {
        display: 'none',
        padding: '20px',
      },
    },
  
    padding: '10px 20px',
  });

  const inputStyles = {
    display: "block",
   
    height: "1.075rem",
    padding: "0.8rem .9rem",
    fontSize: ".9rem",
    fontWeight: "500",
    lineHeight: "1.5",
  
    appearance: "none",
    margin: "10px 0px",
    backgroundClip: "padding-box",
    border: "1px solid #DBDFE9",
    borderRadius: "0.475rem",
    outline: "none",
   
    transition: "border-color 0.3s ease",
    '&::placeholder':{
      color:"#4B5675",
      fontWeight:"600"
    }
  };


function WorkItemsContent() {
    const [workItems,setWorkItems]=useState([]);
    const {isAdmin}=useAuth();
    const user = useSelector((state) => state.user.user);
    const [selectedState, setSelectedState] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const {updatePieChart}= usePieChartData()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filterValue, setFilterValue] = useState('');
    const handleChangePage = (event, newPage) => {
      setCurrentPage(newPage);
    };
    
    const filteredWorkItems = workItems.filter(item => {
      const assignedTo = item.System_AssignedTo || ''; // Fallback to empty string if undefined or null
      return assignedTo.toLowerCase().includes(filterValue.toLowerCase());
    });
    
  const uniqueAssignedToList = Array.from(new Set(workItems
    .filter(item => item.System_AssignedTo) // Ensure the property exists and is not undefined or null
    .map(item => item.System_AssignedTo.trim()) // Trim any leading or trailing whitespace
  ));
 
  const paginatedWorkItems = isAdmin? filteredWorkItems.slice((currentPage - 1) * pageSize, currentPage * pageSize):workItems.slice((currentPage - 1) * pageSize, currentPage * pageSize)
  let bgcolor; 
  let icon;
  let bgcoloricon;
  const setcolor=(params)=>{
    switch(params){
      case 'Task': {
        bgcoloricon = '#FAF3D3';
        bgcolor = 'rgb(164, 136, 10)';
        icon = <AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px", marginRight:"8px"}}/>;
        break;
      }
      case 'Bug': {
        bgcoloricon = '#FFDFDD';
        bgcolor = 'rgb(204, 41, 61)'; // Change this to the color you want for 'Bug'
        icon = <BugReportOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Bug'
        break;
      }
      case 'Feature': {
        bgcoloricon = '#F5F0FF';
        bgcolor = 'rgb(180, 0, 158)'; // Change this to the color you want for 'Feature'
        icon = <EmojiEventsIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Feature'
        break;
      }
      case 'User Story': {
        bgcoloricon = '#E5F9FF';
        bgcolor = 'rgb(0, 152, 199)'; // Change this to the color you want for 'UserStory'
        icon = <MenuBookOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'UserStory'
        break;
      }
      case 'Epic': {
        bgcoloricon = '#fadfd3';
        bgcolor = 'rgb(224, 108, 0)'; // Change this to the color you want for 'Epic'
        icon = <StarsOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Epic'
        break;
      }
      case 'Issue': {
        bgcoloricon = '#f8e2f6';
        bgcolor = '#b4009e'; // Change this to the color you want for 'Epic'
        icon = <ErrorOutlineOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>; // Change this to the icon you want for 'Epic'
        break;
      }
      default:{
        bgcoloricon = '#000000';
        bgcolor="#000000";
        icon=<AssignmentTurnedInOutlinedIcon sx={{fontSize:"18px",marginRight:"8px"}}/>
        // Default case
      }
  }

  }
  
  function truncate(str, num) {
    if (str.length <= num) {
      return str;
    }
    return `${str.slice(0, num)}...`;
  }   
const {token}= useAuth()
const fetchData = async () => {
    
  try {
    let workItemsData;

    // Fetch data based on the selected state
    switch (selectedState) {
      case 'active':
        workItemsData = await getWorkItemsActive(token);
        break;
      case 'closed':
        workItemsData= await getWorkItemsClosed(token);
        break;
        case 'new':
        workItemsData= await getWorkItemsNew(token);
        break;
        case 'resolved':
        workItemsData= await getWorkItemsResolved(token);
        break;
        case 'hold':
          workItemsData= await getWorkItemsHold(token);
          break;
        case 'pause':
          workItemsData= await getWorkItemsPause(token);
          break;
        case 'removed':
        workItemsData= await getWorkItemsRemoved(token);
        break;
      default:
        // Default case (when no specific state is selected)
        workItemsData = await getWorkItems(token);
        break;
    }
    setIsLoading(false)
    setWorkItems(workItemsData.reverse());
 
  } catch (error) {
    setIsLoading(false)
    console.log(error);
  }
};
useEffect(  ()=>{
  
fetchData();
},[user,selectedState])




    if (isLoading) {
      return (
        <Box sx={{
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        }}>
          <div className="loader1" style={{ backgroundColor: "#17C653", width: "40px" }} />
        </Box>
      );
    }
   
    const columns =isAdmin?
    [
      { field: 'Work_ID', headerName: 'Title', flex: 2, renderCell: (params) => {
        setcolor(params.row.System_WorkItemType)
        return(
          <Tooltip title={params.row.System_Title} placement="top" arrow>
          <div className='iconbox-title'>
            <div className='iconinner' style={{color:bgcolor,backgroundColor:bgcoloricon}}>{icon}</div><div>
           
            <Typography variant='h4' className='projectname-items margintop'><b>{truncate(params.row.System_Title,isAdmin?20:30)}</b></Typography>
          <Typography variant='h4' className='projectname-items'>Work ID <b>{params.value}</b></Typography>
          
          </div></div>
          </Tooltip>
        )
      }
        },
        { field: 'System_Project_name', headerName: 'Project Name', flex: 1, renderCell:(params)=>{
          setcolor(params.value)
          return(
            <div className='projectheading'>{params.value}</div>
          )
        }},
        { field: 'System_WorkItemType', headerName: 'Work Type', flex: 1, renderCell:(params)=>{
          setcolor(params.value)
          return(
            <span className='id-task' style={{color:bgcolor}}>{icon} {params.value}</span>
          )
        }} ,
        {
          field: 'System_State',
          headerName: 'State',
          flex: 1,
          renderCell: (params) => {
            // Define colors for each state
            
            const stateColors = {
              'Active': '#17c653',
              'New': '#1B84FF',
              'Resolved': '#FFAD0F',
              'Pause': '#6951aa',
              'Closed': '#F1416C',
              'Removed': '#000',
              'Hold':"red"
            };
            const bgColor={
              'Active':'#dfffea',
              'New':'#e9f3ff',
              'Resolved':'',
              'Closed':''
            }
            // Render a dropdown
            return (
              <div className='id-task2'>
              <div className='taskcolor' style={{background: stateColors[params.value] }}> </div>
              <FormControl sx={{ m: 1, minWidth: 120 ,borderColor:"transparent"}}>
               
              <Select
              sx={{width:"100%",'.MuiOutlinedInput-notchedOutline':{border:0},  color:[params.value] || "Grey",appearance:"none"}}
              
              value={params.value}
              onChange={async (e) => {
  
  
  const updatedWorkItems = workItems.map((item) =>
    item.Work_ID === params.id ? { ...item, System_State: e.target.value } : item
  );
  
  // Find the edited item in the workItems array
  const editedItem = updatedWorkItems.find((item) => item.Work_ID === params.id);
  
  const requestBody = editedItem.System_State;
  
  if(localStorage.getItem('timerstats').includes(params.id)){
          return(
            toast.error('Timer is ON,stop timer to change state!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
          )
        }
  try {
    // Call the editData API with the requestBody
    
    await EditData(token, params.id, requestBody);
  
    toast.success(`State is changed successfully to ${requestBody}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  await updatePieChart()
   
   
    fetchData()
  } catch (error) {
    console.error('Error updating data:', error);
    // Handle errors or display an error message
  }
  }}
            
            >
              
             
              <MenuItem value={'Active'}>Active</MenuItem>
              <MenuItem value={'New'}>New</MenuItem>
              <MenuItem value={'Closed'}>Closed</MenuItem>
              <MenuItem value={'Pause'}>Pause</MenuItem>
              <MenuItem value={'Resolved'}>Resolved</MenuItem>
              <MenuItem value={'Hold'}>Hold</MenuItem>
              <MenuItem value={'Removed'}>Removed</MenuItem>
            </Select>
               
            </FormControl>
               </div>
            );
          },
        },
        { field: 'System_AssignedTo', headerName: 'Assigned To', flex: 1.5, renderCell:(params)=>{
         
          return(
            <span className='dateassign'> {params.row.System_AssignedTo}</span>
          )
        }},
      
        { field: 'System_CreatedDate', headerName: 'Assigned Date', flex: 1.5, renderCell:(params)=>{
          setcolor(params.value)
          return(
            <span className='dateassign'> {params.value}</span>
          )
        }},
      
       
        {
          field: 'button',
          headerName: 'Action',
          
          renderCell: (params) => (<div className='clockrote'>
                <div  style={{
    background: params.row.System_State === "Active" ? 'transparent' : '#DBDFE969',borderRadius:"8px"
  }}> <RotatingTimerIcon id={params.id} selectedState={params.row.System_State} project={params.row.System_Project_name} state={params.row.System_State}/></div>
                <div className='pageloading'> <TableProjectView id={params.id} /></div>
          </div>
           ),
        },
       
       
      ]
      :
    [
      { field: 'Work_ID', headerName: 'Title', flex: 2, renderCell: (params) => {
        setcolor(params.row.System_WorkItemType)
        return(
          <Tooltip title={params.row.System_Title} placement="top" arrow>
          <div className='iconbox-title'>
            <div className='iconinner' style={{color:bgcolor,backgroundColor:bgcoloricon}}>{icon}</div><div>
           
            <Typography variant='h4' className='projectname-items margintop'><b>{truncate(params.row.System_Title,30)}</b></Typography>
          <Typography variant='h4' className='projectname-items'>Work ID <b>{params.value}</b></Typography>
          
          </div></div>
          </Tooltip>
        )
      }
        },
        { field: 'System_Project_name', headerName: 'Project Name', flex: 1, renderCell:(params)=>{
          setcolor(params.value)
          return(
            <div className='projectheading'>{params.value}</div>
          )
        }},
        { field: 'System_WorkItemType', headerName: 'Work Type', flex: 1, renderCell:(params)=>{
          setcolor(params.value)
          return(
            <span className='id-task' style={{color:bgcolor}}>{icon} {params.value}</span>
          )
        }} ,
        {
          field: 'System_State',
          headerName: 'State',
          flex: 1,
          renderCell: (params) => {
            // Define colors for each state
            
            const stateColors = {
              'Active': '#17c653',
              'New': '#1B84FF',
              'Resolved': '#FFAD0F',
              'Closed': '#F1416C',
              'Removed': '#000',
              'Hold':"red"
            };
            const bgColor={
              'Active':'#dfffea',
              'New':'#e9f3ff',
              'Resolved':'',
              'Closed':''
            }
            // Render a dropdown
            return (
              <div className='id-task2'>
              <div className='taskcolor' style={{background: stateColors[params.value] }}> </div>
              <FormControl sx={{ m: 1, minWidth: 120 ,borderColor:"transparent"}}>
               
              <Select
              sx={{width:"100%",'.MuiOutlinedInput-notchedOutline':{border:0},  color:[params.value] || "Grey",appearance:"none"}}
              
              value={params.value}
              onChange={async (e) => {
  
  
  const updatedWorkItems = workItems.map((item) =>
    item.Work_ID === params.id ? { ...item, System_State: e.target.value } : item
  );
  
  // Find the edited item in the workItems array
  const editedItem = updatedWorkItems.find((item) => item.Work_ID === params.id);
  
  const requestBody = editedItem.System_State;
  
  if(localStorage.getItem('timerstats').includes(params.id)){
          return(
            toast.error('Timer is ON,stop timer to change state!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
          )
        }
  try {
    // Call the editData API with the requestBody
    
    await EditData(token, params.id, requestBody);
  
    toast.success(`State is changed successfully to ${requestBody}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  await updatePieChart()
   
   
    fetchData()
  } catch (error) {
    console.error('Error updating data:', error);
    // Handle errors or display an error message
  }
  }}
            
            >
              
             
              <MenuItem value={'Active'}>Active</MenuItem>
              <MenuItem value={'New'}>New</MenuItem>
              <MenuItem value={'Closed'}>Closed</MenuItem>
              <MenuItem value={'Resolved'}>Resolved</MenuItem>
              <MenuItem value={'Pause'}>Pause</MenuItem>
              <MenuItem value={'Hold'}>Hold</MenuItem>
              <MenuItem value={'Removed'}>Removed</MenuItem>
            </Select>
               
            </FormControl>
               </div>
            );
          },
        },
        { field: 'System_CreatedDate', headerName: 'Assigned Date', flex: 1.5, renderCell:(params)=>{
          setcolor(params.value)
          return(
            <span className='dateassign'> {params.value}</span>
          )
        }},
      
       
        {
          field: 'button',
          headerName: 'Action',
          
          renderCell: (params) => (<div className='clockrote'>
                <div  style={{
    background: params.row.System_State === "Active" ? 'transparent' : '#DBDFE969',borderRadius:"8px"
  }}> <RotatingTimerIcon id={params.id} selectedState={params.row.System_State} project={params.row.System_Project_name} state={params.row.System_State}/></div>
                <div className='pageloading'> <TableProjectView id={params.id} /></div>
          </div>
           ),
        },
       
       
      ]
      const rowHeight=80;
      const rowSpace='1px';
  return (
    <Container fixed sx={{ alignItems: 'center', marginTop: "50px", maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' } }}>
    {/* <Box sx={{ display: 'flex !important', justifyContent: 'space-between', alignItems: 'center' }}>
      

      <Button
        variant="text"
        sx={{ textTransform: 'capitalize', color: '#252F4A', backgroundColor: '#F1F1F4', fontWeight: '600' }}
        onClick={handleNavigation}
      >
        My Projects
      </Button>
    </Box> */}
  <Paper sx={{ borderRadius: "0.625rem",boxShadow:"0px 3px 4px 0px rgba(0, 0, 0, 0.03)",marginTop:"10px",  backgroundColor:"white" }} >
      <div class="roletaskdetails">
        <div className='buttonwrapper'>
        <Box>
        <Typography variant="h6" sx={{ color: '#071437', fontWeight: '600' }}>
        Work Items
        </Typography>
        <Typography variant="body2" sx={{ color: '#99A1B7', fontWeight: '600' }}>
          Home - Project - Work Items
        </Typography>
      </Box>
      {/* <Typography className='titledet' variant='h4' sx={{color:'#071437', marginTop:"50px", fontWeight:"600"}}>Work Items</Typography> */}
      <div>

      <FilterButton selectedState={selectedState}  filterValue={filterValue} setFilterValue={setFilterValue} userList={uniqueAssignedToList} setSelectedState={setSelectedState}/>
           
              
              </div>
              </div>
              {workItems.length===0?(<>
                <Typography variant='body1' sx={{color:"#F1416C",textAlign:"center", border:"2px dashed #F1416C69", backgroundColor:"#fd413138", margin:"50px 30%", borderRadius:"5px", padding:"10px 5px"}}>No {selectedState} work items </Typography>
              </>):(
                <div>
              <div style={{ width: '100%' }}>
               <StyledDataGrid
                className='workitems'
                sx={{ border: "none", width:"100%", padding:'0', overflowY:'auto !important',
                
                }}
                columns={columns}
                rows={paginatedWorkItems}  // Use paginatedWorkItems instead of workItems
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20, 30]}
                paginationMode="server" // Set paginationMode to "server" for server-side pagination
                onPageChange={(newPage) => handleChangePage(null, newPage)}
                hideFooter
                getRowHeight={() => rowHeight}
                
                
              />
             

                  </div>      
                   <div style={{display:"flex", justifyContent:"space-between",marginTop:"20px", paddingBottom:"10px" }}>
                          <div style={{ display: 'flex', alignItems: 'center',  justifyContent:"center" }}>
                                        <Typography sx={{ margin: '0 10px' }}>Rows per page:</Typography>
                                        <select
                                          style={{
                                           border:'none', backgroundColor:"#1B84FF", color:"#fff", padding:"5px", borderRadius:"5px"
                                          }}
                                          value={pageSize}
                                          onChange={(e) => {
                                            setPageSize(Number(e.target.value));
                                            setCurrentPage(1); // Reset to the first page when changing page size
                                          }}
                                        >
                                          {[5, 10, 20, 30, 40, 50].map((pageSizeOption) => (
                                            <option key={pageSizeOption} value={pageSizeOption}>
                                              {pageSizeOption}
                                            </option>
                                          ))}
                                        </select>
                                      
                                      </div>
                                      <p>Total Rows: {workItems.length}</p>
                          <div>
                          <Pagination
                              shape="rounded"
                              count={Math.ceil(workItems.length / pageSize)}
                              page={currentPage}
                              onChange={handleChangePage}
                              sx={{
                        '.Mui-selected': {
                          backgroundColor: '#1B84FF !important',
                          color:"white"
                        },
                      }}
                            />
                          </div>
              </div>
              </div>
              )}
    
   </div>
    </Paper>
   </Container>
  
  )
}

export default WorkItemsContent