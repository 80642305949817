import React, { useState } from 'react';
import {  Button, Container, Typography, Divider, Backdrop, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { navigateTo, loginUser,storeCSRFToken } from '../redux/actions';
import microsoftLogo from '../public/microsoft (1) 1.png';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import LoadingTick from './LoaderTick';
import { Box } from '@mui/system';
import { getCsrfToken, getLogin, getMicrosoft,  postUserActivity } from '../api/api';
import { useMsal } from '@azure/msal-react';
import { UserAgentApplication } from 'msal';
import { config } from '../Config';
import { normalizeError, getUserProfile, getAccessToken } from '../utils/MSUtils';
import { toast } from 'react-toastify';
import { useAuth } from '../context/authContext';
import LoginWithWhatsApp from './LoginWithWhatsApp';

const LoginComponent = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({username: '', password: ''}); 
  const [loading, setLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [error1, setError1] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user1, setUser1] = useState({});

  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.clientId,
      redirectUri: config.redirectUri
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    }
  });

  
  const inputStyles = {
    display: "block",
    width: "95%",
    height: "1.075rem",
    padding: "0.8rem .9rem",
    fontSize: ".9rem",
    fontWeight: "500",
    lineHeight: "1.5",
  
    appearance: "none",
    margin: "30px 10px 5px 10px",
    backgroundClip: "padding-box",
    border: "1px solid #DBDFE9",
    borderRadius: "0.475rem",
    outline: "none",
   
    transition: "border-color 0.3s ease",
    '&::placeholder':{
      color:"#4B5675",
      fontWeight:"600"
    }
  };
 
  const linkStyle = {
    display:"flex",
    paddingTop:"5px",
    fontSize:".8rem",
    textAlign: "right",
    color: "#1B84FF",
    fontWeight:"600",
    textDecoration:"none",
    
    fontFamily:"Inter,Helvetica,sans-serif",
   
 
  };
  const navigate = useNavigate();
  const { token,login } = useAuth();
  const [open, setOpen] = React.useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleMicrosoftLogin=async ()=>{

      
    try {
      setOpen(true)
      await userAgentApplication.loginPopup({
        scopes: config.scopes,
        prompt: "select_account"
      });
      await toast.success('Login successful!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      const accesstoken = await getAccessToken(userAgentApplication, config.scopes);
   
      const userProfile = await getUserProfile(userAgentApplication, config.scopes);
     
      setUser1({
        displayName: userProfile.displayName,
        email: userProfile.mail 
      });
    //   console.log(userProfile, user1)
    //  console.log(user1)
     
      const LoginResponse = await getMicrosoft(accesstoken);
      login(LoginResponse.token,userProfile.displayName,userProfile.mail);
     setOpen(false)
     
      if(LoginResponse){
        
        setIsAuthenticated(true);
        setError1(null);
        setLoginSuccess(true);
    
        navigate('/dashboard');
      }else{        
        toast.error('Microsoft Login Failed!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })}
      
    } catch (err) {
           
     setOpen(false)
      setIsAuthenticated(false);
     
      setError1(normalizeError(err));
    }
  }

  const handleLogin = async () => {
    setError({username: '', password: ''}); 
    if(username === '' || password === '') {
      setError({
        username: username === '' ? 'Email address is required' : '',
        password: password === '' ? 'The password is required' : ''
      });
      return;
    }
    try {
      setLoading(true);
     
      // const csrfResponse = await getCsrfToken();
      // const csrfToken = csrfResponse.data; 
      const loginResponse = await getLogin(username,password);
      
      await toast.success('Login successful!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      
      login(loginResponse.data.token,loginResponse.data.users.name,loginResponse.data.users.email,loginResponse.data.role);
      setLoginSuccess(true);
      setTimeout(() => {
  
        setLoading(false);
        setLoginSuccess(false);
        navigate('/dashboard');
      }, 1000);
  
      await postUserActivity(loginResponse.data.token,'User Signed In')
    } catch (error) {
    
      console.error('Login failed', error);
      setError({username: 'Invalid credentials', password: 'Invalid credentials'});

    }finally {
      setLoading(false);
    }
  };
 
  return (

<Container component="main" disableGutters sx={{paddingLeft:"20%", paddingRight:"20%",maxWidth:{md:"100%"},}} >

<Typography variant="h5"  sx={{ textAlign: 'center', color: '#071437', fontWeight: '700',  }}>{props.header}</Typography>
<Typography variant="body2"  sx={{ textAlign: 'center', color: '#99A1B7', fontWeight: '500', marginBottom: '.75rem' }}>Your Work Portal</Typography>


            <LoginWithWhatsApp />

<Box mt={4} mb={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
<Button variant='contained' sx={{
    color:"white", 
    textAlign: 'center', 
    backgroundColor:"#282626", 
    textTransform:"capitalize", 
    margin: '0 auto',
    '&:hover':{
        backgroundColor:"#282626"
    },
    '&:focus':{
        backgroundColor:"#282626"
    }
}}
  onClick={handleMicrosoftLogin}
  disableElevation 
  disableFocusRipple 
  disableRipple 
  disableTouchRipple
>
    <img src={microsoftLogo} style={{marginLeft:"2px", marginRight:"15px"}} alt="microsoft"/> Sign in with Microsoft
</Button>
  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress sx={{color:"#17c653"}} />
      </Backdrop>
</Box>


<Divider variant='middle' mt={2} sx={{color:"#99A1B7",fontSize:"12px"}}>Or with Email</Divider>
<input type='email' placeholder='Email' value={username} style={inputStyles} 
        onChange={(e) => setUsername(e.target.value)} autoComplete='new-password' />
         {error.username && <div style={{color: '#F8285A',padding:"5px 10px", fontSize:".8rem"}}>{error.username}</div>} 
<input  type='password' placeholder='Password' value={password} style={inputStyles}
        onChange={(e) => setPassword(e.target.value)} autoComplete='new-password'  />
         {error.password && <div style={{color: '#F8285A',padding:"5px 10px", fontSize:".8rem"}}>{error.password}</div>} 
         <Box sx={{float:"right"}}><Link to={'/forgotpassword'} style={linkStyle}>Forgot Password ?</Link></Box>
        
<Button variant="contained"
      fullWidth
      disableElevation
      disableRipple
      disableFocusRipple
      onClick={handleLogin}
      style={{
        margin: '30px 8px',
        padding: '10px 48px',
        fontSize: '.9rem',
     
        height: '44px',
        textTransform: 'capitalize',
        backgroundColor: loginSuccess ? '#17C653' : '#1B84FF',
        position: 'relative',
        color:"white"
      }}
      disabled={loading}>

     
      {loading ? 'Please Wait...' : loginSuccess ? 'Login Success!' : props.header}
      {loading && <LoadingTick />}
    </Button>

<span>
  <p style={{ margin:"10px", textAlign:"center", color:"#99A1B7",fontWeight:"500"}}>Not a Member yet? <Link to={'/'} style={{fontSize:".9rem",
    textAlign: "right",
    color: "#1B84FF",
    fontWeight:"600",
    textDecoration:"none",
    
    fontFamily:"Inter,Helvetica,sans-serif",
   }}>Sign up</Link></p>
</span>

 
</Container>

  );
};
 
const mapDispatchToProps = {
  navigateTo,
  loginUser,
  storeCSRFToken,
};
 
export default connect(null, mapDispatchToProps)(LoginComponent);