import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem,  } from '@mui/material';
import {  getEndTimer, getStartTimer } from '../api/api';
import IconButton from '@mui/material/IconButton';
import RotateRight from '@mui/icons-material/RotateRight';
import ScheduleIcon from '@mui/icons-material/Schedule';
import "./style.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableProjectView from './TableProjectView';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save'; 
import { ServerStyleSheets } from '@mui/styles';
import {  Popover, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { useTimerStats } from '../context/timerStatsContext';

const inputStyles = {
    display: "block",
   
    height: "1.075rem",
    padding: "0.8rem .9rem",
    fontSize: ".9rem",
    fontWeight: "500",
    lineHeight: "1.5",
  
    appearance: "none",
    margin: "10px 0px",
    backgroundClip: "padding-box",
    border: "1px solid #DBDFE9",
    borderRadius: "0.475rem",
    outline: "none",
   
    transition: "border-color 0.3s ease",
    '&::placeholder':{
      color:"#4B5675",
      fontWeight:"600"
    }
  };


 
    

function RotatingTimerIcon({ id , selectedState, project,state}) {
    const [isRotating, setIsRotating] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [comment, setComment] = useState('');
    const [server,setServer]=useState(null);
    const { timerStats, updateTimerStats } = useTimerStats();
    const isClosedState = selectedState !== 'Active';
    useEffect(() => {
      const idExists = localStorage.getItem('timerstats').includes(id);
      setIsRotating(idExists);
    }, [timerStats]);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
  
  
    const handleCommentChange = (event) => {
      setComment(event.target.value);
    };
  
  
        
        const startTimer = async () => {
          const token =  localStorage.getItem('token');

          if (server===null){

            return(
              toast.error("Please select server before start timer!", { 
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                })
            )
          }
          try {
            const startmessage = await getStartTimer(token,id,server,project);
            setAnchorEl(null)
            const toastMessage =  'Timer started Successfully!' ;
          toast.success(toastMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            setIsRotating(!isRotating);
         await updateTimerStats()
          } catch (error) {
            console.log(error);
            toast.error("Please Punch In/Out before starting a task", { 
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
             
          }
         
        };
      
        const stopTimer = async () => {
          const token =  localStorage.getItem('token');
          try {
            const endmessage = await getEndTimer(token, id, comment);
            setAnchorEl(null)
            const toastMessage =  'Timer stopped Successfully!' ;
          toast.success(toastMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        await updateTimerStats()
            setIsRotating(!isRotating);
          } catch (error) {
            console.log(error);
            toast.error("Please Punch In/Out before ending a task", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
         
        };
      
       
        const handleClick1 = () => {
          if (isRotating) {
            stopTimer();
          } else {
            startTimer();
          }
         
        };
  

  
        return (
          <div style={{backgroundColor:isRotating?'#17c65326':state==='Active'?"#1B84FF":'transparent',borderRadius:"8px", border: isRotating?'1px dashed #17c653':"none"}}>
           {isRotating? (<>

            <IconButton  onClick={handleClick} disabled={isClosedState}>
          
             <ScheduleIcon className={isRotating?"rotate":""} />
            </IconButton>
            <Popover
          
           elevation={2}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
          >
             <div style={{padding:"10px 50px 10px 20px",margin:"auto 20px", display:"inline-flex" , overflow:"hidden",}}>
            
            
              <input type='text' placeholder='Comments' style={inputStyles} value={comment}
                onChange={handleCommentChange}/>
              <IconButton className={isRotating?"rotate":""} disableFocusRipple disableTouchRipple disableRipple  onClick={handleClick1} disabled={isClosedState}>
              <ScheduleIcon sx={{fontSize:"32px",}}/>
            </IconButton>
            </div>
          </Popover>
          </>
           ):( <>
            <IconButton className={isRotating?"rotate":""}  disableFocusRipple disableTouchRipple disableRipple onClick={handleClick} disabled={isClosedState}>
          <ScheduleIcon />
            </IconButton>
            <Popover
           elevation={2}
           arrow
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
  
          >
            <div style={{padding:"10px 50px 10px 20px",margin:"auto 20px", display:"flex" }}>
            
            <FormControl sx={{ m: 1, minWidth: 200 ,borderColor:"transparent", }}>
                <Select
               
                sx={{width:"100%",'.MuiOutlinedInput-notchedOutline':{border:0},}}
                defaultValue={'Select server'}
                value={server}
                    onChange={(event)=>setServer(event.target.value)}
                  >
                   <MenuItem value={'Select server'} disabled selected>Select server</MenuItem>
                    <MenuItem value={'Development'}>Development</MenuItem>
                <MenuItem value={'Production'}>Production</MenuItem>
                <MenuItem value={'Staging'}>Staging</MenuItem>
              
                  </Select>
                  </FormControl>
                  <IconButton  disableFocusRipple disableTouchRipple disableRipple  className={isRotating?"rotate":""} onClick={handleClick1} >
              <ScheduleIcon sx={{fontSize:"32px"}}/>
            </IconButton>
            </div>
          </Popover>
           </>)}
            </div>
        );
      }

export default RotatingTimerIcon