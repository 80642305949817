
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import "./index.css";
import ForgotpasswordPage from './pages/Login/ForgotpasswordPage';
import LoginPage from './pages/Login/LoginPage';
import Home from './pages/Dashboard/Home';
import RegisterPage from './pages/Login/RegisterPage';
import { msalInstance } from './auth/msalInstance';
import { MsalProvider } from '@azure/msal-react';
import { AuthProvider } from './context/authContext';
import IndividualProject from './components/IndividualProject';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import DashboardContent from './components/DashboardContent';
import ProjectContent from './components/ProjectContent';
import WorkItemsContent from './components/WorkItemsContent';
import ReportContent from './components/ReportContent';
import AttendanceContent from './components/AttendanceContent';
import WebSessionsContent from './components/WebSessionsContent';
import UserDetail from './components/UserDetail';
import { ProjectDataProvider } from './context/projectDataContext';
import { TimerStatsProvider } from './context/timerStatsContext';
import { PieCharttDataProvider } from './context/pieChartContext';
import { OverallWorkItemProvider } from './context/overallWorkitemContext';
import Sidebar from './components/Sidebar';
import IndividualUserPage from './components/IndividualUserPage';

function App() {
  const [open, setOpen] = useState(true);

  return (
    <AuthProvider>
      
              <MsalProvider instance={msalInstance}>
                <Provider store={store}>
                  <Router>
                    <div style={{ margin: "" }}>
                    
                      <Routes>
                        <Route path="/" element={<LoginPage />} />
                        <Route path="/resetpassword" element={<RegisterPage />} />
                        <Route path="/forgotpassword" element={<ForgotpasswordPage />} />
                        <Route path="/dashboard/*" element={
                          <ProjectDataProvider>
                          <TimerStatsProvider>
                          <PieCharttDataProvider>
                          <OverallWorkItemProvider>
                          <div>
                            <Sidebar setOpen={setOpen} open={open} />
                            <div style={{ marginLeft: open ? '250px' : '100px', transition: 'margin .5s', padding: '20px 50px', backgroundColor: '#FCFCFC', justifyContent: 'center' }}>
                            <Navbar open1={open}/>
                              <Routes>
                                <Route path="/" element={<DashboardContent />} />
                                <Route path="projects" element={<ProjectContent />} />
                                <Route path="projects/:projectSlug" element={<IndividualProject />} />
                                <Route path="workitems" element={<WorkItemsContent />} />
                                <Route path="report" element={<ReportContent />} />
                                <Route path="attendance" element={<AttendanceContent />} />
                                <Route path="web-sessions" element={<WebSessionsContent />} />
                                <Route path="user-details" element={<UserDetail />} />
                                <Route path="user-details/:userSlug" element={<IndividualUserPage/>}/>
                              </Routes>
                              <Footer />
                            </div>
                          </div>
                          </OverallWorkItemProvider>
                          </PieCharttDataProvider>
                          </TimerStatsProvider>
                          </ProjectDataProvider>
                        } />
                      </Routes>
                     
                    </div>
                  </Router>
                </Provider>
              </MsalProvider>
          
    </AuthProvider>
  );
}

export default App;



