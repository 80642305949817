// authContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [isAdmin, setIsAdmin] = useState(() => {
    const storedAdmin = localStorage.getItem('admin');
    return storedAdmin ? storedAdmin === 'Admin' : false;
  });
  useEffect(() => {
    // Save token, name, and email to localStorage whenever they change
    localStorage.setItem('token', token);
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    localStorage.setItem('admin', isAdmin ? 'Admin' : 'Developer');
  }, [token, name,email,isAdmin]);

  const login = (token, name, mail,admin) => {
    setToken(token);
    setName(name);
    setEmail(mail);
    setIsAdmin(admin === 'Admin');
  };

  const logout = () => {
    setEmail(null);
    setToken(null);
    setName(null);
    setIsAdmin(false)
  };

  return (
    <AuthContext.Provider value={{ token, name, email, login, logout,isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
