import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Modal } from '@mui/material';


import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import LoadingTick from './LoaderTick';
import { Box } from '@mui/system';

function ForgotPasswordComponent() {
    const [username, setUsername] = useState('');
    const [error, setError] = useState({username: ''});
    const [open, setOpen] = useState(false); 
    const navigate = useNavigate();


    const styles ={
      inputStyles: {
        display: "block",
    width: "100%",
    height: "1.075rem",
    padding: "0.8rem .9rem",
    fontSize: ".9rem",
    fontWeight: "500",
    lineHeight: "1.5",
    color: "grey",
    appearance: "none",
    margin: "30px 10px 5px 10px",
    backgroundClip: "padding-box",
    border: "1px solid #DBDFE9",
    borderRadius: "0.475rem",
    outline: "none",
   
    transition: "border-color 0.3s ease",
    '&::placeholder':{
      color:"whitesmoke",
      fontWeight:"600"
    }
      },
    }

   const handleForgotPassword = async ()=>{
    setError({username: '', password: ''}); 
    if(username === '' ) {
      setError({
        username: username === '' ? 'Email address is required' : '',
      });
      return;
    }
    try {
    
      const csrfResponse = await axios.get(`${process.env.REACT_APP_INTERNAL_SERVER_API}/csrf-token`, { withCredentials: true });
      const csrfToken = csrfResponse.data; 
      
      const forgotPasswordResponse = await axios.post(`${process.env.REACT_APP_INTERNAL_SERVER_API}/forgot-password`, {
        email: username,
       
      }, {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true, 
      });
      console.log(forgotPasswordResponse)
     
      setOpen(true);
  
    } catch (error) {
    
   
      setError({username: 'Invalid credentials'});
      console.error('Login failed', error);
     
    }
  };
 const handleClose = () => {
    setOpen(false);
    navigate('/'); 
  };
  
  return (
    <Container component="main" maxWidth="xs">
     
    <Typography variant="h5" style={{textAlign:"center", color:"#071437", fontWeight:"700",marginBottom:".75rem"}}>Forgot Password ?</Typography>
    <Typography variant='body1' sx={{ margin:"10px", textAlign:"center", color:"#99A1B7",fontWeight:"500"}}>Enter your email to reset your password.</Typography>
    <input type='email' style={{ ...styles.inputStyles }} placeholder='Email' value={username}
          onChange={(e) => setUsername(e.target.value)}/>
           {error.username && <div style={{color: '#F8285A',padding:"5px 10px", fontSize:".8rem"}}>{error.username}</div>} 
  <Box sx={{display:"inline-flex", padding:"2% 18%"}}>
  <Button  variant="contained" disableElevation  onClick={handleForgotPassword} style={{ margin: '30px 8px', padding:" 10px 48px",fontSize:"1rem", backgroundColor: '#1B84FF', width: '40%', height: '44px', textTransform:"capitalize",borderRadius:".5rem"}}>
      Submit
    </Button>
    <Button  variant="text" style={{ margin:20, width:"40%", color:"#071437",fontSize:"1rem", textTransform:"capitalize"}} onClick={handleClose}>
      Cancel
    </Button>

  </Box>
   
  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "20rem", backgroundColor: 'white',textAlign:"center", padding:"2rem",
          borderRadius:"0.475rem"
        }}>
          <LoadingTick/>
          <p style={{fontSize:"1.1rem",fontWeight:"400",color:"#252F4A"}} id="modal-modal-description">We have emailed your password reset link.</p>
          <Button variant="contained" disableElevation disableFocusRipple disableRipple disableTouchRipple sx={{backgroundColor:"#1B84FF", color:"white",textTransform:"capitalize", fontSize:"1rem", padding:".6rem 1.2rem", borderRadius:".5rem"}} onClick={handleClose}>Ok, got it!</Button>
        </div>
      </Modal>
  
</Container>
  )
}


export default ForgotPasswordComponent;