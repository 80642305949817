import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import {  Tooltip } from '@mui/material';
import { Paper, Box, Typography, Button, Grid, Container } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import PieChartComponent from './PieChartComponent';
import StyledDataGrid1 from './StyledDataGrid';
import { styled } from '@mui/system';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBtn from './ArrowBtn';
import "./IndividualProject.css";
import { ChevronLeftOutlined } from '@mui/icons-material';
import { Avatar as Avatar1} from '@mui/material';
import Avatar from 'react-avatar';
import { useAuth } from '../context/authContext';
import { getProjectMonthlyData, getProjectTimer, getProjectWorkItems } from '../api/api';
import CountUp from 'react-countup';
import WorkItemsContent from './WorkItemsContent';
import IndividualProjectTable from './IndividualProjectTable';
import IndividualPieChartComponent from './individualPieChar';
import IndividualArrowBtn from './IndividualArrowbtn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { getActiveWorkItemDetail,  } from '../api/api';
import { toast } from 'react-toastify';
import TableDataGrid from './TableDataGrid';
import { v4 as uuidv4 } from 'uuid';
import { useProjectData } from '../context/projectDataContext';
import { useParams } from 'react-router-dom';
import bgshade from '../public/vector-1.png';

const commonStyles = {
  borderRadius: '0.625rem',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Inter, Helvetica, sans-serif',
};

const StyledLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  background: 'none ',
  backgroundColor: 'rgb(255 255 255 / 32%)',
  '.MuiLinearProgress-bar1Determinate': {
    backgroundColor: 'white',
  },
  ...commonStyles,
});
function IndividualProject({setSelectedCard}) {
  const {token}= useAuth();
  const { projectSlug } = useParams();
  const [totalHours, setTotalHours]=useState('0');
  const [gridData,setGridData]=useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [projectTimerStats,setProjectTimerStats]=useState(0);
  const [projectTimerData, setProjectTimerData]=useState([])
  const currentDate = new Date();
  const [active, setActive]=useState(0)
  const [newvalue, setNewValue]=useState(0)
  const [resolved, setResolved]=useState(0)
  const end = currentDate.toISOString().split('T')[0];
  const startingDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const start = startingDateOfMonth.toISOString().split('T')[0];
  const [open, setOpen] = useState(false);
  const [schedule, setSchedule] = useState([]);
const {projectData}=useProjectData()
const projectInfo = projectData.find(project => project.project.name === projectSlug);
  const fetchData = async () => {
    const projectNameToFind = projectSlug;
    
    try {
      
      const MonthlyData = await getProjectMonthlyData(token, start, end);
      const getProjectWorkItemData = await getProjectWorkItems(token, projectSlug);
      const projectTimer= await getProjectTimer(token,projectSlug);
      setProjectTimerStats(projectTimer.count)
      setProjectTimerData(projectTimer.data)
      const projectObject = MonthlyData.overall_project_durations.find(item => item.project === projectNameToFind);
      setTotalHours(projectObject.duration)
      setGridData(getProjectWorkItemData);
      setActive(getProjectWorkItemData.state_count?.Active)
      setNewValue(getProjectWorkItemData.state_count?.New)
      setResolved(getProjectWorkItemData.state_count?.Resolved)
      setIsLoading(false)
   
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching data:", error);
    }
  };
useEffect(() => {

  fetchData();
}, []);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const handleClickOpen = async () => {
 
  try {
      // Ensure that projectTimerData is an array
      if (Array.isArray(projectTimerData) && projectTimerData.length > 0) {
        // Loop through each element of the array
        for (const workitemsString of projectTimerData) {
  
  
          // Check if workitemsString is a non-empty string
          if (typeof workitemsString === 'string' && workitemsString.trim() !== '') {
            // Fetch data and wait for the response
            const tableData = await getActiveWorkItemDetail(token, workitemsString);
  
            // Process the data and set the state
            const extractedData = tableData.map(item => ({
              id: uuidv4(),
              Work_id: item.value[0].id,
              System_Project_name: item.value[0].fields["System.TeamProject"],
              System_State: item.value[0].fields["System.State"],
              System_WorkItemType: item.value[0].fields["System.WorkItemType"],
              System_CreatedDate: item.value[0].fields["System.CreatedDate"],
              System_Title: item.value[0].fields["System.Title"],
            }));
  
            setSchedule(extractedData);
            setOpen(true);
          } else {
            console.error('Invalid or empty workitemsString:', workitemsString);
            toast.error('There are no active timers at the moment.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
      });
            // Handle the case where an element in the array is not a valid string
          }
        }
      } else {
        throw new Error('Invalid or empty array in projectTimerData.');
        
      }
  } catch (error) {
    console.error(error);
    toast.error('There are no active timers at the moment.', {
      // ... toast configuration ...
    });
  }
};


const handleClose = () => {
  setOpen(false);
  fetchData();
};

if (isLoading) {
  return (
    <Box sx={{
    width: "100%",
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    }}>
      <div className="loader1" style={{ backgroundColor: "#17C653", width: "40px" }} />
    </Box>
  );
}


  return (
    <div>
   
        <Container fixed sx={{ alignItems: 'center', marginTop: "50px", maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' } }}>
   
        
       <Typography variant="body2" sx={{ color: '#99A1B7', fontWeight: '600',  marginBottom:"20px",  marginTop:"-5px", paddingLeft:'10px', }}>
          Home - Projects - {projectInfo.project.name}
        </Typography>
        <Typography variant='h4' sx={{fontWeight:"600", color:"#F1416C", paddingTop:"5px", paddingBottom:"15px"}}>#{projectInfo.project.name}
        <Typography className='' variant='p' sx={{color:'#9b9696',display:'block', fontSize:'12px', marginTop:"5px", fontWeight:"400"}}>{projectInfo.project.description}</Typography>

        </Typography>

<Grid container spacing={1} mb={4}>
  <Grid item xs={12} md={5} lg={5} xl={3}>
    <Paper className='box-project'
    sx={{ backgroundColor: '#080655',
        backgroundImage: `url(${bgshade})`,
        backgroundSize: 'contain',
        backgroundPositionX: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode:"saturation"}}>
      <div>
      <Typography variant='h4'
        sx={{
         paddingTop:"40px",
          color: "#ffff",
          lineHeight: "1",
          fontWeight: "600"
        }}
      >
     {totalHours}
      </Typography>
      <Typography className='project-sub'
      >
       Over All Working Hours
      </Typography>
      </div>
      <div>
      
      </div>
     
    </Paper>

  </Grid>

  <Grid item xs={12} md={4} lg={4} xl={3}>

       
          <IndividualPieChartComponent  
          props={{
            active:active,
            new:newvalue,
            resolved:resolved
            }}
          />
     
 </Grid>

<Grid item xs={12} md={4} lg={4} xl={3}>
  <Paper className='timerreport' sx={{borderRadius: "0.625rem",padding: { xs: '1rem', md: '1.75rem' },boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)",  border: "1px solid #F1F1F4"}}>
  
        <div>
        <Typography
          sx={{
            color: '#071437',
            fontSize: '2rem',
            fontWeight: '600',
          }}
        >
          Timer Starts
        </Typography>
        <Typography
          sx={{
            color: '#99A1B7',
            fontSize: '1rem',
            fontWeight: '300',
          }}
        >
          Detail View
        </Typography>
        </div>
        <Box>
           <Typography  className='startname'  >
           {projectTimerStats}  </Typography>
        </Box>

      
        <Button
        variant='text'
        disableElevation
        disableRipple
        disableFocusRipple
        sx={{ float: "right", width: "100%", paddingLeft:"60%", paddingTop: "0px", '&:hover': { backgroundColor: 'white' }, marginTop:"-20px"}}
        onClick={handleClickOpen}
      >
        <ArrowForwardIcon sx={{ color: '#1B84FF', fontSize: "3rem" }} />
      </Button>
    
        

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ '.MuiDialog-paper': { maxHeight: 'none', maxWidth: 'none', width: '70%' } }}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon sx={{ color: '#071437' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box>
        <TableDataGrid workItems={schedule} />
        </Box>
        
      </Dialog>
     
    </Paper>

  </Grid>
 
  <Grid item xs={12} md={4} lg={4} xl={3}>
  <Paper className='timerreport'
      sx={{ borderRadius: "0.625rem",
        padding: { xs: '1rem', md: '1.75rem' },
      
        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)",
       
        border: "1px solid #F1F1F4"
      }}
    >
        <Typography
          sx={{
            color: '#071437',
            fontSize: '2rem',
            fontWeight: '600',
          }}
        >
          Team Members
        </Typography>
        <Box mb={10}>
        <AvatarGroup total={projectInfo.members.length} className='avatarview'>
  {projectInfo.members.map((person, index) => {
                         const letters = '0123456789ABCDEF';
                         let color = '#';
                         for (let i = 0; i < 6; i++) {
                           color += letters[Math.floor(Math.random() * 8)];
                         }
                         const avatarUrl = `https://dev.azure.com/Fulgid/_apis/GraphProfile/MemberAvatars/${person.id}`;

                         return(
                           <Tooltip title={person.name}>
                           <Avatar1 alt={person.name} src={avatarUrl} sx={{ backgroundColor: color }} />
                           </Tooltip>
                           
                         ) 
                         
                       })}
</AvatarGroup>
  
        </Box>
 

    </Paper>
 </Grid>
</Grid>

<IndividualProjectTable projectName={projectInfo.project.name} />
</Container>
    </div>
  )
}

export default IndividualProject