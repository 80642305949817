import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import bgshade from '../public/vector-1.png';
import React from 'react';
import CountUp from 'react-countup'; // Import the CountUp component
import { useProjectData } from '../context/projectDataContext';
import styled from '@emotion/styled';

const commonStyles = {
  borderRadius: '0.625rem',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Inter, Helvetica, sans-serif',
};

const StyledLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  background: 'none ',
  backgroundColor: 'rgb(255 255 255 / 32%)',
  '.MuiLinearProgress-bar1Determinate': {
    backgroundColor: 'white',
  },
  ...commonStyles,
});

function ActiveCard() {
  const { projectData } = useProjectData();

  return (
    <Paper
      sx={{
        width: { xs: '100%', md: '22rem' },
        padding: { xs: '1rem', md: '1.75rem' },
        borderRadius: '0.625rem',
        backgroundColor: '#F1416C',
        backgroundImage: `url(${bgshade})`,
        backgroundSize: 'contain',
        backgroundPositionX: 'right',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Typography
        sx={{
          fontSize: '2.5rem',
          color: '#ffff',
          lineHeight: '1',
          fontWeight: '600',
        }}
      >
        {/* Use CountUp component for the counter animation */}
        <CountUp end={projectData.length} duration={2} />
      </Typography>
      <Typography
        sx={{
          color: '#ffff',
          opacity: '0.75',
          fontWeight: '500',
          fontSize: '1.075rem',
          paddingTop: '0.25 rem',
          fontFamily: 'Inter, Helvetica, sans-serif',
        }}
      >
        Active Projects
      </Typography>
      <Box mt={12}>
        <Typography
          sx={{
            color: '#ffff',
            opacity: '0.75',
            fontWeight: '500',
            fontSize: '1.075rem',
            paddingTop: '0.25 rem',
            fontFamily: 'Inter, Helvetica, sans-serif',
          }}
        >
          {/* Use CountUp component for the counter animation */}
          <CountUp end={projectData.length} duration={2} /> Pending
        </Typography>
        <StyledLinearProgress variant="determinate" value={100 - projectData.length} />
      </Box>
    </Paper>
  );
}

export default ActiveCard;
