// projectDataContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getProjects, getWorkItemsActive, getWorkItemsNew, getWorkItemsResolved } from '../api/api';
import { useAuth } from './authContext';

const PieChartDataContext = createContext();

export const PieCharttDataProvider = ({ children }) => {
    const {token}= useAuth();
    const [WorkItemsActive,setWorkItemsActive]=useState([]);
    const [WorkItemsNew,setWorkItemsNew]=useState([]);
    const [WorkItemsClosed,setWorkItemsClosed]=useState([]);

    const updatePieChart = async () => {
      try{
        const workItemsData1 = await getWorkItemsActive(token);
        setWorkItemsActive(workItemsData1)
        
        const workItemsData2 = await getWorkItemsNew(token);
        setWorkItemsNew(workItemsData2)
        
        const workItemsData3 = await getWorkItemsResolved(token);
        setWorkItemsClosed(workItemsData3)
       
      }catch (error){
          console.log(error)
      }
    }
    useEffect(  ()=>{
    const fetchData = async () => {
   await updatePieChart();  
   
  };
  fetchData();
  },[])

 

  return (
    <PieChartDataContext.Provider value={{WorkItemsActive,WorkItemsNew,WorkItemsClosed, updatePieChart }}>
      {children}
    </PieChartDataContext.Provider>
  );
};

export const usePieChartData = () => {
  const context = useContext(PieChartDataContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
