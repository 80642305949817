import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectsData } from '../redux/actions';
import { getProjects, getPunchStatus, getPunchin, getPunchout, getTimerCount, getWorkItems } from '../api/api';
import { styled } from '@mui/system';
import { Paper, Box, Typography, Button, Grid, Container, IconButton } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimerIcon from '@mui/icons-material/Timer';
import PieChartComponent from './PieChartComponent';
import CalenderTable from './CalenderTable';
import StyledDataGrid1 from './StyledDataGrid';
import illustration from "../public/upgrade-dark.svg";
import punchbag from "../public/42.png";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import ArrowBtn from './ArrowBtn';
import { useAuth } from '../context/authContext';
import { useProjectData } from '../context/projectDataContext';
import ActiveCard from './ActiveCard';
import { useOverallWorkitem } from '../context/overallWorkitemContext';
import { useTimerStats } from '../context/timerStatsContext';
import CountUp from 'react-countup';

const commonStyles = {
  borderRadius: '0.625rem',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Inter, Helvetica, sans-serif',
};

const StyledLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  background: 'none ',
  backgroundColor: 'rgb(255 255 255 / 32%)',
  '.MuiLinearProgress-bar1Determinate': {
    backgroundColor: 'white',
  },
  ...commonStyles,
});

function DashboardContent() {
  const user = useSelector((state) => state.user.user);
  const [data, setData] = useState([]);
  
  
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [isPunchedIn, setIsPunchedIn] = useState(false);
  const [isRotating, setisRotating] = useState(false);
  const [location, setLocation] = useState("");
  const navigate = useNavigate();
  const {token}= useAuth();
const {projectData}=useProjectData();
const {overallWorkItems}= useOverallWorkitem();
const {timerStats}=useTimerStats();
  useEffect(() => {
    const fetchData = async () => {
      
      try {
        if (!token) {
          console.log("User or token not available");
          setIsLoading(false);
          return;
        }

       
        navigator.geolocation.getCurrentPosition(async function (position) {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          const openCageApiKey = 'a9f9f1996f96406783c0452d0d562a3d';
          const openCageResponse = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=${openCageApiKey}`);
       
          if (openCageResponse.data.results.length > 0) {
            const locality = openCageResponse.data.results[0].formatted;
           
            setLocation(locality);
          } else {
            console.log("OpenCage geocoding failed");
          }
        });

      
        
    
       

        const punchStatus = await getPunchStatus(token);
        setIsPunchedIn(punchStatus.error === "Already Punched In" ? true : false);
        dispatch(updateProjectsData(projectData));

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

   fetchData();

    
  }, [user, dispatch]);


  if (isLoading) {
    return (
      <Box sx={{
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      }}>
        <div className="loader1" style={{ backgroundColor: "#17C653", width: "40px" }}></div>
      </Box>
    );
  }

  const handleButtonClick = async() => {
    try {
      const punchAction = isPunchedIn ? getPunchout(token, location) : getPunchin(token, location);

      
    const punchData = await punchAction;
    if (punchData !== null) {
      setIsPunchedIn(!isPunchedIn);
      const toastMessage = isPunchedIn ? 'Punched Out Successfully!' : 'Punched In Successfully!';
     
      toast.success(toastMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setisRotating(!isRotating);
    }
    } catch (error) {
      console.log(error);
   
    if (error.response.status === 403) {
      toast.error('Please close work timer before punch out.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error('An error occurred while making the API call', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  
  }
}

  return (
    <Container fixed sx={{ alignItems: 'center', marginTop: "20px", maxWidth: { xs: 'xs', sm: 'sm', md: 'md', lg: 'xl', xl: 'xl' } }}>
    <ToastContainer />
      <Grid container spacing={1} mb={4}>
        <Grid item xs={12} md={6} lg={6} xl={4}>
         <ActiveCard/>

          <Paper
            sx={{
              width: { xs: "100%", md: "22rem" },
              borderRadius: "0.625rem",
              padding: { xs: '1rem', md: '1.75rem' },
              minWidth: "22rem",
              boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)",
              marginTop: "20px",
              border: "1px solid #F1F1F4"
            }}
          >
            <Box mb={3}>
              <Typography
                sx={{
                  color: '#071437',
                  fontSize: '2rem',
                  fontWeight: '600',
                }}
              >
                Timer Stats
              </Typography>
              <Typography
                sx={{
                  color: '#99A1B7',
                  fontSize: '1rem',
                  fontWeight: '400',
                }}
              >
                Detail View
              </Typography>

              <Box>
                <Typography
                  sx={{
                    color: '#F1416C',
                    fontSize: '5rem',
                    fontWeight: '500',
                  }}
                >
                  <CountUp end={timerStats} duration={2}/>
                </Typography>
              </Box>
<ArrowBtn/>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <PieChartComponent />

          <Paper
            sx={{
              width: { xs: "100%", md: "22rem" },
              minWidth: "22rem",
              borderRadius: "0.625rem",
              padding: { xs: '1rem', md: '1.75rem' },
              border: "1px solid #F1F1F4",
              boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)",
              marginTop: "20px"
            }}
          >
            <Box mb={3}>
              <Typography
                sx={{
                  color: '#071437',
                  fontSize: '2rem',
                  fontWeight: '600',
                }}
              >
                Over All
              </Typography>
              <Typography
                sx={{
                  color: '#99A1B7',
                  fontSize: '1rem',
                  fontWeight: '400',
                }}
              >
                Work Items Assigned
              </Typography>

              <Typography
                sx={{
                  color: '#17C653',
                  fontSize: '4.7rem',
                  fontWeight: '500',
                }}
              >
                <CountUp end={overallWorkItems.length} duration={2}/>
              </Typography>
              <Button variant='text' disableElevation disableRipple disableFocusRipple
                sx={{ float: "right", width: "100%", paddingLeft: "60%", paddingTop: "0px", '&:hover': { backgroundColor: 'white' }, marginTop: "-20px" }}
              onClick={()=>{navigate('/dashboard/workitems')}}
              >
                <ArrowForwardIcon sx={{ color: '#1B84FF', fontSize: "3rem" }} />
              </Button>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={4} sx={{ backgroundImage: punchbag }}>
          <Paper
            sx={{
              padding: { xs: '1rem', md: '1.75rem' },
              textAlign: 'center',
              width: "89npm install --save react-toastify%",
              boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)",
              backgroundImage: `url(${punchbag})`,
              border: "1px solid #F1F1F4",
              backgroundPosition: "100% 50%",
              backgroundRepeat: "no-repeat"
            }}
          >
            <Box mb={7}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "600", paddingTop: "50px" }}>
                Minutes Matter: Punching In for <span style={{ color: "#F8285A", borderBottom: "5px solid rgb(248,40,90,.3)", paddingBottom: "5px", }}>Progress</span>
              </Typography>
              <Box>
                <img
                  src={illustration}
                  alt="Illustration of a person with technology elements"
                  style={{ width: "55%", margin: '20px 0' }}
                />
              </Box>

              <Button variant="contained" disableElevation disableFocusRipple disableTouchRipple disableRipple
                sx={{ backgroundColor: isPunchedIn ? '#17c653 !important' : ' #071437 !important', marginTop: "10px" }} onClick={handleButtonClick}
              >
                {isPunchedIn ? 'Punch Out' : 'Punch In'}
             <IconButton className={isPunchedIn ? "rotate" : ""}  disableElevation disableFocusRipple disableTouchRipple disableRipple >
             {isPunchedIn ? <ScheduleIcon  sx={{ color: "white",  }} /> : <TimerIcon sx={{ fontSize: "20px", color: "white", paddingTop: "5px" }} />}
               
             </IconButton>
                  
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <CalenderTable />
      <StyledDataGrid1 />
    </Container>
  );

          }
export default DashboardContent;
